import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { signOut } from 'helpers/sign';
import Button from 'ui/Button/Button';
import Modal, { ModalDescription, ModalStatus, ModalTitle } from 'ui/Modal/Modal';
import { ModalProps } from 'ui/Modal/Modal.d';
import {useHistory} from "react-router-dom";
import styles from './AuthLogoutModal.module.scss';

const cx = classNames.bind(styles);

export default function ModalLogout(props: ModalProps) {
    const [t] = useTranslation();
    const history = useHistory()

    const onClickLogout = () => {
        // eslint-disable-next-line react/destructuring-assignment
        props.close?.();
        signOut(true);
        history.push("/")
    };

    return (
        <Modal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <div className={cx('Component')}>
                <ModalStatus type="question" bgColor="red" />
                <ModalTitle>{t('modalLogout.title')}</ModalTitle>
                <ModalDescription>{t('modalLogout.description')}</ModalDescription>
                <div className={cx('BtnGroup')}>
                    <Button variant="outlined" onClick={onClickLogout} size="large" color="secondary">
                        {t('modalLogout.btnSubmit')}
                    </Button>
                    {/* eslint-disable-next-line react/destructuring-assignment */}
                    <Button onClick={props.close} size="large">
                        {t('modalLogout.btnCancel')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
