import React from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames/bind';
import styles from './Tooltip.module.scss';

const cx = classNames.bind(styles);

export const useTooltip = () => {
    const popperWrapperRef = React.useRef<Element | any>(null);
    const [popperRef, setPopperRef] = React.useState<null | HTMLDivElement>(null);
    const popperArrowRef = React.useRef<HTMLDivElement>(null);
    const [displayTooltip, setDisplayTooltip] = React.useState(false);

    const popper = usePopper(popperWrapperRef.current, popperRef, {
        placement: 'right',
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['bottom', 'top']
                }
            },
            {
                name: 'arrow',
                options: {
                    element: popperArrowRef.current
                }
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 12]
                }
            }
        ]
    });

    return {
        popper,
        popperWrapperRef,
        popperArrowRef,
        setPopperRef,
        popperRef,
        displayTooltip,
        setDisplayTooltip
    };
};

function Tooltip({
    popper,
    children,
    className = ''
}: React.PropsWithChildren<{
    popper: ReturnType<typeof useTooltip>;
    className?: string;
}>) {
    if (!popper.displayTooltip) return null;

    return (
        <div
            style={popper.popper.styles.popper}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...popper.popper.attributes.popper}
            ref={(ref) => {
                popper.setPopperRef(ref);
            }}
            className={cx('Tooltip', className)}
        >
            {children}
            <div style={popper.popper.styles.arrow} ref={popper.popperArrowRef} className={cx('TooltipArrow')} />
        </div>
    );
}

export default Tooltip;
