import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useMerchantsQuery } from 'apollo/generated';
import {validateIntToFloat} from "helpers/formatMoney";
import useModal from 'hooks/useModal/useModal';
import { ReactComponent as IconErrorStatus } from 'assets/images/status-error.svg';
import Button from 'ui/Button/Button';
import Preloader from 'ui/Preloader/Preloader';
import profile from "assets/icons/profile.svg"
import RequestModal from '../RequestModal/RequestModal'
import CurrencyIcon from '../CurrencyIcon/CurrencyIcon';
import styles from './BookBalances.module.scss';
import {ArrowBottomCornerIcon} from "../../ui/Icons/Icons";


const cx = classNames.bind(styles);

function BookBalances() {
    const [t] = useTranslation();
    const modalDeposit = useModal<{ currency: { id: string } }>();
    const merchantsQuery = useMerchantsQuery()
    const [merchantId, setMerchantId] = React.useState("")

    // const myBalancesQuery = useMyBalancesQuery({
    //     fetchPolicy: 'cache-and-network',
    //     nextFetchPolicy: 'cache-only'
    // });
    //
    // const balances = myBalancesQuery.data?.me?.myBalances;
    // const hasError = !!myBalancesQuery.error;

    const merchants = merchantsQuery.data?.merchants.__typename === "Users"
        ? merchantsQuery.data?.merchants.users
        : []

    const handleOrders = (id: string) => {
        setMerchantId(id)
        modalDeposit.open()
    }

    if (merchantsQuery.error)
        return (
            <div className={cx('ErrorScreen')}>
                <IconErrorStatus />
                <div className={cx('ErrorScreenContent')}>
                    <h6>Ошибка</h6>
                    <p>При загрузке списка пользователей произошла ошибка. Попробуйте обновить страницу</p>
                </div>

                <Button
                    disabled={merchantsQuery.loading}
                    onClick={() => {
                        merchantsQuery.refetch();
                    }}
                >
                    {t('pageStatistics.balances.errorScreen.btn')}
                </Button>
            </div>
        );

    return (
        <section className={cx('Component')}>
            <header className={cx('Header')}>
                <h3 className={cx('Title')}>Баланс</h3>
            </header>

            <main className={cx('Main')}>
                {merchantsQuery.loading && (
                    <div className={cx('Preloader', merchants && 'absolute')}>
                        <Preloader />
                    </div>
                )}

                {merchants?.map((el) => {
                    if (!el) return null;

                    return (
                        <div className={cx('Balance')} key={el.uid}>
                            <div className={cx('BalanceDetails')}>
                                <div className={cx('BalanceMain')}>
                                    <img src={el.imageUrl || profile} alt="merchant"/>
                                    <div className={cx('BookName')}>
                                        <b className={cx('BalanceAmount')}>{el.name}</b>
                                        <span className={cx('BalanceName')}>{el.email}</span>
                                    </div>

                                    <CurrencyIcon currency="RUBCASH" />

                                    <div>
                                        <b className={cx('BalanceAmount')}>{validateIntToFloat(el.balance)} ₽</b>
                                        <span className={cx('BalanceName')}>Текущий баланс</span>
                                    </div>
                                </div>
                                <div className={cx('BalanceMain')}>
                                    <Button isRound iconStart={<ArrowBottomCornerIcon />} color='gray'/>
                                    <div>
                                        <b className={cx('BalanceAmount')}>{el.userWithdrawOrdersCount}</b>
                                        <span className={cx('BalanceName')}>Заявки на вывод</span>
                                    </div>
                                </div>
                                {/*{!!Number(balance.awaitedIncome) && (*/}
                                {/*    <span className={cx('BalanceWaiting')}>*/}
                                {/*        {t('pageStatistics.balances.waiting')}{' '}*/}
                                {/*        <b>*/}
                                {/*            {formatMoney(balance.awaitedIncome)} {name}*/}
                                {/*        </b>*/}
                                {/*    </span>*/}
                                {/*)}*/}
                            </div>
                            <div className={cx('BalanceActions')}>
                                {/*{(name === 'USDCASH' || name === 'USDT' || name === 'RUBLES') && (*/}
                                    <Button
                                        color="primary"
                                        onClick={() => handleOrders(el.uid)}
                                        type="button"
                                    >
                                        Посмотреть все заявки
                                    </Button>
                                {/*)}*/}
                            </div>
                        </div>
                   );
                })}
            </main>

            <RequestModal
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...modalDeposit}
                currencyId={modalDeposit.payload?.currency.id!}
                merchantId={merchantId}
            />
        </section>
    );
}
export default React.memo(BookBalances);
