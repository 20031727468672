import React, {useEffect, useRef} from 'react';
import useModal from 'hooks/useModal/useModal';
import {useTranslation} from 'react-i18next';
import {CrossIcon, ReloadIcon, SearchIcon} from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import Table from 'ui/Table/Table';
import useDebounce from 'hooks/useDebounce/useDebounce';
import cn from 'classnames';
import AdminTransactionsHeader from '../AdminTransactionsHeader/AdminTransactionsHeader';
import styles from './AdminTransactions.module.scss';
import TableSkeleton from '../TableSkeleton/TableSkeleton';
import Sidebar from '../Sidebar/Sidebar';
import BookBalances from "../BookBalances/BookBalances";
import {OrderSortTypeEnum, OrderTypeEnum, useOrdersQuery} from "../../apollo/generated";
import formatDate from "../../helpers/formatDate";
import {validateIntToFloat} from "../../helpers/formatMoney";
import TextField from "../../ui/TextField/TextField";

export function NoDataStub({
    description,
    icon,
    label
}: {
    description?: React.ReactNode;
    icon?: string;
    label?: string;
}) {
    return (
        <div className={cn(styles.IconContainer)}>
            <img className={cn()} src={icon} alt="No data" />
            <h2>{label}</h2>
            <span className={cn(styles.LoadingFailed)}>{description}</span>
        </div>
    );
}

export function LoadingFailedInfo({ onClick }: { onClick: () => void }) {
    const [t] = useTranslation();
    return (
        <div className={cn(styles.LoadingFailed)}>
            {t('adminTransactions.loadingFailed')}
            <Button color="secondary" variant="outlined" iconStart={<ReloadIcon />} onClick={onClick}>
                {t('adminTransactions.update')}
            </Button>
        </div>
    );
}

const STEP = 10000;

export default function AdminTransactions() {
    const filtersModal = useModal();
    const [t] = useTranslation();
    const [search, setSearch] = React.useState('');
    const debouncedSearch = useDebounce(search);

    const {data: orders, loading, fetchMore, refetch, variables} = useOrdersQuery({
        variables: {
            allMerchants: true,
            merchantUids: [],
            skip: 0,
            limit: STEP
        }
    })

    const currentSort = variables?.sortType

    React.useEffect(() => {
        refetch({
            q: debouncedSearch
        })
    }, [debouncedSearch])

    const handleLoadMore = () => {
        fetchMore({
            variables: {
                skip: orders?.orders.__typename === "Orders" ? orders.orders.orders.length : 0,
                limit: STEP
            }
        })
    }

    const transformOrderDataToRow = React.useCallback(() => {
        let res:any[] = []
        if (orders?.orders.__typename === "Orders") {
            res = orders.orders.orders.map((order, index) => ({
                options: {
                    type: 'options',
                    rowId: order.uid + index
                },
                content: [
                    {
                        component:
                            <div className={cn(styles.RowTypeLabel, order.orderType === OrderTypeEnum.Deposit ? styles.Success : styles.Danger)}>
                                {order.orderType === OrderTypeEnum.Deposit ? "Ввод" : "Вывод"}
                            </div>,
                        id: order.uid + index + 1
                    },
                    {
                        component: order.status,
                        id: order.uid + index + 2
                    },
                    {
                        component: order.orderId,
                        id: order.uid + index + 3
                    },
                    {
                        component: formatDate(order.created),
                        id: order.uid + index + 4
                    },
                    {
                        component: `${validateIntToFloat(order.amount)} ₽`,
                        id: order.uid + index + 5
                    },
                    {
                        component: `${validateIntToFloat(order.fee)} ₽`,
                        id: order.uid + index + 6
                    },
                    {
                        component: `${validateIntToFloat(order.depositAmount)} ₽`,
                        id: order.uid + index + 7
                    },
                    {
                        component: order.paymentProviderId,
                        id: order.uid + index + 8,
                        className: cn(styles.IdCell, styles.RowBorder)
                    },
                    {
                        component: "-",
                        id: order.uid + index + 9,
                        className: cn( styles.IdCell, styles.RowBorder)
                    },
                    {
                        component: order.merchantUid,
                        id: order.uid + index + 10,
                    }
                ]
            }))
        }
        return res
    }, [orders])
    // const [isIpadToTablet] = useWidthCondition((w) => BREAKPOINT_IPAD > w && w >= BREAKPOINT_TABLET);
    // const [variables, setVariables] = React.useState<AllTransfersQueryVariables>({
    //     offset: 0,
    //     first: STEP,
    //     filters: {},
    //     sort: [TransferSortEnum.Created]
    // });

    // const { data, loading } = useAllTransfersQuery({ variables });

    // const { transfers: transfersPage, pageInfo } = data?.allTransfers ?? {};
    // const [transfers, setTransfers] = React.useState<typeof transfersPage>([]);
    // const isEmpty = !transfers?.length;
    // const currentSort = variables.sort?.[0];
    // const isMoreTransfers = transfers && pageInfo && pageInfo.total && pageInfo.total >= transfers.length;

    // useEffect(() => {
    //     if (!variables.offset) {
    //         setTransfers(transfersPage);
    //     } else if (transfersPage) {
    //         setTransfers((prev) => (prev ? [...prev, ...transfersPage] : transfersPage));
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [transfersPage]);

    // To submit search
    // useEffect(() => {
    //     update(variables, {
    //         offset: () => 0,
    //         filters: {
    //             account: () => debouncedSearch
    //         }
    //     });
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [debouncedSearch]);

    // To load next page
    // const loadMoreTransactions = () => {
    //     setVariables(
    //         update(variables, {
    //             offset: () => variables!.offset! + STEP
    //         })
    //     );
    // };

    // To submit new sort
    // const onChangeSort = React.useCallback(
    //     (sort: TransferSortEnum) => {
    //         setVariables(
    //             update(variables, {
    //                 sort: () => [sort],
    //                 offset: () => 0
    //             })
    //         );
    //     },
    //     [variables]
    // );

    // To Submit new Form
    // const applyFilters = (filters: AllTransfersQueryVariables['filters']) => {
    //     setVariables({
    //         ...variables,
    //         offset: 0,
    //         filters: {
    //             ...variables.filters,
    //             ...filters
    //         }
    //     });
    // };

    // const handleReload = React.useCallback(() => {
    //     const test = refetch(variables);
    //     // eslint-disable-next-line no-console
    //     console.log(test);
    // }, [variables, refetch]);

    // const handleSortClick = React.useCallback(
    //     ({ sortAsc, sortDesc }: TableSort) => {
    //         onChangeSort(currentSort === sortAsc ? (sortDesc as TransferSortEnum) : (sortAsc as TransferSortEnum));
    //     },
    //     [currentSort, onChangeSort]
    // );

    const mockColumns = [
        {
            field: 'type',
            headerName: 'Тип',
        },
        {
            field: 'status',
            headerName: 'Статус'
        },
        {
            field: 'number',
            headerName: 'Номер',
            sortDesc: OrderSortTypeEnum.OrderIdDesc,
            sortAsc: OrderSortTypeEnum.OrderIdAsc
        },
        {
            field: 'time',
            headerName: 'Время оплаты',
        },
        {
            field: 'withdraw sum',
            headerName: 'Сумма к зачислению',
            sortDesc: OrderSortTypeEnum.AmountDesc,
            sortAsc: OrderSortTypeEnum.AmountAsc
        },
        {
            field: 'commission',
            headerName: 'Комиссия',
            sortDesc: OrderSortTypeEnum.FeeDesc,
            sortAsc: OrderSortTypeEnum.FeeAsc
        },
        {
            field: 'deposit sum',
            headerName: 'сумма пополнения',
            sortDesc: OrderSortTypeEnum.DepositAmountDesc,
            sortAsc: OrderSortTypeEnum.DepositAmountAsc
        },
        {
            field: 'id1',
            headerName: 'ID ПЛАТЕЖКИ',
        },
        {
            field: 'id2',
            headerName: 'ID Транзакции',
        },
        {
            field: 'merchantId',
            headerName: 'Контрагент',
        }
    ];

    const mainRef = useRef<HTMLElement>(null);

    // To set page height
    useEffect(() => {
        if (mainRef.current) {
            const headerHeight = mainRef.current!.offsetTop;
            const windowInnerHeight = window.innerHeight;
            mainRef.current.style.height = `${windowInnerHeight - headerHeight - 1}px`;
        }
    }, []);

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (loading) return;
        const { scrollHeight, clientHeight, scrollTop } = e.target as HTMLDivElement;
        if (scrollHeight - scrollTop <= clientHeight) {
            // handleLoadMore()
        }
    };

    const handleChangeSort = (sort: any) => {
        refetch({
            skip: 0,
            sortType: sort
        })
    }

    const handleSortClick = React.useCallback(
        ({ sortAsc, sortDesc }: any) => {
            handleChangeSort(currentSort === sortAsc ? sortDesc : sortAsc);
        },
        [currentSort, handleChangeSort]
    );

    return (
        <section>
            <AdminTransactionsHeader
                // filterLabel="Filter"
                // onClickBtnAdd={reportModal.open}
                // onClickBtnFilter={filtersModal.open}
                // onChangeSearch={setSearch}
                // searchPlaceholder="Search"
                // searchValue={search}
            />
            <main className={cn(styles.Main)} ref={mainRef}>
                <div className={cn(styles.Wrapper)}>
                    <BookBalances />
                </div>

                {/*{loading && isEmpty && <TableSkeleton />}*/}

                {/*{!isEmpty && (*/}
                <div className={cn(styles.Wrapper)}>
                    <div className={cn(styles.TableWrapper)}>
                        <header className={cn(styles.TransactionHeader)}>
                            <h3 className={cn(styles.TransactionTitle)}>Транзакции</h3>
                            <TextField
                                placeholder="Поиск"
                                className={cn(styles.Textfield)}
                                containerClassName={cn(styles.TextfieldContainer)}
                                inputWrapperClassName={cn(styles.TextfieldWrapper)}
                                inputLeftContent={<SearchIcon/>}
                                value={search}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                        </header>
                        {loading && <TableSkeleton />}
                        <div>
                            <Table
                                columns={mockColumns}
                                rows={transformOrderDataToRow()}
                                onSortClick={handleSortClick}
                                currentSort={currentSort}
                                onScroll={handleScroll}
                            />
                        </div>
                    </div>
                </div>
                {/*)}*/}

                {/*{search && loading && (*/}
                {/*    <div className={cn(styles.SearchLoading)}>*/}
                {/*        <Preloader />*/}
                {/*    </div>*/}
                {/*)}*/}

                {/*{(loading || error) && !isEmpty && (*/}
                {/*    <div className={cn(styles.LoadMore)}>*/}
                {/*        {loading && <Preloader />}*/}
                {/*        {error && <LoadingFailedInfo onClick={handleReload} />}*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*{!loading && isEmpty && !error && (*/}
                {/*    <NoDataStub label={t('adminTransactions.dataNotAvailable')} icon={NoDataIcon} />*/}
                {/*)}*/}
                {/*{!loading && error && (*/}
                {/*    <NoDataStub*/}
                {/*        label={t('adminTransactions.error')}*/}
                {/*        icon={LoadingFailIcon}*/}
                {/*        description={<LoadingFailedInfo onClick={handleReload} />}*/}
                {/*    />*/}
                {/*)}*/}

                <Sidebar
                    className={cn(styles.Sidebar)}
                    level={1}
                    isOpen={filtersModal.isOpen}
                    close={filtersModal.close}
                >
                    <header className={cn(styles.Header)}>
                        <h5 className={cn(styles.Title)}>{t('adminTransactions.filters.title')}</h5>
                        <Button
                            variant="text"
                            color="gray"
                            isRound
                            onClick={filtersModal.close}
                            iconStart={<CrossIcon />}
                        />
                    </header>
                    <main className={cn(styles.FiltersContent)}>jfjfjf</main>
                </Sidebar>
            </main>
        </section>
    );
}
