import React from 'react';
import classNames from 'classnames/bind';
import {
    GetFormControlPropsParams,
    SharableProps,
    FormControlFocusControlRef,
    FormControlProps
} from './FormControl.d';
import styles from './FormControl.module.scss';

const cx = classNames.bind(styles);

const getFormControlProps = <T extends object>(props: GetFormControlPropsParams<T>) => {
    const {
        containerClassName,
        error,
        label,
        help,
        labelRightContent,
        inputLeftContent,
        inputRightContent,
        inputWrapperClassName,
        ...restProps
    } = props;

    const formControlProps = {
        containerClassName,
        error,
        label,
        labelRightContent,
        help,
        inputLeftContent,
        inputRightContent,
        inputWrapperClassName
    };

    return [formControlProps, restProps] as [SharableProps, T];
};

/**
 * get required custom params, divide incoming props by
 * form control props and rest props
 */
export const useFormControl = <T extends object>(props: GetFormControlPropsParams<T>) => {
    const focusControl: FormControlFocusControlRef = React.useRef(null);

    return [focusControl, getFormControlProps(props)] as const;
};

function FormControl({
    error,
    label,
    help,
    labelRightContent,
    inputLeftContent,
    inputRightContent,
    containerClassName = '',
    inputWrapperClassName = '',
    children,
    focusControlRef
}: FormControlProps) {
    const [focus, setFocus] = React.useState(false);
    // eslint-disable-next-line no-param-reassign
    focusControlRef.current = {
        setFocus: () => {
            setFocus(true);
        },
        unsetFocus: () => {
            setFocus(false);
        }
    };

    return (
        <div className={cx('Component', error && 'error', focus && 'focus', containerClassName)}>
            {label && (
                <div className={cx('LabelWrapper')}>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className={cx('Label')}>{label}</label>
                    {labelRightContent && <div className={cx('LabelRightContent')}>{labelRightContent}</div>}
                </div>
            )}

            <div className={cx('InputWrapper', inputWrapperClassName)}>
                {inputLeftContent && <div className={cx('InputLeftContent')}>{inputLeftContent}</div>}

                {children}

                {inputRightContent && <div className={cx('InputRightContent')}>{inputRightContent}</div>}
            </div>

            {error && <p className={cx('TextError')}>{error}</p>}
            {help && !error && <p className={cx('TextHelp')}>{help}</p>}
        </div>
    );
}

export default FormControl;
