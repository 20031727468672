import React from 'react';
import cn from 'classnames';
import styles from './AdminDashboard.module.scss';
import StatisticsBalances from "../StatisticsBalances/StatisticsBalances";
import Table from "../../ui/Table/Table";
import {OrderSortTypeEnum, OrderTypeEnum, useMeQuery, useOrdersQuery} from "../../apollo/generated";
import formatDate from "../../helpers/formatDate";
import TableSkeleton from "../TableSkeleton/TableSkeleton";
import TextField from "../../ui/TextField/TextField";
import {SearchIcon} from "../../ui/Icons/Icons";
import useDebounce from "../../hooks/useDebounce/useDebounce";
import {validateIntToFloat} from "../../helpers/formatMoney";

const mockColumns = [
    {
        field: 'type',
        headerName: 'Тип',
    },
    {
        field: 'status',
        headerName: 'Статус'
    },
    {
        field: 'number',
        headerName: 'Номер',
        sortDesc: OrderSortTypeEnum.OrderIdDesc,
        sortAsc: OrderSortTypeEnum.OrderIdAsc
    },
    {
        field: 'time',
        headerName: 'Время оплаты',
    },
    {
        field: 'withdraw sum',
        headerName: 'Сумма к зачислению',
        sortDesc: OrderSortTypeEnum.AmountDesc,
        sortAsc: OrderSortTypeEnum.AmountAsc
    },
    {
        field: 'commission',
        headerName: 'Комиссия',
        sortDesc: OrderSortTypeEnum.FeeDesc,
        sortAsc: OrderSortTypeEnum.FeeAsc
    },
    {
        field: 'deposit sum',
        headerName: 'сумма пополнения',
        sortDesc: OrderSortTypeEnum.DepositAmountDesc,
        sortAsc: OrderSortTypeEnum.DepositAmountAsc
    },
    {
        field: 'id1',
        headerName: 'ID ПЛАТЕЖКИ',
    },
    {
        field: 'id2',
        headerName: 'ID Транзакции',
    }
];

const STEP = 10000;


export default function AdminDashboard() {
    const {data, loading, refetch: meRefetch} = useMeQuery()
    const [search, setSearch] = React.useState('');
    const [ordersArr, setOrdersArr] = React.useState([])
    const debouncedSearch = useDebounce(search);

    let myId

    if (data?.userInfo.__typename === "User") {
        myId = data.userInfo.uid
    }

    const {data: orders, loading: ordersLoading, fetchMore, refetch, variables} = useOrdersQuery({
        variables: {
            skip: 0,
            limit: STEP
        }
    })

    const currentSort = variables?.sortType;

    const handleLoadMore = () => {
        fetchMore({
            variables: {
                skip: orders?.orders.__typename === "Orders" ? orders.orders.orders.length : 0
            }
        })
    }

    const handleScroll = (e: any) => {
        if (!loading) {
            return
        };
        const { scrollHeight, scrollTop } = e.target;
        if (scrollHeight - (scrollTop + window.innerHeight) <= 100) {
            // handleLoadMore()
        }
    };

    React.useEffect(() => {
        document.addEventListener("scroll", (event) => handleScroll(event))

        return () => {
            document.removeEventListener("scroll", (event) => handleScroll(event))
        }
    }, [])

    React.useEffect(() => {
        refetch({
            q: debouncedSearch
        })
    }, [debouncedSearch])

    const transformOrderDataToRow = React.useCallback(() => {
        let res:any[] = []
        if (orders?.orders.__typename === "Orders") {
            res = orders.orders.orders.map((order, index) => ({
                options: {
                    type: 'options',
                    rowId: order.uid + index
                },
                content: [
                    {
                        component:
                            <div className={cn(styles.RowTypeLabel,
                                order.orderType === OrderTypeEnum.Deposit || order.orderType === OrderTypeEnum.UserDeposit
                                    ? styles.Success
                                    : styles.Danger)}
                            >
                                {order.orderType === OrderTypeEnum.Deposit || order.orderType === OrderTypeEnum.UserDeposit
                                    ? "Ввод"
                                    : "Вывод"}
                            </div>,
                        id: order.uid + index + 1
                    },
                    {
                        component: order.status,
                        id: order.uid + index + 2
                    },
                    {
                        component: order.orderId,
                        id: order.uid + index + 3
                    },
                    {
                        component: formatDate(order.created),
                        id: order.uid + index + 4
                    },
                    {
                        component: `${validateIntToFloat(order.amount)} ₽`,
                        id: order.uid + index + 5
                    },
                    {
                        component: `${validateIntToFloat(order.fee)} ₽`,
                        id: order.uid + index + 6
                    },
                    {
                        component: `${validateIntToFloat(order.depositAmount)} ₽`,
                        id: order.uid + index + 7
                    },
                    {
                        component: order.paymentProviderId,
                        id: order.uid + index + 8,
                        className: cn(styles.IdCell, styles.RowBorder)
                    },
                    {
                        component: order.paymentProviderId,
                        id: order.uid + index + 9,
                        className: cn( styles.IdCell, styles.RowBorder)
                    }
                ]
            }))
        }
        return res
    }, [orders])

    const handleChangeSort = (sort: any) => {
        refetch({
            skip: 0,
            sortType: sort
        })
    }

    const handleSortClick = React.useCallback(
        ({ sortAsc, sortDesc }: any) => {
            handleChangeSort(currentSort === sortAsc ? sortDesc : sortAsc);
        },
        [currentSort, handleChangeSort]
    );

    const handleOrder = () => {
        meRefetch()
        refetch()
    }

    return (
        <div className={cn(styles.Root)}>
            <div className={cn(styles.Header)}>
                <div className={cn(styles.Container)}>
                    <h1 className={cn(styles.Title)}>Дашборд</h1>
                </div>
            </div>
            <div className={cn(styles.Wrapper)}>
                <StatisticsBalances handleOrder={() => handleOrder()}/>
            </div>
            <div className={cn(styles.Wrapper)}>
                <div className={cn(styles.TableWrapper)}>
                    <header className={cn(styles.TransactionHeader)}>
                        <h3 className={cn(styles.TransactionTitle)}>Транзакции</h3>
                        <TextField
                            placeholder="Поиск"
                            className={cn(styles.Textfield)}
                            containerClassName={cn(styles.TextfieldContainer)}
                            inputWrapperClassName={cn(styles.TextfieldWrapper)}
                            inputLeftContent={<SearchIcon/>}
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                    </header>
                    {(loading || ordersLoading) && <TableSkeleton />}
                    <Table
                        rows={transformOrderDataToRow()}
                        columns={mockColumns}
                        onSortClick={handleSortClick}
                        currentSort={currentSort}
                        // onScroll={handleScroll}
                    />
                </div>
            </div>
        </div>
    );
}
