import React from 'react';
import cn from 'classnames';
import { SkeletonProps } from './Skeleton.d';
import styles from './Skeleton.module.scss';

const Skeleton = React.forwardRef<HTMLSpanElement, SkeletonProps>(
    ({ animation = 'wave', className, height, variant = 'default', style, width, ...props }: SkeletonProps, ref) => {
        const classes = cn(styles.Root, styles[`Animation-${animation}`], styles[`Variant-${variant}`], className);

        return (
            <span
                className={classes}
                style={{ width, height, ...style }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                ref={ref}
            />
        );
    }
);

export default Skeleton;
