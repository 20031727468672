export default {
    title: 'API ключ',
    title_generateAttention: 'Новый ключ',
    title_copyAttention: 'Новый ключ',

    description_generateAttention:
        'Вы уверены что хотите сгенерировать новый ключ? Старый ключ будет удален и недействителен',

    description_copyAttention:
        'Вы уверены что сохранили ключ в надежном месте, так как после закрытия данного окна, вы не сможете посмотреть новый ключ',
    description_new: 'Сохраните себе данный ключ, потому что вы не сможете его больше посмотреть',

    important: 'При генерации нового ключа, старый ключ будет удален и недействителен',
    important_new: 'Не передавайте ключ от вашего магазина посторонним лицам',

    key: 'Ключ API',
    generating: 'Генерация нового\nключа',
    copied: 'Ключ API скопирован',

    btnSubmit: 'Сгенерировать новый ключ',
    btnSubmit_agreeGenerating: 'Сгенерировать новый ключ',
    btnSubmit_cancelGenerating: 'Оставить старый ключ',
    btnSubmit_complete: 'Готово',
    btnSubmit_copied: 'Да, сохранил',
    btnSubmit_notCopied: 'Нет, не сохранил',

    error: {
        baseError: 'Произошла ошибка во время генерирования нового ключа. Попробуйте еще раз'
    }
};
