// import { useMeQuery, useUpdateStoreMutation } from 'apollo/generated';
import * as clipboard from 'clipboard-polyfill/text';
import { DRAG_ANIMATION_DURATION, LOCAL_STORAGE_KEY_TOKEN_AUTH, LOCAL_STORAGE_LNG_KEY } from 'constants/';
import { Languages } from 'i18n';
import merge from 'lodash/merge';
import { DateTime } from 'luxon';
import maxSize from 'popper-max-size-modifier';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { Id as ToastId, toast } from 'react-toastify';
import { ApplicationState } from 'store/reducers';

export type DeepPartial<T> = T extends Function ? T : T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T;

export const getErrorData = (e: any) => ({
    message: e?.networkError?.result?.errors?.[0]?.extensions || e?.graphQLErrors?.[0]?.message,
    code: e?.graphQLErrors?.[0]?.code as undefined | number | null
});

export type UseFormField<T> = {
    value: T;
    error: string;
    change: (v: T, er?: string) => void;
    changeError: React.Dispatch<React.SetStateAction<string>>;
};

export function useFormField<T>(initialValue: T, withResetErrorOnChange = true): UseFormField<T> {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState('');

    return {
        value,
        error,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        change: (v: T, error = '') => {
            setValue(v);
            if (withResetErrorOnChange || error) setError(error);
        },
        changeError: setError
    };
}

type UseDropdownParams = {
    onClose?(): void;
    popperOptions?: Parameters<typeof usePopper>[2];
};

/**
 *
 * @description
 * Helpful hook to craete fully contoled dropdown component
 * 1) pass `ref` to dropdown wrapper
 * 2) use `toggle` or `setOpen` actions to control visibility
 * 3) use `open` flag to display or hide dropdown's content
 * 4) for better content position use `popperRef` to select
 * dropdown content and `popper` object to pass correct position
 * @example
 * const dropdown = useDropdown()
 *
 * <div ref={dropdown.ref}>
 *     <button onClick={dropdown.toggle}>toggle content</div>
 *
 *     {dropdown.open && (
 *         <div
 *             ref={dropdown.popperRef}
 *             style={dropdown.popper.styles.popper}
 *             {...dropdown.popper.attributes.popper}
 *         >
 *             content
 *         </div>
 *     )}
 * </div>
 */
export function useDropdown({ onClose, popperOptions }: UseDropdownParams = {}) {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const popperWrapperRef = useRef<HTMLDivElement>(null);
    // @todo check reqirement of using state
    const [popperRef, setPopperRef] = useState<HTMLUListElement | HTMLDivElement | null>(null);

    const defaultPopperOptions: Parameters<typeof usePopper>[2] = useMemo(
        () => ({
            placement: 'bottom-start',

            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 4]
                    }
                },

                maxSize,
                {
                    name: 'applyMaxSize',
                    enabled: true,
                    phase: 'beforeWrite' as const,
                    requires: ['maxSize'],
                    fn({ state }: any) {
                        // eslint-disable-next-line no-param-reassign
                        state.styles.popper = {
                            ...state.styles.popper,
                            maxHeight: `${state.modifiersData.maxSize.height - 8}px`
                        };
                    }
                }
            ]
        }),
        []
    );

    const popper = usePopper(
        popperWrapperRef.current ?? ref.current,
        popperRef,
        merge(defaultPopperOptions, popperOptions)
    );

    const toggle = () => setOpen(!open);

    useEffect(() => {
        const mousedownListener = (e: MouseEvent) => {
            if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
                document.removeEventListener('mousedown', mousedownListener);
                setOpen(false);
            }
        };

        const keydownListener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setOpen(false);
                e.preventDefault();
                e.stopPropagation();
            }
        };

        if (open) {
            document.addEventListener('mousedown', mousedownListener);
            document.addEventListener('keydown', keydownListener);
        } else {
            document.removeEventListener('mousedown', mousedownListener);
            document.removeEventListener('keydown', keydownListener);
            onClose?.();
        }

        return () => {
            document.removeEventListener('keydown', keydownListener);
            document.removeEventListener('mousedown', mousedownListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return {
        open,
        toggle,
        ref,
        popperRef: setPopperRef,
        setOpen,
        popper,
        popperWrapperRef
    };
}

export function useStore<TSelected = unknown>(
    selector: (state: ApplicationState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
) {
    return useSelector(selector, equalityFn);
}

/**
 * Helper to control need we close sidebar on click/tap overlay or sidebar directly
 * @param close will fire on direct click on overlay
 *
 * @example
 * const overlayClickHandler = useOverlayClickHandler(close)
 *
 * <div {...overlayClickHandler.overlayProps} className='overlay'>
 *     <div {...overlayClickHandler.componentProps}>
 *         content
 *     </div>
 * </div>
 */
export const useOverlayClickHandler = (close?: () => void) => {
    const shouldCloseRef = useRef<null | boolean>(null);

    const setShouldClose = (v: null | boolean) => {
        shouldCloseRef.current = v;
    };

    const handleOverlayOnClick = () => {
        if (shouldCloseRef.current === null) {
            setShouldClose(true);
        }

        if (shouldCloseRef.current) {
            close?.();
        }

        setShouldClose(null);
    };

    return {
        overlayProps: { onClick: handleOverlayOnClick },
        componentProps: {
            onMouseDown: () => setShouldClose(false),
            onMouseUp: () => setShouldClose(false),
            onClick: () => setShouldClose(false)
        }
    };
};

export const useChangeClientLanguage = () => {
    const { i18n } = useTranslation();

    return (lng: Languages) => {
        i18n.changeLanguage(lng);
        localStorage.setItem(LOCAL_STORAGE_LNG_KEY, lng);
    };
};

export const useToast = (baseToastId?: string) => {
    const toastRef = useRef<ToastId>('');
    const baseToastIdRef = useRef(baseToastId ?? `${Math.random()}`);
    const tid = useRef<NodeJS.Timeout>();
    type ToastKeys = keyof typeof toast;
    return new Proxy(toast, {
        get(target, prop: ToastKeys) {
            const PROXY_KEYS = ['info', 'success', 'error', 'warn'] as const;
            if (PROXY_KEYS.includes(prop as any)) {
                const toastFn = target[prop as (typeof PROXY_KEYS)[number]];
                const proxy: typeof toastFn = (content, options?) => {
                    clearTimeout(tid.current as any as number);

                    // eslint-disable-next-line no-param-reassign
                    options = options ?? {};
                    // eslint-disable-next-line no-param-reassign
                    options.toastId = options.toastId ?? baseToastIdRef.current;

                    if (options?.toastId === toastRef.current) {
                        toast.update(toastRef.current, {
                            ...options,
                            render: content,
                            className: `${options.className ?? ''} DragAnimation`,
                            style: {
                                ...options.style,
                                animationDuration: `${DRAG_ANIMATION_DURATION}ms`
                            }
                        });

                        tid.current = setTimeout(() => {
                            toast.update(toastRef.current, {
                                ...options,
                                render: content,
                                style: options?.style,
                                className: options?.className ?? ''
                            });
                        }, DRAG_ANIMATION_DURATION);
                    } else {
                        toast.dismiss(toastRef.current);
                    }

                    toastRef.current = toastFn(content, {
                        ...options,
                        onClose: (props) => {
                            toastRef.current = '';
                            options?.onClose?.(props);
                        }
                    });
                    return toastRef.current;
                };

                return proxy;
            }
            return target[prop];
        }
    });
};

/**
 * Callback to check condition.
 *
 * @callback conditionCb
 * @param {number} width Width.
 * @returns {boolean}
 */

/**
 * Checks the status of a given condition.
 *
 * @param {callback} conditionCb
 * @returns [boolean, Function]
 */
export const useWidthCondition = (conditionCb: (width: number) => boolean) => {
    const check = () => conditionCb(window.innerWidth);
    const [condition, setCondition] = useState(check());

    const handleResize = () => setCondition(check);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [condition, handleResize] as const;
};
export const useCopy = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const toast = useToast();

    return (text: string, success: string, error: string, onCopied?: () => void) => {
        clipboard
            .writeText(text)
            .then(() => {
                toast.success(success);
                if (onCopied) {
                    onCopied();
                }
            })
            .catch(() => {
                toast.error(error);
            });
    };
};

export const formatPhone = (phone = '') => phone.replace(/[^\d+]/g, '');

export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);

export const AUTH_HEADER = 'Authorization';
export const constructAuthHeader = (token: string) => ({
    Authorization: `JWT ${token}`
});

// export const useHasStore = () => {
//     const { isAuth } = useStore((s) => s.Base);
//     const meQuery = useMeQuery({ skip: !isAuth });
//     const stores = meQuery?.data?.me?.stores;
//
//     return Boolean(stores?.length);
// };

export const useGlobalError = () => {
    const [t] = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const toast = useToast();
    return (msg?: string, tst = toast) => {
        tst.error(`${t('errors.globalError')}${msg ? `: ${msg}` : ''}`);
    };
};

export const useOverflowController = () => {
    const id = useRef(`${Math.random()}`).current;
    const blockScroll = (el: HTMLElement) => {
        window.overflowController = window.overflowController ?? {};
        window.overflowController[id] = el || true;
        document.body.style.overflow = 'hidden';
    };

    const unblockScroll = () => {
        window.overflowController = window.overflowController ?? {};
        delete window.overflowController[id];

        // eslint-disable-next-line @typescript-eslint/no-shadow
        Object.entries(window.overflowController).forEach(([id, element]) => {
            if (typeof element === 'object' && !element.parentElement) {
                delete window.overflowController[id];
            }
        });

        const hasOverflowElements = Object.values(window.overflowController).some(Boolean);

        if (!hasOverflowElements) {
            document.body.style.overflow = '';
        }
    };

    return {
        blockScroll,
        unblockScroll
    };
};

export const removeWhiteSpace = (v: string) => v.replace(/\s/g, '');
export const formatMoney = (value: string) => {
    const splitValue = value.split('.');
    splitValue[0] = splitValue[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$& ');
    return splitValue.join('.');
};

export const useIsTouchableDevice = () =>
    useMemo(
        () =>
            'ontouchstart' in window ||
            window.navigator.maxTouchPoints > 0 ||
            (window.navigator as any).msMaxTouchPoints > 0,
        []
    );

// export const useChangeDefaultStore = () => {
//     const [updateStoreMutation] = useUpdateStoreMutation();
//     const onGlobalError = useGlobalError();
//     const [t] = useTranslation();
//     // eslint-disable-next-line @typescript-eslint/no-shadow
//     const toast = useToast();
//
//     return (storeId: string, onSuccess?: () => void) => {
//         updateStoreMutation({
//             variables: {
//                 input: {
//                     storeId,
//                     isDefault: true
//                 }
//             }
//         })
//             .then(({ data }) => {
//                 const updateStore = data?.updateStore! ?? {};
//                 if (updateStore.__typename === 'UpdateStoreSuccess') {
//                     onSuccess?.();
//                     toast.success(
//                         t('global.defaultStoreChanged', {
//                             name: updateStore.me?.defaultStore?.name
//                         })
//                     );
//                 } else {
//                     onGlobalError(updateStore.errorMessage, toast);
//                 }
//             })
//             .catch((e) => {
//                 onGlobalError(getErrorData(e).message, toast);
//             });
//     };
// };

export const createEosTransactionLink = (trxid = '') => `https://bloks.io/transaction/${trxid}`;

export const createEosAccountLink = (account = '') => `https://bloks.io/account/${account}`;

export const useFormatDate = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t, { language }] = useTranslation();
    return (date: string) => DateTime.fromISO(`${date}Z`, { zone: 'local' }).setLocale(language);
};

export const useGetDateTime = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t, { language }] = useTranslation();
    return {
        getDateTimeFromISO: (isoDateString = '') => DateTime.fromISO(isoDateString).setLocale(language)
    };
};
