import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// import { useResendCodeMutation, useVerifyCodeMutation } from 'apollo/generated';
import { constructAuthHeader, getErrorData, useFormField, useGlobalError, useToast } from 'helpers';
import { ClockIcon } from 'ui/Icons/Icons';
import { ModalDescription } from 'ui/Modal/Modal';
import Button from 'ui/Button/Button';
import Link from 'ui/Link/Link';
import useCountdownTimer from '../../hooks/useCountdownTimer/useCountdownTimer';
import CodeInput from '../AuthCodeInput/AuthCodeInput';
import { AuthCodeProps, ResendBtnProps } from './AuthCode.d';
import styles from './AuthCode.module.scss';

const cx = classNames.bind(styles);
const TIMER_TIME = 60;

function ResendBtn({ onBlock, stopTrigger, verificationToken }: ResendBtnProps) {
    const [t] = useTranslation();
    // const [resendCodeMutation, { loading }] = useResendCodeMutation();
    const resendTimer = useCountdownTimer(TIMER_TIME);
    const toast = useToast();
    const onGlobalError = useGlobalError();

    /** On any try to send code we have to stop timer */
    React.useEffect(() => {
        if (stopTrigger) resendTimer.stop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopTrigger]);

    const onClickResend = () => {
        // resendCodeMutation({
        //     context: verificationToken ? { headers: constructAuthHeader(verificationToken) } : undefined
        // })
        //     .then(({ data }) => {
        //         const resendCode = data?.resendCode!;
        //         if (resendCode.__typename === 'ResendCodeSuccess') {
        //             resendTimer.reset();
        //             toast.success(t('formCode.resendCode.alert.success'));
        //         } else {
        //             const { __typename, errorMessage = '' } = resendCode ?? {};
        //
        //             if (__typename === 'NoResendAttemptsError' && onBlock) {
        //                 onBlock();
        //             } else {
        //                 onGlobalError(errorMessage, toast);
        //             }
        //         }
        //     })
        //     .catch((e) => {
        //         onGlobalError(getErrorData(e).message, toast);
        //     });
    };

    if (resendTimer.timeLeft <= 0)
        return (
            <div className={cx('ResendBtnWrapper')}>
                {t('formCode.resendBtn_description')}
                <Link
                    as="button"
                    data-testid="btn-resend-code"
                    // disabled={loading}
                    size="small"
                    onClick={onClickResend}>
                    {t('formCode.resendBtn')}
                </Link>
            </div>
        );

    const minutes = parseInt(`${resendTimer.timeLeft / 60}`, 10);
    const seconds = parseInt(`${resendTimer.timeLeft % 60}`, 10);

    return (
        <p className={cx('Timer')}>
            {t('formCode.resendTimer')}
            <span className={cx('TimerTime')}>
                <ClockIcon /> {minutes}:{seconds.toString().padStart(2, '0')}
            </span>
        </p>
    );
}

function AuthCode({ description, btnSubmitText, className, onSuccess, onBlock, verificationToken }: AuthCodeProps) {
    const [t] = useTranslation();
    // const [verifyCodeMutation, { loading }] = useVerifyCodeMutation();
    const code = useFormField('');
    const toast = useToast();
    const onGlobalError = useGlobalError();

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        code.changeError('');

        // verifyCodeMutation({
        //     context: verificationToken ? { headers: constructAuthHeader(verificationToken) } : undefined,
        //     variables: {
        //         input: {
        //             code: code.value
        //         }
        //     }
        // })
        //     .then(async ({ data }) => {
        //         const verifyCode = data?.verifyCode!;
        //
        //         if (verifyCode.__typename === 'VerifyCodeSuccess' && onSuccess) {
        //             onSuccess(verifyCode.token);
        //         } else {
        //             const {
        //                 __typename,
        //                 // @ts-ignore
        //                 errorMessage = ''
        //             } = verifyCode ?? {};
        //
        //             if (__typename === 'InvalidVerifyCodeError') {
        //                 code.changeError(t(['formCode.alert.invalidCode', errorMessage]));
        //             } else if (__typename === 'VerifyCodeExpiredError') {
        //                 code.changeError(t(['formCode.alert.codeExpired', errorMessage]));
        //             } else {
        //                 onGlobalError(errorMessage, toast);
        //             }
        //         }
        //     })
        //     .catch((err) => onGlobalError(getErrorData(err).message, toast));
    };

    return (
        <form data-testid="form-code" onSubmit={onSubmit} className={cx('Form', className)}>
            <ModalDescription>{description}</ModalDescription>

            <div className={cx('Field')}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>{t('formCode.field')}</label>
                <CodeInput
                    name="code"
                    inputMode="numeric"
                    error={!!code.error}
                    value={code.value}
                    fields={6}
                    onChange={code.change}
                />

                {code.error && <p className={cx('FieldError')}>{code.error}</p>}
            </div>

            <Button
                classes={{ root: cx('BtnSubmit') }}
                // loading={loading}
                data-testid="btn-submit"
                size="large"
                type="submit"
                fullWidth
            >
                {btnSubmitText}
            </Button>

            {/*<ResendBtn verificationToken={verificationToken} onBlock={onBlock} stopTrigger={loading} />*/}
        </form>
    );
}

export default AuthCode
