import React from 'react';
import cn from 'classnames';
// import validator from 'helpers/validator';
// import Button from 'ui/Button/Button';
// import { FilterIcon, PlusIcon, WarningShieldIcon } from 'ui/Icons/Icons';
// import { Input } from 'ui/TextField/TextField';
import styles from './AdminTransactionsHeader.module.scss';

export default function AdminTransactionsHeader(
    // filterLabel: string;
    // onChangeSearch: (value: string) => void;
    // onClickBtnAdd: () => void;
    // onClickBtnFilter: () => void;
    // searchPlaceholder: string;
    // searchValue: string;
) {
    // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     onChangeSearch?.(validator(e, searchValue ?? ''));
    // };

    return (
        <header className={cn(styles.Header)}>
            <div className={cn(styles.HeaderInfo)}>
                <h2 className={cn(styles.HeaderTitle)}>Дашборд</h2>
            </div>
            {/*<div className={cn(styles.HeaderFilters)}>*/}
            {/*    <Button*/}
            {/*        color="danger"*/}
            {/*        variant="outlined"*/}
            {/*        iconStart={<WarningShieldIcon />}*/}
            {/*        iconEnd={<div className={cn(styles.ErrorsNumber)}>2</div>}*/}
            {/*    >*/}
            {/*        Failed transactions*/}
            {/*    </Button>*/}

            {/*    <Button*/}
            {/*        color="secondary"*/}
            {/*        isDark*/}
            {/*        iconStart={<FilterIcon />}*/}
            {/*        onClick={onClickBtnFilter}*/}
            {/*        variant="outlined"*/}
            {/*    >*/}
            {/*        {filterLabel}*/}
            {/*    </Button>*/}

            {/*    <Input*/}
            {/*        type="search"*/}
            {/*        className={cn(styles.SearchInput)}*/}
            {/*        value={searchValue}*/}
            {/*        placeholder={searchPlaceholder}*/}
            {/*        onChange={handleSearchChange}*/}
            {/*    />*/}

            {/*    <Button iconStart={<PlusIcon />} onClick={onClickBtnAdd}>*/}
            {/*        Download*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </header>
    );
}
