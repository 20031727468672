import React from 'react';
import cn from 'classnames';
import AuthLogin from '../AuthLogin/AuthLogin';
import AuthSignup from '../AuthSignup/AuthSignup';
import AuthForgotPassword from '../AuthForgotPassword/AuthForgotPassword';
// import LanguageSwitcher from '../AuthLanguageSwitcher/AuthLanguageSwitcher';
import Logo from '../Logo/Logo';
import { AuthProps, FormType } from './Auth.d';
import styles from './Auth.module.scss';

function Form(formType: FormType, isMobile: boolean, onChangeFormType: (type: FormType) => () => void) {
    switch (formType) {
        // case 'app':
        //     return (
        //         <AuthLogin
        //             isMobile={isMobile}
        //             onOpenForgotPassword={onChangeFormType('forgotPassword')}
        //             onOpenQr={onChangeFormType('qr')}
        //             onOpenApp={onChangeFormType('app')}
        //             onOpenSignup={onChangeFormType('signup')}
        //         />
        //     );
        case 'login':
            return (
                <AuthLogin
                    isMobile={isMobile}
                    onOpenForgotPassword={onChangeFormType('forgotPassword')}
                    onOpenQr={onChangeFormType('qr')}
                    onOpenApp={onChangeFormType('app')}
                    onOpenSignup={onChangeFormType('signup')}
                />
            );
        case 'signup':
            return (
                <AuthSignup
                    isMobile={isMobile}
                    onOpenLogin={onChangeFormType('login')}
                    onOpenQr={onChangeFormType('qr')}
                    onOpenApp={onChangeFormType('app')}
                />
            );
        case 'forgotPassword':
            return (
                <AuthForgotPassword
                    isMobile={isMobile}
                    onOpenLogin={onChangeFormType('login')}
                />
            );
        default:
            return null;
    }
}

export default function Auth({ isMobile, formType }: AuthProps) {
    const [formTypeState, setFormTypeState] = React.useState<FormType>(formType || "login");

    const handleChangeFormType = React.useCallback((type: FormType) => () => {
        setFormTypeState(type)
    }, []);
    return (
        <div className={cn(styles.Root)}>
            {!isMobile && (
                <div className={cn(styles.Header)}>
                    <Logo />
                    {/* TODO: Add language switcher */}
                    {/* <LanguageSwitcher /> */}
                </div>
            )}
            {Form(formTypeState, isMobile, handleChangeFormType)}
        </div>
    );
}
