import React from 'react';
import classNames from 'classnames/bind';
import styles from './CurrencyIcon.module.scss';

const cx = classNames.bind(styles);

type Currency = 'USDCASH' | 'UAHCASH' | 'RUBCASH';

interface CurrencyIconProps {
    /**
     * @default 24
     */
    size?: number;
    className?: string;
    currency: string | Currency;
}

function CurrencyIcon({ className = '', currency, size = 24 }: CurrencyIconProps) {
    const Icon = React.useMemo(() => {
        if (currency === 'USDCASH') {
            // eslint-disable-next-line global-require
            return require('assets/images/token-usdcash.svg').default;
        }
        if (currency === 'UAHCASH') {
            // eslint-disable-next-line global-require
            return require('assets/images/token-uahcash.svg').default;
        }
        if (currency === 'RUBCASH') {
            // eslint-disable-next-line global-require
            return require('assets/images/token-rubcash.svg').default;
        }
        if (currency === 'RUBLES') {
            // eslint-disable-next-line global-require
            return require('assets/images/token-rubles.svg').default;
        }
        if (currency === 'USDT') {
            // eslint-disable-next-line global-require
            return require('assets/images/token-usdt.svg').default;
        }
        return '';
    }, [currency]);

    return (
        <span className={cx('Component', className)}>
            <img src={Icon} alt="" width={size} height={size} />
        </span>
    );
}

export default CurrencyIcon;
