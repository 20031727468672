export default {
    dataNotAvailable: 'Данные отсутствуют',
    downloadReport: 'Скачать отчёт',
    error: 'Ошибка',
    failedTransactions: 'Неудачные транзакции',
    filters: {
        title: 'Фильтры'
    },
    loadingFailed: `При загрузке произошла ошибка.
                    Попробуйте обновить страницу`,
    nothingFound: 'Ничего не найдено',
    nothingFoundDescription: `Мы не смогли ничего найти по вашему
        запросу. Проверьте правильность
        написания и попробуйте еще раз`,
    search: 'Поиск',
    sort: 'Сортировка',
    status: {
        ACCEPTED: 'Оплачено',
        DECLINED: 'Отмена',
        FAILED: 'Ошибка',
        INITIATED: 'Ожидание',
        NEW: 'Новый',
        RECEIVED: 'Получено',
        SENDED: 'Отправлено'
    },
    title: 'Транзакции',

    tableHeader: {
        type: 'Тип',
        status: 'Статус',
        number: 'Номер',
        createdDate: 'Время создания',
        paymentDate: 'Время оплаты',
        amount: 'Сумма',
        commission: 'Комиссия',
        token: 'Токен',
        network: 'Сеть',
        paymentMethod: 'Способ оплаты',
        user: 'Пользователь',
        store: 'Магазин'
    },
    type: {
        CLIENT_WITHDRAW: 'Вывод',
        DEPOSIT: 'Пополнение',
        ORDER_PAYMENT: 'Оплата',
        PAYBACK: 'Возврат',
        UNKNOWN: 'Неизвестно',
        WITHDRAW: 'Отказ'
    },
    update: 'Обновить'
};
