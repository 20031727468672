import React from 'react';
import classNames from 'classnames/bind';
import uuidv4 from "utils/utils";
import Modal, {ModalDescription, ModalTitle, useModalContext} from 'ui/Modal/Modal';
import {ModalProps} from 'ui/Modal/Modal.d';
import Button from 'ui/Button/Button';
// import Alert from 'ui/Alert/Alert';
import TextField from "ui/TextField/TextField";
import styles from './DepositModal.module.scss';
import {CreateOrder, OrderTypeEnum, useCreateOrderMutation} from "../../apollo/generated";
import {useToast} from "../../helpers";
import {validateAmountForRequest} from "../../helpers/formatMoney";

const cx = classNames.bind(styles);

type Props = {
    orderType: OrderTypeEnum,
    handleOrder: () => void,
    balance: number
};

function Main({ orderType, handleOrder, balance }: Props) {
    // const isTouchableDevice = useIsTouchableDevice();
    // const [t] = useTranslation();
    // const depositParamsQuery = useDepositParamsQuery({
    //     variables: { currencyId }
    // });
    const [createOrderMutation] = useCreateOrderMutation()
    const [amount, setAmount] = React.useState('')
    const [comment, setComment] = React.useState('')
    const {close} = useModalContext()
    const toast = useToast()
    // const depositParams = depositParamsQuery.data?.depositParams! ?? {};
    // if (depositParamsQuery.loading)
    //     return (
    //         <div className={cx('Preloader')}>
    //             <Preloader />
    //         </div>
    //     );

    const handleDeposit = () => {
        const order: CreateOrder = {
            amount: Number(validateAmountForRequest(amount)) * 100,
            description: comment,
            orderType,
            orderId: uuidv4(),
            successRedirectUrl: "",
            failureRedirectUrl: ""
        }
        const options = {
            style: {
                borderRadius: "16px",
                padding: "16px"
            }
        }
        if (orderType === OrderTypeEnum.Withdraw || orderType === OrderTypeEnum.UserWithdraw) {
            if (balance <= 0) {
                toast.error("Вывод средств невозможен, проверьте баланс", options)
                return
            }
            if (Number(amount) * 100 > balance) {
                toast.error("Недостаточно средств для вывода", options)
                return
            }
        }
        createOrderMutation({
            variables: {
                order
            }
        }).then(({data}) => {
            handleOrder()
            toast.success(`Заявка на ${orderType === OrderTypeEnum.Deposit ? 'пополнение счета' : 'вывод средств'} была успешно отправлена.`, options)
            close()
        }).catch(async (error) => {
            toast.error("Ошибка", options)
            close()
        })
    }

    return (
        <>
            <div className={cx('Main')}>
                <ModalTitle withCloseBtn>{orderType === OrderTypeEnum.UserDeposit ? "Пополнить" : "Вывести"}</ModalTitle>
                <ModalDescription />
                <TextField
                    placeholder='Введите сумму'
                    label='Сумма'
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                />
                <TextField
                    placeholder='Введите комментарий'
                    label='Комментарий (не обязательно)'
                    value={comment} onChange={(e) => setComment(e.target.value)}
                />
            </div>
            {/*{isTouchableDevice && (*/}
                <div className={cx('Footer')}>
                    <Button  size="large" fullWidth onClick={() => handleDeposit()} disabled={!amount}>
                        Оставить заявку
                    </Button>
                </div>
            {/*)}*/}
        </>
    );
}

export default function ModalDeposit({ handleOrder, orderType, balance, ...props }: ModalProps & Props) {
    // const isTouchableDevice = useIsTouchableDevice();
    return (
        <Modal
            // className={cx('Component', isTouchableDevice && 'isTouchable')}
            className={cx('Component', 'isTouchable')}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <Main orderType={orderType} handleOrder={handleOrder} balance={balance}/>
        </Modal>
    );
}
