import React from 'react';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useStore } from 'helpers';
import { setAuth, setShowModalSession } from 'store/Base/actions';
import Modal, { ModalDescription, ModalStatus, ModalTitle } from 'ui/Modal/Modal';
import Button from 'ui/Button/Button';
import styles from './SessionModal.module.scss';

const cx = classNames.bind(styles);

export default function SessionModal() {
    const { showModalSession } = useStore((s) => s.Base);
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory()
    /** Leave dashboard UI available while user closes the modal */
    const close = React.useCallback(() => {
        dispatch(setAuth(false));
        dispatch(setShowModalSession(false));
        history.push("/")
    }, [dispatch]);

    return (
        <Modal data-testid="modal-session" isOpen={showModalSession} close={close}>
            <div className={cx('Component')}>
                <ModalStatus bgColor="blue" type="info" />
                <ModalTitle>{t('modalSession.title')}</ModalTitle>
                <ModalDescription>{t('modalSession.description')}</ModalDescription>
                <div className={cx('BtnGroup')}>
                    <Button size="large" onClick={close}>
                        {t('modalSession.btnSubmit')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
