const toInt = (v: number) => Number(v.toFixed(0));

const MINIMAL_FEE_IN_COIN = 0;
const MAX_FEE = 25000000;

export const getTotalByReceive = (receive: number, precission = 5, fee = 0) => {
    if (receive <= 0) return 0;

    const precissionInt = 10 ** precission;
    const feeReverse = 1 - fee;

    const receiveInt = toInt(receive * precissionInt);

    /**
     * @important
     * If total after calc become  to be less, than minimal required total for
     * dynamic fee - just add minimal fee to `receive` value
     */
    let totalInt = toInt(Math.floor(receiveInt / feeReverse));
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (totalInt < getMinimalTotal(fee)) {
        totalInt = receiveInt + MINIMAL_FEE_IN_COIN;
    }

    const totalFee = totalInt - receiveInt;
    if (totalFee > MAX_FEE) {
        totalInt -= totalFee - MAX_FEE;
    }

    return totalInt / precissionInt;
};

export const getReceiveByTotal = (total: number, precission = 5, fee = 0) => {
    if (total <= 0) return 0;

    const precissionInt = 10 ** precission;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const feeInCoins = toInt(getFeeFromTotal(total, precission, fee) * precissionInt);

    const totalInt = toInt(total * precissionInt);

    return toInt(totalInt - feeInCoins) / precissionInt;
};

/**
 * @description Get fee in tokens by total value. Includes checking for minimal possible fee
 * @param total - float string or number
 * @param precission - int
 * @param fee - float in range 0 to 1
 * @returns float number
 */
export const getFeeFromTotal = (total: string | number, precission = 5, fee = 0) => {
    const precissionInt = 10 ** precission;
    const totalInt = toInt(Number(total) * precissionInt);

    if (!+total) return 0;

    const feeInt =
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        totalInt < getMinimalTotal(fee) ? MINIMAL_FEE_IN_COIN : Math.min(Math.floor(totalInt * fee), MAX_FEE);

    return feeInt / precissionInt;
};

// const getMinimalTotal = (fee = 0) => MINIMAL_FEE_IN_COIN / fee
const getMinimalTotal = (fee = 0) => fee || 1;

/**
 *
 * @param total - float string
 * @param precission - int
 * @param min - int (minimal amount in coins). By default, it is `MINIMAL_FEE_IN_COIN + 1`
 * @returns
 */
export const validateMinTotal = (total: string | number, precission = 5, min = MINIMAL_FEE_IN_COIN + 1) => {
    const precissionInt = 10 ** precission;
    return toInt(+total * precissionInt) >= min;
};

export const validateIntToFloat = (number: number | string | undefined | null) => {
    if (!number) return "0.00"
    if (Number(number) / 100 % 1 > 0) {
        return Number(number) / 100
    }
    return (Number(number) / 100).toFixed(2)
}

export const validateAmountForRequest = (amount: string) => {
    if (Number(amount)) return Number(amount).toFixed(2)
    const validatedAmount = amount.replace(",", ".")
    return Number(validatedAmount).toFixed(2)
}
