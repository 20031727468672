import React from 'react';
import classNames from 'classnames/bind';
import {useTranslation} from 'react-i18next';
import useModal from 'hooks/useModal/useModal';
import {ReactComponent as IconErrorStatus} from 'assets/images/status-error.svg';
import Button from 'ui/Button/Button';
import Preloader from 'ui/Preloader/Preloader';
import {OrderTypeEnum, useMeQuery} from "../../apollo/generated";
import ModalDeposit from '../DepositModal/DepositModal';
import CurrencyIcon from '../CurrencyIcon/CurrencyIcon';
import styles from './StatisticsBalances.module.scss';
import {ArrowBottomCornerIcon, ArrowTopCornerIcon} from "../../ui/Icons/Icons";
import {validateIntToFloat} from "../../helpers/formatMoney";

const cx = classNames.bind(styles);

interface Props {
    handleOrder: () => void
}
function StatisticsBalances({handleOrder}: Props) {
    const [t] = useTranslation();
    const modalDeposit = useModal<{ currency: { id: string } }>();
    const {data, loading, error, refetch} = useMeQuery();
    const [orderTypeState, setOrderTypeState] = React.useState<OrderTypeEnum>(OrderTypeEnum.Deposit)
    let balance: number = 0;
    let balanceOutgoing: number = 0;
    let balanceIncomming: number = 0;

    if (data?.userInfo.__typename === "User") {
        balance = data.userInfo.balance
        balanceOutgoing = data.userInfo.balanceOutgoing
        balanceIncomming = data.userInfo.balanceIncoming
    }

    const handleModalOpen = (orderType: OrderTypeEnum) => {
        setOrderTypeState(orderType)
        modalDeposit.open()
    }

    if (error)
        return (
            <div className={cx('ErrorScreen')}>
                <IconErrorStatus />
                <div className={cx('ErrorScreenContent')}>
                    <h6>{t('pageStatistics.balances.errorScreen.title')}</h6>
                    <p>{t('pageStatistics.balances.errorScreen.text')}</p>
                </div>

                <Button
                    disabled={loading}
                    onClick={() => refetch()}
                >
                    {t('pageStatistics.balances.errorScreen.btn')}
                </Button>
            </div>
        );

    return (
        <section className={cx('Component')}>
            <header className={cx('Header')}>
                <h3 className={cx('Title')}>Баланс</h3>
            </header>

            <main className={cx('Main')}>
                {loading ? (
                    <div className={cx('Preloader', balance && 'absolute')}>
                        <Preloader />
                    </div>
                ) : (
                    <div className={cx('Balance')}>
                            <div className={cx('BalanceDetails')}>
                                <div className={cx('BalanceMain')}>
                                    <CurrencyIcon currency="RUBCASH" />
                                    <div>
                                        <b className={cx('BalanceAmount')}>{validateIntToFloat(balance)} ₽</b>
                                        <span className={cx('BalanceName')}>Текущий баланс</span>
                                    </div>
                                </div>
                                <div className={cx('BalanceMain')}>
                                    <Button isRound iconStart={<ArrowBottomCornerIcon />} color='gray'/>
                                    <div>
                                        <b className={cx('BalanceAmount')}>{validateIntToFloat(balanceOutgoing)} ₽</b>
                                        <span className={cx('BalanceName')}>На вывод</span>
                                    </div>
                                </div>
                                <div className={cx('BalanceMain')}>
                                    <Button isRound iconStart={<ArrowTopCornerIcon />} color='gray'/>
                                    <div>
                                        <b className={cx('BalanceAmount')}>{validateIntToFloat(balanceIncomming)} ₽</b>
                                        <span className={cx('BalanceName')}>На пополнение</span>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('BalanceActions')}>
                                <Button
                                    // disabled={!Number(balance.amount)}
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => handleModalOpen(OrderTypeEnum.UserWithdraw)}
                                    type="button"
                                >
                                    Вывести
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => handleModalOpen(OrderTypeEnum.UserDeposit)}
                                    type="button"
                                >
                                    Пополнить
                                </Button>
                            </div>
                        </div>
                )}
            </main>

            <ModalDeposit
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...modalDeposit}
                handleOrder={handleOrder}
                orderType={orderTypeState}
                balance={balance}
            />
        </section>
    );
}
export default React.memo(StatisticsBalances);
