import { createStore, compose } from 'redux';
import rootReducer from './reducers';

/**
 * Create a Redux store that holds the app state.
 */
const store = createStore(
    rootReducer,
    compose(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f)
);

export default store;
