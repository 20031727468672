// Fork of https://github.com/40818419/react-code-input
import React, { memo, useState, useEffect, useRef, useMemo, FocusEvent, ChangeEvent } from 'react';
import classNames from 'classnames/bind';
import styles from './AuthCodeInput.module.scss';
import uuidv4 from '../../utils/utils';

const cx = classNames.bind(styles);

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

export type InputModeTypes = 'numeric' | 'tel' | 'email' | 'url';

type Props = {
    error?: boolean;
    // Accepted input type
    type?: 'text' | 'number' | 'password' | 'tel';
    // Allowed amount of characters to enter.
    fields?: number;
    // Value of the input
    value?: string;
    // Get the full value of the input on every change
    onChange?: (value: string) => void;
    // Setting the name of component.
    name: string;
    // Marks the given fields as "touched" to show errors.
    touch?: (name: string) => void;
    // Clears the "touched" flag for the given fields.
    untouch?: (name: string) => void;
    // Add classname to the root element.
    className?: string;
    isValid?: boolean;
    // When present, it specifies that the element should be disabled.
    disabled?: boolean;
    readOnly?: boolean;
    // Setting the styles of container element.
    style?: React.CSSProperties;
    // Setup autofocus on the first input, true by default.
    autoFocus?: boolean;
    //
    forceUppercase?: boolean;
    // Filter characters on key down.
    filterKeyCodes?: Array<number>;
    // Filter characters.
    filterChars?: Array<string>;
    // Filter above acts as blacklist if false, whitelist if true; false by default.
    filterCharsIsWhitelist?: boolean;
    // The pattern prop specifies a regular expression that the element's value is checked against.
    pattern?: string;
    // The inputMode prop tells the browser on devices with dynamic keyboards which keyboard to display.
    inputMode: InputModeTypes;
};

/** @todo Refactor this all */
const CodeInput = memo(
    ({
        error,
        autoFocus = true,
        isValid = true,
        disabled = false,
        readOnly = false,
        forceUppercase = false,
        fields = 4,
        value = '',
        type = 'text',
        filterKeyCodes = [189, 190],
        filterChars = ['-', '.'],
        filterCharsIsWhitelist = false,
        className,
        pattern,
        inputMode,
        ...props
    }: Props) => {
        const textInput = useRef<(HTMLInputElement | null)[]>([]);
        const uuid = useMemo(uuidv4, []);
        const [state, setState] = useState({
            value: forceUppercase ? value.toUpperCase() : value,
            fields,
            type,
            input: [] as string[],
            filterKeyCodes
        });

        useEffect(() => {
            for (let i = 0; i < Number(state.fields); i += 1) {
                if (i < 32) {
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    const value = state.value[i] || '';
                    state.input.push(value);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            const input = [];
            for (let i = 0; i < state.fields; i += 1) {
                if (i < 32) {
                    input.push(value[i] || '');
                }
            }

            setState({
                ...state,
                input,
                value
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const handleTouch = (value: string) => {
            const { touch, untouch, name } = props;
            if (touch && untouch) {
                if (value === '') {
                    touch(name);
                } else {
                    untouch(name);
                }
            }
        };

        const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
            handleTouch(e.target.value);
        };

        const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            let value = String(e.target.value);
            if (forceUppercase) {
                value = value.toUpperCase();
            }

            if (type === 'number') {
                value = value.replace(/[^\d]/g, '');
            }

            /** Filter Chars */
            value = value
                .split('')
                .filter((currChar) => {
                    if (filterCharsIsWhitelist) {
                        return filterChars.includes(currChar);
                    }
                    return !filterChars.includes(currChar);
                })
                .join('');

            let fullValue = value;

            if (value !== '') {
                const input = state.input.slice();
                if (value.length > 1) {
                    value.split('').map((chart, i) => {
                        if (Number(e.target.dataset.id) + i < fields) {
                            input[Number(e.target.dataset.id) + i] = chart;
                        }
                        return false;
                    });
                } else {
                    input[Number(e.target.dataset.id)] = value;
                }

                input.forEach((s, i) => {
                    if (textInput.current?.[i]) {
                        textInput.current[i]!.value = s;
                    }
                });

                const ind =
                    Number(e.target.dataset.id) < input.length ? Number(e.target.dataset.id) + 1 : e.target.dataset.id;

                const newTarget = textInput.current?.[ind as any];

                if (newTarget) {
                    newTarget.focus();
                    newTarget.select();
                }

                fullValue = input.join('');

                setState({ ...state, value: input.join(''), input });
            }

            if (props.onChange && fullValue) {
                props.onChange(fullValue);
            }

            handleTouch(fullValue);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const target = Number((e.target as HTMLInputElement).dataset.id);
            const nextTarget = textInput.current?.[target + 1];
            const prevTarget = textInput.current?.[target - 1];

            let input;
            // eslint-disable-next-line @typescript-eslint/no-shadow
            let value;

            if (state.filterKeyCodes.length > 0) {
                // eslint-disable-next-line array-callback-return,consistent-return
                state.filterKeyCodes.map((item) => {
                    // TODO: Change deprecated `keyCode`
                    if (item === e.keyCode) {
                        e.preventDefault();
                        return true;
                    }
                });
            }
            // TODO: Change deprecated `keyCode`
            switch (e.keyCode) {
                case BACKSPACE_KEY: {
                    e.preventDefault();
                    if (textInput.current?.[target]) textInput.current[target]!.value = '';
                    input = state.input.slice();
                    input[target] = '';
                    value = input.join('');

                    setState({ ...state, value, input });
                    if (textInput.current?.[target]!.value === '') {
                        if (prevTarget) {
                            prevTarget.focus();
                            prevTarget.select();
                        }
                    }
                    props.onChange?.(value);
                    break;
                }

                case LEFT_ARROW_KEY:
                    e.preventDefault();
                    if (prevTarget) {
                        prevTarget.focus();
                        prevTarget.select();
                    }
                    break;

                case RIGHT_ARROW_KEY:
                    e.preventDefault();
                    if (nextTarget) {
                        nextTarget.focus();
                        nextTarget.select();
                    }
                    break;

                case UP_ARROW_KEY:
                    e.preventDefault();
                    break;

                case DOWN_ARROW_KEY:
                    e.preventDefault();
                    break;

                case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
                    if ((e.target as HTMLInputElement).type === 'number') {
                        e.preventDefault();
                        break;
                    }
                    break;

                default:
                    break;
            }

            handleTouch(value ?? '');
        };

        const { input } = state;

        return (
            <div className={cx('Container', error && 'error', className)}>
                {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                {input.map((value, i) => (
                    <input
                        // eslint-disable-next-line no-return-assign
                        ref={(ref) => (textInput.current[i] = ref)}
                        id={`${uuid}-${i}`}
                        data-id={i}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={autoFocus && i === 0 ? true : undefined}
                        value={value}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`input_${i}`}
                        type={type}
                        min={0}
                        max={9}
                        maxLength={input.length === i + 1 ? 1 : input.length}
                        autoComplete="off"
                        onFocus={(e) => e.target.select()}
                        onBlur={(e) => handleBlur(e)}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        readOnly={readOnly}
                        disabled={disabled}
                        data-valid={isValid}
                        pattern={pattern}
                        inputMode={inputMode}
                    />
                ))}
            </div>
        );
    }
);

export default CodeInput;
