import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {useSigninMutation} from 'apollo/generated';
import { getErrorData, useFormField, useGlobalError, useToast } from 'helpers';
import validator from 'helpers/validator';
import { signIn, setAdmin } from 'helpers/sign';
import { DRAG_ANIMATION_DURATION } from 'constants/';
import Link from 'ui/Link/Link';
import Button from 'ui/Button/Button';
import PasswordField from 'ui/PasswordField/PasswordField';
// import LanguageSwitcher from '../AuthLanguageSwitcher/AuthLanguageSwitcher';
import AuthLoginTabs, { useLoginType } from '../AuthLoginTabs/AuthLoginTabs';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import { AuthLoginProps } from './AuthLogin.d';
// import styles from './AuthLogin.module.scss';

function AuthLogin({ isMobile, onOpenForgotPassword, onOpenApp }: AuthLoginProps) {
    const [t] = useTranslation();
    const onGlobalError = useGlobalError();
    const [loginType, setLoginType] = useLoginType();
    const [signinMutation, { loading }] = useSigninMutation();
    const login = useFormField('');
    const password = useFormField('');
    const toast = useToast();
    const formRef = React.useRef<HTMLFormElement>(null);

    /** Await finishing of drag animation */
    const onErrorForm = () => {
        const className = cn('DragAnimation');

        formRef.current?.classList.remove(className);
        formRef.current?.classList.add(className);
        setTimeout(() => formRef.current?.classList.remove(className), DRAG_ANIMATION_DURATION);
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        password.changeError('');

        if (!login.value || !password.value) {
            onErrorForm();

            return toast.error(t('pageSign.alert.login.emptyFields', { context: loginType }));
        }
        signinMutation({
            variables: {
                    password: password.value,
                    userName: loginType === "login" ? login.value : undefined,
                    email: loginType === "email" ? login.value : undefined
                }
        }).then(({data}) => {
            if (data?.signIn.__typename === "Token") {
                const token = data?.signIn.token
                const isSuperuser = data.signIn.user?.isSuperuser
                setAdmin(isSuperuser || false)
                signIn(token || "");
            } else {
                onErrorForm();
                onGlobalError(data?.signIn.errorMessage || "", toast)
            }
        })
            .catch(async (err) => {
                onErrorForm();
                onGlobalError(getErrorData(err).message, toast);
            });

        return undefined;
    };

    return (
        <AuthWrapper
            title={t('pageSign.title', { context: 'login' })}
            isBack={isMobile}
            onBackClick={onOpenApp}
        >
            <form style={{ animationDuration: `${DRAG_ANIMATION_DURATION}ms` }} ref={formRef} onSubmit={onSubmit}>
                <AuthLoginTabs login={login} loginType={loginType} setLoginType={setLoginType} />
                <PasswordField
                    data-testid="field-password"
                    label={t('pageSign.password')}
                    placeholder={t('pageSign.password_placeholder', { context: 'login' })}
                    value={password.value}
                    error={password.error}
                    onChange={(e) => password.change(validator(e, password.value))}
                />
                <Button
                    data-testid="btn-submit"
                    loading={loading}
                    disabled={!login.value || !password.value}
                    size="large"
                    fullWidth
                    type="submit"
                >
                    {t('pageSign.btnSubmit', { context: 'login' })}
                </Button>
            </form>
        </AuthWrapper>
    );
}

export default AuthLogin;
