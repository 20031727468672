import React from 'react';
import { useParams } from 'react-router-dom';
import OrderSuccess from '../components/OrderSuccess/OrderSuccess';

interface Params {
    id: string;
}

export default function OrderPage() {
    const params: Params = useParams();

    return (
        <div>
            <OrderSuccess id={params.id} />
        </div>
    );
}
