import React from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'helpers/validator';
import { UseFormField } from 'helpers';
import { MailIcon, PhoneIcon } from 'ui/Icons/Icons';
import TextField from 'ui/TextField/TextField';
import PhoneField from 'ui/PhoneField/PhoneField';
import TabSwitcher from '../TabSwitcher/TabSwitcher';

type LoginType = 'email' | 'login';

type Props = {
    login: UseFormField<string>;
    loginType: LoginType;
    setLoginType: (type: LoginType) => void;
};

export const useLoginType = () => React.useState<LoginType>('email');

function AuthLoginTabs({ login, loginType, setLoginType }: Props) {
    const [t] = useTranslation();

    const onChangeLoginType = (type: LoginType) => {
        setLoginType(type);
        login.change('');
    };

    return (
        <>
            <TabSwitcher<LoginType>
                activeTab={loginType}
                setActiveTab={onChangeLoginType}
                tabs={[
                    {
                        label: (
                            <>
                                <MailIcon />
                                {t('pageSign.loginType.email')}
                            </>
                        ),
                        type: 'email'
                    },
                    {
                        label: (

                                <span>По имени пользователя</span>

                        ),
                        type: 'login'
                    }
                ]}
            />

            {loginType === 'email' && (
                <TextField
                    data-testid="field-email"
                    label={t('pageSign.email')}
                    placeholder={t('pageSign.email_placeholder')}
                    value={login.value}
                    error={login.error}
                    onChange={(e) => login.change(validator(e, login.value))}
                />
            )}
            {loginType === 'login' && (
                <TextField
                    data-testid="field-phone"
                    label="Имя пользователя"
                    placeholder="Введите имя пользователя"
                    value={login.value}
                    error={login.error}
                    onChange={(e) => login.change(validator(e, login.value))}
                />
            )}
        </>
    );
}

export default AuthLoginTabs;
