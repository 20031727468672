import React from 'react';
import cn from 'classnames';
import TestMark from 'components/TestMarker/TestMarker';
import { ArrowUpAndDownIcon } from 'ui/Icons/Icons';
import { ColumnType, RowType, TableProps } from './Table.d';
import styles from './Table.module.scss';

export default function Table({
    align = 'left',
    columns,
    currentSort,
    rows,
    onSortClick,
    onRowClick,
    onScroll
}: TableProps) {
    const handleRowClick = (rowItem: RowType) => (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (onRowClick) onRowClick(rowItem);
    };

    const handleSortClick = (columnCell: ColumnType) => (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (onSortClick && columnCell.sortDesc && columnCell.sortAsc) {
            onSortClick({ sortAsc: columnCell.sortAsc, sortDesc: columnCell.sortDesc });
        }
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (onScroll) {
            onScroll(e);
        }
    };

    return (
        <div className={cn(styles.ScrollContainer)} onScroll={handleScroll}>
            <table className={cn(styles.Table)}>
                <thead className={cn(styles.TableHead)}>
                    <tr className={cn(styles.TableRow)}>
                        {columns.map((columnCell) => (
                            <th
                                key={columnCell.field}
                                onClick={handleSortClick(columnCell)}
                                className={cn(styles.TableHeaderCell, styles[`Align-${align}`], columnCell.className)}
                            >
                                {columnCell.sortAsc && columnCell.sortDesc ? (
                                    <button
                                        type="button"
                                        className={cn(
                                            styles.HeaderButton,
                                            styles[`Align-${align}`],
                                            currentSort === columnCell.sortAsc && styles.Asc,
                                            currentSort === columnCell.sortDesc && styles.Desc
                                        )}
                                    >
                                        {columnCell.headerName}
                                        <ArrowUpAndDownIcon fillOpacity={0.2} />
                                    </button>
                                ) : (
                                    <span>{columnCell.headerName}</span>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={cn(styles.TableBody)}>
                    {rows.map((rowItem) => (
                        <tr
                            key={`${rowItem.options.rowId}`}
                            className={cn(
                                styles.TableRow,
                                rowItem.options.type === 'error' && styles.Error,
                                onRowClick && styles.RowHover
                            )}
                            onClick={handleRowClick(rowItem)}
                        >
                            {rowItem.content.map((cellItem, index) => (
                                <td
                                    key={cellItem.id}
                                    className={cn(
                                        styles.TableBodyCell,
                                        styles.Padding,
                                        styles[`Align-${align}`],
                                        cellItem.className
                                    )}
                                >
                                    {index === 0 && rowItem.options.withTestRow && <TestMark />}
                                    {cellItem.component}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
