export default {
    title: 'Статистика',

    filters: {
        timeFrame: {
            week: 'Неделя',
            month: 'Месяц',
            year: 'Год',
            all: 'Все время'
        }
    },

    subtitle_period: 'За выбранный период',

    receivedStatisticsEmpty: {
        title: 'Данные отсутсвуют',
        text: 'Заказы за выбранный период отсутствуют'
    },

    orderStatisticsCounter: 'Заказов',
    orderStatisticsEmpty: {
        title: 'Данные отсутсвуют',
        text: 'Заказы за выбранный период отсутствуют'
    },

    orderTypesStatistics: {
        chart: 'Всего Заказов',
        chartEmpty: 'Заказы за выбранный период отсутствуют'
    },

    balances: {
        title: 'Балансы',

        empty: 'Список балансов пуст',
        hideEmpty: 'Скрыть пустые балансы',

        btnDeposit: 'Пополнение',
        btnWithdraw: 'Вывод средств',
        waiting: 'В ожидании',

        errorScreen: {
            title: 'Ошибка',
            text: 'При загрузке баланса произошла непредвиденная\nошибка. Попробуйте обновить страницу',
            btn: 'Обновить'
        }
    },

    diff_more_0: 'на {{ count }} заказ больше чем в предыдущем периоде',
    diff_less_0: 'на {{ count }} заказ меньше чем в предыдущем периоде',
    diff_more_1: 'на {{ count }} заказа больше чем в предыдущем периоде',
    diff_less_1: 'на {{ count }} заказа меньше чем в предыдущем периоде',
    diff_more_2: 'на {{ count }} заказов больше чем в предыдущем периоде',
    diff_less_2: 'на {{ count }} заказов меньше чем в предыдущем периоде',

    empty: 'Нет данных',

    orderType: {
        period: 'За период',
        today: 'За сегодня',
        cancellations: 'Отмен',
        refunds: 'Возвратов',
        payments: 'Оплачено',
        expirations: 'Истекших'
    },

    errorScreen: {
        title: 'Ошибка',
        text: 'При загрузке статистики произошла\nнепредвиденная ошибка. Попробуйте\nобновить страницу',
        btn: 'Обновить'
    },

    receivedStatistics: 'Поступления средств',
    orderStatistics: 'Заказы',
    meanPrice: 'Средний чек на покупателя'
};
