import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import client from 'apollo';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
// import reportWebVitals from './reportWebVitals';
import store from 'store';
import i18n from './i18n';
import ErrorBoundary from './helpers/ErrorBoundary';
import Routes from './routes/routes';
import ModalSession from './components/SessionModal/SessionModal';
import 'styles/main.scss';

/** Initialize language configs */
i18n();

/** Enable sentry only for production */
if (process.env.NODE_ENV !== 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0
    });
}

function ScrollController() {
    const { pathname } = useLocation();

    React.useEffect(() => window.scrollTo(0, 0), [pathname]);

    return null;
}

/** Render app */
ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <BrowserRouter>
                    {/* @ts-ignore */}
                    <ErrorBoundary>
                        <ScrollController />
                        <Routes />
                        <ToastContainer
                            closeButton={false}
                            hideProgressBar
                            autoClose={5000}
                            position="top-center"
                            closeOnClick
                        />
                        <ModalSession />
                    </ErrorBoundary>
                </BrowserRouter>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
