import { ChangeEvent } from 'react';

export default (
    {
        target: {
            value,
            validity: { valid }
        }
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentValue: string
) => (value && !valid ? currentValue : value);
