import { InMemoryCache, ReactiveVar, makeVar } from '@apollo/client';
import generated from './generated';

const token = typeof window !== 'undefined' ? window.localStorage.getItem('tokenAuth') : null;

export const isAuthVar: ReactiveVar<boolean> = makeVar(Boolean(token));

// TODO: Replace any
export const errorVar: ReactiveVar<any> = makeVar(null);
export const showModalSessionVar: ReactiveVar<boolean> = makeVar(false);

export const cache: InMemoryCache = new InMemoryCache({
    ...generated,
    typePolicies: {
        Query: {
            fields: {
                error: {
                    read() {
                        return errorVar();
                    }
                },
                isAuth: {
                    read() {
                        return isAuthVar();
                    }
                },
                showModalSession: {
                    read() {
                        return showModalSessionVar();
                    }
                }
            }
        }
    }
});
