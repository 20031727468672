import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import { useOverflowController, useOverlayClickHandler } from 'helpers';
import styles from './Sidebar.module.scss';

const cx = classNames.bind(styles);

export function SidebarHeader({ className, children }: React.PropsWithChildren<{ className?: string }>) {
    return <header className={cx('Header', className)}>{children}</header>;
}

export function SidebarContent({ children, className }: React.PropsWithChildren<{ className?: string }>) {
    return <main className={cx('Content', className)}>{children}</main>;
}

type SidebarContextType = {
    activeLevel: [number, (v: number) => void];
};

const SidebarContext = React.createContext(undefined as any as SidebarContextType);

type Props = React.PropsWithChildren<{
    close(): void;
    isOpen: boolean;
    level: number;
    className?: string;
}>;

function Sidebar({ className, isOpen, close, children, level }: Props) {
    const primarySidebarContext = React.useContext(SidebarContext);

    const overflowController = useOverflowController();
    const [activeLevel, setActiveLevel] = primarySidebarContext
        ? // eslint-disable-next-line react/destructuring-assignment
          primarySidebarContext.activeLevel
        : // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useState(level);

    const isFirstRenderRef = React.useRef(true);
    const portal = React.useRef(document.createElement('div')).current;
    const [isVisible, setIsVisible] = React.useState(false);

    const hidden = !isOpen && !isVisible;
    const shown = isOpen && isVisible;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => () => portal && portal.remove(), []);

    const hide = () => {
        setActiveLevel(level - 1);

        if (isOpen) setIsVisible(false);

        setTimeout(() => {
            if (!isOpen) setIsVisible(false);
            close();
            overflowController.unblockScroll();
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            portal && portal.remove();
        }, 200);
    };

    const overlayClickHandler = useOverlayClickHandler(hide);

    React.useEffect(() => {
        if (isOpen) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            portal && document.body.appendChild(portal);
            setTimeout(() => {
                setIsVisible(true);
                overflowController.blockScroll(portal);
            });
        } else if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;
        } else {
            hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    React.useEffect(() => {
        if (isOpen) {
            setActiveLevel(level);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    if (hidden) return null;

    const Component = (
        <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...overlayClickHandler.overlayProps}
            className={cx('Wrapper', shown && 'isOpen')}
        >
            <aside
                style={{
                    transform: activeLevel > level ? `translateX(${(activeLevel - level) * 100}px)` : undefined
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...overlayClickHandler.componentProps}
                className={cx('Component', className)}
            >
                {children}
            </aside>
        </div>
    );

    return createPortal(
        primarySidebarContext ? (
            Component
        ) : (
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            <SidebarContext.Provider value={{ activeLevel: [activeLevel, setActiveLevel] }}>
                {Component}
            </SidebarContext.Provider>
        ),
        portal
    );
}

export default Sidebar;
