import { combineReducers } from 'redux';
import Base from './Base/reducer';

const rootReducer = combineReducers({
    Base
});

export type ApplicationState = {
    Base: ReturnType<typeof Base>;
};

export default rootReducer;
