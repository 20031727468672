import React from 'react';
import classNames from 'classnames/bind';
import FormControl, { useFormControl } from 'ui/FormControl/FormControl';
import { FormControlFocusControlRef, SharableProps as FormControlProps } from 'ui/FormControl/FormControl.d';
import styles from './TextField.module.scss';

const cx = classNames.bind(styles);

type InputProps = Omit<JSX.IntrinsicElements['input'], 'children'>;
export type Props = FormControlProps & InputProps;

export function Input({
    className = '',
    onFocus,
    onBlur,
    focusControlRef,
    ...props
}: InputProps & { focusControlRef?: FormControlFocusControlRef }) {
    return (
        <input
            className={cx('Input', className)}
            onBlur={(e) => {
                focusControlRef?.current?.unsetFocus();
                onBlur?.(e);
            }}
            onFocus={(e) => {
                focusControlRef?.current?.setFocus();
                onFocus?.(e);
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
}

function TextField(props: Props) {
    const [focusControlRef, [formControlProps, inputProps]] = useFormControl<InputProps>(props);

    return (
        <FormControl
            focusControlRef={focusControlRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...formControlProps}
        >
            <Input
                focusControlRef={focusControlRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
            />
        </FormControl>
    );
}

export default TextField;
