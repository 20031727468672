export default {
    intro: {
        title: 'Профиль',

        email: 'Почта',
        phone: 'Номер телефона',

        btnEdit: 'Редактировать',

        btnAddEmail: 'Добавить почту',
        btnAddPhone: 'Добавить номер телефона',
        btnChangePassword: 'Сменить пароль',

        btnSubmit: 'Готово'
    },

    changePassword: {
        title: 'Сменить пароль',

        oldPassword: 'Старый пароль',
        newPassword: 'Новый пароль',
        repeatPassword: 'Повторите новый пароль',
        btnSubmit: 'Продолжить',
        successMsg: 'Пароль успешно изменен'
    },

    emailForm: {
        title_add: 'Добавить почту',
        title_edit: 'Редактировать почту',
        description_add: 'Для привязки почты, вам будет будет выслан код подтверждения',
        description_edit: 'Введите новую почту',
        description_code: 'Для подтверждения, вам на почту <0>{{address}}</0> был выслан код подтверждения',
        email: 'Почта',
        btnSubmit: 'Продолжить'
    },

    phoneForm: {
        title_add: 'Добавить номер',
        title_edit: 'Редактировать номер ',
        description_add: 'Для привязки номера телефона, вам будет будет выслан код подтверждения',
        description_edit: 'Введите новый номер телефона',
        description_code: 'Для подтверждения, вам на номер телефона <0>{{address}}</0> был выслан код подтверждения',
        phone: 'Номер телефона',
        btnSubmit: 'Продолжить'
    },

    passwordVerificationForm: {
        title: 'Подтвеждение',
        description: 'Для того чтобы продолжить, необходимо ввести пароль',
        password: 'Пароль',
        btnSubmit: 'Продолжить'
    },

    error: {
        fieldRequired: 'Обязательное поле',
        repeatPasswordNotMatch: 'Пароли не совпадают',
        passwordIncorrect: 'Пароль неверный',
        oldPasswordIncorrect: 'Старый пароль неверный',

        phoneIncorrect: 'Номер телефона неверный',
        phoneAlreadyExists: 'Номер телефона уже используется',

        emailExists: 'Данная почта уже используется',
        emailInvalid: 'Данная почта неверная'
    }
};
