import React from 'react';
import Skeleton from 'ui/Skeleton/Skeleton';
import Table from 'ui/Table/Table';
import { RowType } from 'ui/Table/Table.d';
import cn from 'classnames';
import styles from './TableSkeleton.module.scss';

export default function TableSkeleton() {
    const columns = [
        {
            field: 'type',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={20} />
                </div>
            )
        },
        {
            field: 'status',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={20} />
                </div>
            )
        },
        {
            field: 'number',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={20} />
                </div>
            )
        },
        {
            field: 'createdDate',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={100} />
                </div>
            )
        },
        {
            field: 'paymentDate',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={100} />
                </div>
            )
        },
        {
            field: 'amount',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={40} />
                </div>
            )
        },
        {
            field: 'commission',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={30} />
                </div>
            )
        },
        {
            field: 'token',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={20} />
                </div>
            )
        },
        {
            field: 'network',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={40} />
                </div>
            )
        },
        {
            field: 'paymentMethod',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={100} />
                </div>
            )
        },
        {
            field: 'user',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={60} />
                </div>
            )
        },
        {
            field: 'store',
            headerName: (
                <div className={cn(styles.HeaderCell)}>
                    <Skeleton animation="wave" variant="text" width={60} />
                </div>
            )
        }
    ];

    const rows = () => {
        let newArray: RowType[] = [];
        for (let i = 0; i < 12; i += 1) {
            newArray = [
                ...newArray,
                {
                    options: {
                        type: 'options',
                        rowId: `${i}`
                    },
                    content: [
                        {
                            component: <Skeleton animation="wave" height={25} variant="default" width={80} />,
                            id: '1'
                        },
                        {
                            component: (
                                <span className={cn(styles.BodyCell)}>
                                    <Skeleton animation="wave" height={10} variant="circular" width={10} />
                                    <Skeleton animation="wave" height={12} variant="text" width={80} />
                                </span>
                            ),
                            id: '2'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={30} />,
                            id: '3'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={80} />,
                            id: '4'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={80} />,
                            id: '5'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={30} />,
                            id: '6'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={30} />,
                            id: '7'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={60} />,
                            id: '8'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={50} />,
                            id: '9'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={100} />,
                            id: '10'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={100} />,
                            id: '11'
                        },
                        {
                            component: <Skeleton animation="wave" height={12} variant="text" width={90} />,
                            id: '12'
                        }
                    ]
                }
            ];
        }
        return newArray;
    };
    return <Table rows={rows()} columns={columns} />;
}
