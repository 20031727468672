export default {
    title_paid: 'OrderPage paid',
    title_cancelled: 'OrderPage cancelled',
    title_expired: 'OrderPage not paid',

    description_cancelled: 'Your order has been canceled by the store',
    description_expired:
        'The payment link has expired. To complete payment please come back to the shop and try to pay again',
    details: {
        title: 'OrderPage information',
        orderNumber: 'OrderPage number',
        store: 'Store',
        token: 'Payment In Progress method',
        status: 'Status',
        statusType: {
            CREATED: 'Waiting for payment'
        },
        infoAlert: 'Usually a transaction comes within 3 minutes',
        toPay: 'To pay',
        fee: 'Commission',
        total: 'Total'
    },

    paymentInfo: {
        title: 'Scan QR',
        subTitle: 'via app',

        btnDownloadQr: 'Download QR',

        orDivider: 'or pay manually',

        account: 'Wallet',
        memo: 'Memo',
        alert: 'Please enter your address and MEMO correctly. They are needed to deposit funds.\nIn the event of an error, the funds will be lost.'
    },

    paymentInfoAttention: {
        text: 'Pay through the PayCash application or any other convenient way you choose',
        btnUseApp: 'Pay through the app',
        btnPay: 'Pay',
        btnShowDetails: 'Other payment methods'
    },

    paymentMobileApp: {
        title: 'Choose a payment method',
        description: 'With app',
        divider: 'or',
        btnQr: 'By QR code and details'
    },

    btnHowTo: 'How to pay for an order?',
    btnReturnToStore: 'Back to shop',
    btnCheckTransfer: 'Check at bloks.io'
};
