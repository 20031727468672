import React, { PureComponent } from 'react';
import { captureException } from '@sentry/react';
import PageError from 'components/Error/Error';

class ErrorBoundary extends PureComponent {
    // eslint-disable-next-line react/state-in-constructor
    state = {
        hasError: false
    };

    componentDidCatch(e: any) {
        captureException(e);
        this.setState({
            hasError: true
        });
    }

    resetError = () => {
        this.setState({
            hasError: false
        });
    };

    render() {
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.hasError) return <PageError code={500} onClickBtn={this.resetError} />;
        // @ts-ignore
        // eslint-disable-next-line react/destructuring-assignment,react/prop-types
        return this.props.children;
    }
}

export default ErrorBoundary;
