export default {
    title_paid: 'Заказ оплачен',
    title_cancelled: 'Заказ отменен',
    title_expired: 'Заказ не оплачен',

    description_cancelled: 'Ваш заказ был отменен магазином',
    description_expired:
        'Время существования ссылки на оплату истекло, для того чтобы оплатить заказ, вернитесь в магазин и заново нажмите на кнопку оплатить',
    details: {
        title: 'Информация о заказе',
        orderNumber: 'Номер заказа',
        store: 'Магазин',
        token: 'Способ оплаты',
        status: 'Статус',
        statusType: {
            CREATED: 'Ожидание оплаты'
        },
        infoAlert: 'Обычно транзакция приходит в течении 3 минут',
        toPay: 'К оплате',
        fee: 'Комиссия',
        total: 'Итого'
    },

    paymentInfo: {
        title: 'Просканируйте QR',
        subTitle: 'через приложение',

        btnDownloadQr: 'Скачать QR',

        orDivider: 'или оплатите вручную',

        account: 'Кошелек',
        memo: 'Memo',
        alert: 'Пожалуйста правильно введите адрес и MEMO. Они нужны для внесения средств.\nВ случае ошибки средства будут утрачены.'
    },

    paymentInfoAttention: {
        text: 'Оплатите через приложение Paycash, либо другим удобным для вас способом',
        btnUseApp: 'Оплатить через приложение',
        btnPay: 'Оплатить',
        btnShowDetails: 'Другие способы оплаты'
    },

    paymentMobileApp: {
        title: 'Выберите cпособ оплаты',
        description: 'Через приложение',
        divider: 'или',
        btnQr: 'По QR коду и реквизитам'
    },

    btnHowTo: 'Как оплатить заказ?',
    btnReturnToStore: 'Вернуться в магазин',
    btnCheckTransfer: 'Посмотреть на bloks.io'
};
