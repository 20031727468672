/**
 * -----------------------------
 * Local/session storage keys
 * -----------------------------
 */
export const LOCAL_STORAGE_LNG_KEY = 'defaultLng';
export const LOCAL_STORAGE_KEY_TOKEN_AUTH = 'tokenAuth';

/**
 * -----------------------------
 * Screen size breakpoints
 * -----------------------------
 */
export const BREAKPOINT_PHONE = 576;
export const BREAKPOINT_LANDSCAPE = 768;
export const BREAKPOINT_TABLET = 1024;
export const BREAKPOINT_IPAD = 1280;
export const BREAKPOINT_LAPTOP = 1440;

/**
 * -----------------------------
 * App routes
 * -----------------------------
 */
type ID = null | number | string;
export const URLS_MAP = {
    home: '/',
    startTour: '/start',

    admin: {
        index: '/admin',
        transactions: '/admin/transactions',
        list: '/admin/list'
    },

    dashboard: '/dashboard',
    orders: '/orders',
    transfers: '/transfers',
    order: (id: ID = ':id') => `/order/${id}`,
    stores: '/stores',
    reports: '/reports',
    logs: '/logs',
    settings: '/settings',
    forDevelop: {
        index: '/for-develop',
        documentation: {
            index: '/for-develop/documentation',
            begin: '/for-develop/documentation/begin',
            auth: '/for-develop/documentation/auth',
            createOrder: '/for-develop/documentation/createOrder',
            orderDetails: '/for-develop/documentation/orderDetails',
            createTransfer: '/for-develop/documentation/createTransfer',
            transferDetails: '/for-develop/documentation/transferDetails',
            callback: '/for-develop/documentation/callback',
            lifecycles: '/for-develop/documentation/lifecycles',
            sdk: '/for-develop/documentation/sdk',
            testing: '/for-develop/documentation/testing'
        },
        apikey: '/for-develop/api-key',
        logs: '/for-develop/logs',
        webhooks: '/for-develop/webhooks',
        integrations: '/for-develop/integrations'
    },
    payment: (id: ID = ':id') => `/payment/${id}`,

    notFound: '*'
};

export const DRAG_ANIMATION_DURATION = 500;

export const DOWNLOAD_APP = {
    ios: 'https://apps.apple.com/app/paycash/id1557721025',
    android: 'https://play.google.com/store/apps/details?id=online.paycash.app'
};
