import React from 'react';
import {Route, Switch } from 'react-router-dom';
import { URLS_MAP } from 'constants/';
import AuthRoute from 'helpers/AuthRoute/AuthRoute';
import Error from 'components/Error/Error';
import LoginPage from './LoginPage';
import AdminDashboardPage from "./AdminDashboardPage";
import AdminTransactionsPage from "./AdminTransactionsPage";
import OrderRedirectPage from "./OrderRedirectPage";
import OrderFailurePage from './OrderFailurePage';
import OrderSuccessPage from './OrderSuccessPage';
import AuthController from "../helpers/AuthController";
// import AuthForgotPassword from "../components/AuthForgotPassword/AuthForgotPassword";
import {useIsTouchableDevice} from "../helpers";
import Auth from "../components/Auth/Auth";

export default function Routes() {

    const isTouchableDevice = useIsTouchableDevice();

    return (
        <>
            {/*<Route*/}
            {/*    path="*"*/}
            {/*    render={({ location: { pathname } }) => !pathname.startsWith('/payment') && <AuthController />}*/}
            {/*/>*/}
            <Switch>
                <AuthRoute requiredAuth={false} exact path={URLS_MAP.home}>
                    <LoginPage />
                </AuthRoute>
                <Route path={URLS_MAP.admin.index} exact>
                    <AuthController />
                    <AdminTransactionsPage />
                </Route>
                <Route path={URLS_MAP.dashboard} exact>
                    <AuthController />
                    <AdminDashboardPage />
                </Route>
                <Route path="/order/:id/success">
                    <OrderSuccessPage />
                </Route>
                <Route path="/order/:id/failure">
                    <OrderFailurePage />
                </Route>
                <Route path="/order/:id">
                    <OrderRedirectPage />
                </Route>
                <Route path="/reset-password/:uid/:ts/:token">
                    <Auth formType="forgotPassword" isMobile={isTouchableDevice}/>
                </Route>
                <Route path={URLS_MAP.notFound} component={Error} />
            </Switch>
        </>
    );
}
