import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import useModal from 'hooks/useModal/useModal';
import Button from 'ui/Button/Button';
import {ExitIcon, HexagonIcon, ProfileIcon} from 'ui/Icons/Icons';
import ModalLogout from '../AuthLogoutModal/AuthLogoutModal';
import {useMeQuery} from "../../apollo/generated";
// import ModalProfile from '../ProfileModal/ProfileModal';
import styles from './HeaderUser.module.scss';
import ApiKeyModal from "../ApiKeyModal/ApiKeyModal";

const cx = classNames.bind(styles);

interface HeaderUserProps {
    isUser: boolean;
    className?: string;
}

export default function HeaderUser({ className = '' , isUser}: HeaderUserProps) {
    const [t] = useTranslation();
    const modalApiKey = useModal(false);
    const modalLogout = useModal(false);
    const {data, loading, error} = useMeQuery()
    let email;

    if (data?.userInfo.__typename === "User") {
        email = data.userInfo.name
    }

    return (
        <div className={cx('UserActions', className)}>
            <div>
                {isUser && <Button
                    variant="outlined"
                    color="secondary"
                    onClick={modalApiKey.open}
                    iconStart={<span><HexagonIcon/></span>}
                    isDark
                />}
                <Button
                    variant="outlined"
                    color="secondary"
                    isDark
                    classes={{ root: cx('BtnProfile') }}
                    iconStart={
                        <span className={cx('BtnProfileIcon')}>
                            <ProfileIcon />
                        </span>
                    }
                >
                    {email}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    isDark
                    onClick={modalLogout.open}
                    classes={{ root: cx('BtnLogout') }}
                    iconStart={<ExitIcon />}
                />
            </div>
            <ModalLogout
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...modalLogout}
            />
            <ApiKeyModal
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...modalApiKey}
            />
            {/*<ModalProfile*/}
            {/*    // eslint-disable-next-line react/jsx-props-no-spreading*/}
            {/*    {...modalProfile}*/}
            {/*/>*/}
        </div>
    );
}
