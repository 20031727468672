import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import IconLogoLight from "assets/images/logo-paybet-light.svg"
import IconLogoDark from "assets/images/logo-paybet-dark.svg"
import { LogoProps } from './Logo.d';
import styles from './Logo.module.scss';

export default function Logo({ href = '/', isDark, classes }: LogoProps) {
    const { root, img } = classes ?? {};

    return (
        <Link className={cn(styles.Root, root)} to={href}>
            {isDark ? (
                <img className={cn(styles.Img, img)} src={IconLogoDark} alt="Logo Dark" />
            ) : (
                <img className={cn(styles.Img, img)} src={IconLogoLight} alt="Logo Light" />
            )}
        </Link>
    );
}
