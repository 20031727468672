export default {
    title: 'Создать заказ',
    title_success: 'Заказ создан',

    description_success: 'Заказ #{{orderNumber}} успешно создан',

    amountStep: {
        title: 'Сумма заказа',
        btnSubmit: 'Продолжить'
    },
    additionalStep: {
        title: 'Дополнительная информация',
        btnSubmit: 'Создать заказ'
    },

    token_placeholder: 'Выберите токен',
    shopReceive: 'Магазин получит',
    shopReceive_placeholder: 'Сумма которую получит магазин',
    totalPrice: 'К оплате',
    totalPrice_placeholder: 'Сумма к оплате',

    duration_placeholder: 'Введите значение',
    description_placeholder: 'Введите описание заказа',
    redirectSuccess_placeholder: 'Введите ссылку',
    redirectFailure_placeholder: 'Введите ссылку',
    callback_placeholder: 'Введите ссылку',
    merchantOrderId_placeholder: 'Введите значение',

    error: {
        fieldsRequired: 'Для того чтобы продолжить, необходимо выбрать токен и ввести сумму заказа',
        tokenRequired: 'Выберите токен',
        priceRequired: 'Введите сумму заказа',
        amountTooLow: 'Сумма заказа слишком мала'
    },

    copied: 'Ссылка на заказ скопирована',

    paymentLink: 'Ссылка на оплату',
    btnDetails: 'Подробности заказа',
    btnDone: 'Готово'
};
