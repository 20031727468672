export default (value: string | null, dateOnly = false) => {
    if (!value) return '-';

    const format = (v: number) => v.toString().padStart(2, '0');

    const date = new Date(`${value}Z`);

    if (Number.isNaN(date.getTime())) return 'Invalid Date';
    return `${format(date.getDate())}.${format(date.getMonth() + 1)}.${date.getFullYear()}${
        dateOnly ? '' : `, ${format(date.getHours())}:${format(date.getMinutes())}:${format(date.getSeconds())}`
    }`;
};
