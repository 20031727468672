export default {
    title: 'Пополнить баланс',
    description: 'Просканируйте QR через приложение Paycash',
    orDivider: 'или оплатите вручную',
    wallet: 'Кошелек',
    memo: 'Memo',
    alert: 'Проверьте правильность заполнения MEMO для зачисления средств на ваш баланс.',
    hint: 'Обычно транзакция проходит в течение 3 минут,\nно предельный срок исполнения 24 часа',
    btnDone: 'Готово',
    btnDownload: 'Скачать QR код',
    btnUseApp: 'Пополнить через приложение'
};
