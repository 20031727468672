import { useApolloClient } from '@apollo/client';
import { reconnectWS } from 'apollo';
// import { useMeWsSubscription, useRefreshTokenMutation } from 'apollo/generated';
import { signOut } from 'helpers/sign';
import jwt_decode from 'jwt-decode';
import { useEffect, useRef } from 'react';
import { useStore, getToken } from './index';

const getExpirationTimestamp = () => {
    const token = getToken();

    try {
        const jwtToken = jwt_decode(token ?? '') as { [key: string]: any };
        /** timestamp in sec */
        return jwtToken?.expiration ?? 0;
    } catch {
        return 0;
    }
};

const getExpirationDelta = () => getExpirationTimestamp() * 1000 - +new Date();
const MONITOR_TIMEOUT = 1000 * 60 * 5;
const SAFE_OFFSET = 5000;

function AuthController() {
    const hasActionsFromLastCheckRef = useRef(false);
    const intervalIdRef = useRef<any>(undefined);
    const timeoutIdRef = useRef<any>(undefined);
    const { isAuth } = useStore((s) => s.Base);
    // const [refreshTokenMutation] = useRefreshTokenMutation();
    const apollo = useApolloClient();
    const reset = async () => {
        try {
            // await (apollo as any).queryManager.fetchQueryRejectFns
            // await apollo.resetStore()
            await apollo.clearStore();
            reconnectWS();
        } catch (e) {
            /* empty */
        }
    };

    /**
     * @todo replace it to more appropriate place
     * Subscribe for user's updates
     */
    // useMeWsSubscription({ skip: !isAuth });

    const addListeners = () => {
        // eslint-disable-next-line no-return-assign
        const onAction = () => (hasActionsFromLastCheckRef.current = true);

        window.addEventListener('click', onAction);
    };

    const stopExpirationTimer = () => clearTimeout(timeoutIdRef.current);
    const stopMonitorActivity = () => clearInterval(intervalIdRef.current);

    const resetActivityFlag = () => {
        hasActionsFromLastCheckRef.current = false;
    };

    /**
     * Start monitor activity of user
     * If there were no activity - restart timer maximum with
     */
    const startMonitorActivity = () => {
        stopMonitorActivity();
        const check = () => {
            if (hasActionsFromLastCheckRef.current) {
                resetActivityFlag();
                stopExpirationTimer();
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                refreshToken();
            } else {
                intervalIdRef.current = setTimeout(
                    check,
                    Math.min(MONITOR_TIMEOUT, getExpirationDelta() - SAFE_OFFSET)
                );
            }
        };

        intervalIdRef.current = setTimeout(check, MONITOR_TIMEOUT);
    };
    const startExpirationTimer = () => {
        stopExpirationTimer();
        timeoutIdRef.current = setTimeout(() => {
            signOut(false, true);
        }, getExpirationDelta());
    };
    const refreshToken = () => {
        // if (isAuth) {
        //     refreshTokenMutation()
        //         .then(({ data }) => {
        //             signIn(data?.refreshToken?.token ?? '');
        //             reconnectWS();
        //             startExpirationTimer();
        //             startMonitorActivity();
        //         })
        //         .catch(() => {
        //             startExpirationTimer();
        //             startMonitorActivity();
        //         });
        // }
    };

    const initializeMonitor = () => {
        addListeners();
        // refreshToken();
        startExpirationTimer();
        startMonitorActivity();
    };

    const stopMonitor = () => {
        stopExpirationTimer();
        stopMonitorActivity();
        resetActivityFlag();
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isAuth) {
            /**
             * Difference between expiration timestamp and current timestamp
             * If `delta > 0` - token is active now
             * else if `delta < 0` - token is expired
             */
            if (getExpirationDelta() < 0) {
                signOut(false, true);
            } else {
                initializeMonitor();
            }

            return () => {
                stopMonitor();
                reset();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    return null;
}

export default AuthController;
