import React from 'react';
import AdminTransactions from 'components/AdminTransactions/AdminTransactions';
import AdminHeader from "../components/AdminHeader/AdminHeader";

export default function AdminTransactionsPage() {
    return  (
        <>
            <AdminHeader isUser={false}/>
            <AdminTransactions />
        </>
    );
}
