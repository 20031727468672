export default {
    title: 'Заказ #{{orderNumber}}',
    actions: {
        reload: 'Обновить',
        download: 'Скачать',
        print: 'Печать',
        refund: 'Возврат',
        cancel: 'Отмена'
    },

    details: {
        title: 'Детали'
    },

    payment: {
        title: 'Оплата',

        paymentDetails: 'Детали оплаты'
    },

    transfer: {
        title: 'История операций',
        type: 'Действие',
        amount: 'Cумма',
        fee: 'Комиссия',
        send: 'Было отправлено',
        account: 'Аккаунт',
        date: 'Дата и время',

        empty: 'История операци появится после оплаты заказа',
        type_ORDER_PAYMENT: 'Оплата заказа',
        type_PAYBACK: 'Возврат средств'
    },

    logs: {
        title: 'Логи API',
        btnResend: 'Отправить заново',
        date: 'Дата и время создания',
        code: 'HTTP код',
        duration: 'Время ответа',
        duration_units: 'Время ответа',
        status: 'Статус',
        empty: 'Информация по логам API на данный момент не доступна, попробуйте обновить страницу'
    },

    metaInfo: {
        title: 'Информация о системе',
        empty: 'Информация о системе появится после оплаты платежа'
    },

    modalTransferDetails: {
        title: 'Подробности',
        btnTransferDetails: 'Подробности на bloks.io'
    }
};
