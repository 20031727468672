export default {
    title: 'Транзакции',
    failedTransactions: 'Неудачные транзакции',
    search: 'Поиск',
    filter: 'Фильтры',
    downloadReport: 'Скачать отчёт',
    tableHeader: {
        type: 'Тип',
        status: 'Статус',
        number: 'Номер',
        createdDate: 'Время создания',
        paymentDate: 'Время оплаты',
        amount: 'Сумма',
        commission: 'Комиссия',
        token: 'Токен',
        network: 'Сеть',
        paymentMethod: 'Способ оплаты',
        user: 'Пользователь',
        store: 'Магазин'
    }
};
