import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'constants/';
import store from 'store/';
import { setAuth, setShowModalSession, setIsAdmin } from 'store/Base/actions';

export const signOut = (withUnsetAuth = false, withShowSessionModal = false) => {

    if (withUnsetAuth) store.dispatch(setAuth(false));
    if (withShowSessionModal) store.dispatch(setShowModalSession(true));

    window.localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN_AUTH);
};

export const setAdmin = (bool: boolean) =>  {
    store.dispatch(setIsAdmin(bool))
}

export const signIn = (token: string) => {
    /** We save `isAuth` flag in order to after reload display for user correct UI */
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_AUTH, token);
    store.dispatch(setAuth(true));
};
