import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { URLS_MAP } from 'constants/';
import { useStore } from '../index';

function AuthRoute({ requiredAuth, ...routeProps }: RouteProps & { requiredAuth: boolean }) {
    const { isAuth, isAdmin } = useStore((s) => s.Base);
    if (!isAuth && requiredAuth) {
        return <Redirect to={URLS_MAP.home} />;
    }
    if (isAuth && isAdmin) {
        return <Redirect to={URLS_MAP.admin.index} />
    }
    if (isAuth && !requiredAuth) {
        return <Redirect to={URLS_MAP.dashboard} />;
    }

    return (
        <Route
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...routeProps}
        />
    );
}

export default React.memo(AuthRoute);
