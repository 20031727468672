import React from 'react';
import cn from "classnames"
import { ErrorEnum, OrderStatusEnum, useOrderQuery } from "../../apollo/generated";
import Preloader from "../../ui/Preloader/Preloader";
import styles from "./OrderRedirect.module.scss"
import { getErrorData } from '../../helpers';

interface OrderRedirectProps {
    id: string
}

const getErrorMessage = (type: ErrorEnum) => {
    switch (type) {
        case ErrorEnum.BadPassword:
            return 'Не верный пароль';
        case ErrorEnum.BadRequest:
            return 'Неверный запрос';
        case ErrorEnum.Duplicate:
            return 'Дубликат';
        case ErrorEnum.Forbidden:
            return 'Запрещено';
        case ErrorEnum.InsecureLink:
            return 'Небезопасная ссылка';
        case ErrorEnum.InvalidCredentials:
            return 'Неверные данные';
        case ErrorEnum.NotFound:
            return 'Не найдено';
        case ErrorEnum.Unavailable:
            return 'Недоступно';
        default:
            return 'Не предвиденная ошибка';
    }
};

export default function OrderRedirect({id}: OrderRedirectProps) {
    const { data, error } = useOrderQuery({
        variables: {
            uid: id
        }
    });

    if (data?.order.__typename === 'Order') {
        const { status, redirectUrl, successRedirectUrl, failureRedirectUrl } = data.order ?? {};

        if (status === OrderStatusEnum.Pending) {
            window.location.href = redirectUrl ?? '';
        }

        if (status === OrderStatusEnum.Paid) {
            window.location.href = successRedirectUrl ?? '';
        }

        if (status === OrderStatusEnum.Declined || status === OrderStatusEnum.Expired) {
            window.location.href = failureRedirectUrl ?? '';
        }
    }

    if (data?.order.__typename === 'Error') {
        const { errorType, errorMessage } = data?.order ?? {};

        return (
            <h1>
                <>
                    {errorType && <>{getErrorMessage(errorType)}</>}
                    {errorMessage && getErrorData(errorMessage)}
                </>
            </h1>
        );
    }

    return <div className={styles.Root}>{error ? <h1>Error</h1> : <Preloader size={40} />}</div>;
}
