import update from 'immutability-helper';
import { LOCAL_STORAGE_KEY_TOKEN_AUTH } from 'constants/';
// eslint-disable-next-line import/no-cycle
import { Actions } from './actions';
import ActionTypes from './actionTypes';

export type ErrorStatus = 500 | 404 | null;

export const initialState = {
    error: null as ErrorStatus,
    showModalSession: false,
    isAuth: !!window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_AUTH),
    isAdmin: false
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case ActionTypes.SET_AUTH:
            return update(state, { isAuth: () => action.payload });
        case ActionTypes.SET_IS_ADMIN:
            return update(state, { isAdmin: () => action.payload });
        case ActionTypes.SET_ERROR_STATUS:
            return update(state, { error: () => action.payload });
        case ActionTypes.SET_SHOW_MODAL_SESSION:
            return update(state, { showModalSession: () => action.payload });
        default:
            return state;
    }
};

export default reducer;
