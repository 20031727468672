import React from 'react';
import cn from "classnames"
import Modal, {ModalTitle, useModalContext} from 'ui/Modal/Modal';
import {ModalProps} from 'ui/Modal/Modal.d';
import Button from "../../ui/Button/Button";
import Param from "../Param/Param"
import Preloader from "../../ui/Preloader/Preloader";
import {useMeQuery, useRegenerateApiTokenMutation} from "../../apollo/generated";
import {WarningTriangleIcon, KeyIcon} from "../../ui/Icons/Icons";
import styles from "./ApiKeyModal.module.scss"

interface Props {
    apiToken?: string | null
}

function Main() {
    const {data: userInfo, refetch} = useMeQuery()
    const [step, setStep] = React.useState("start")
    const [token, setToken] = React.useState<string | null | undefined>("")
    const [regenerateApiTokenMutation] = useRegenerateApiTokenMutation()
    const {close} = useModalContext()

    let apiToken

    React.useEffect(() => {
        refetch()
        if (userInfo?.userInfo.__typename === "User") {
            apiToken = userInfo.userInfo.apiToken
            setToken(apiToken)
        }
    }, [userInfo])

    const handleGenerate = () => {
        setStep("loading")
        regenerateApiTokenMutation().then(({data: tokenData}) => {
            if (tokenData?.regenerateApiToken.__typename === "User") {
                setToken(tokenData.regenerateApiToken.apiToken)
                setStep("finish")
            }
        })
    }

    return (
        <>
            {step === "start" && (
                <>
                    <ModalTitle withCloseBtn>Настройки API ключа</ModalTitle>
                    <p className={cn(styles.Description)}>Api ключ</p>
                    <Param value={token} withCopy className={cn(styles.Param)}/>
                    <div className={cn(styles.Warning)}>
                        <WarningTriangleIcon className={cn(styles.WarningIcon)}/>
                        Не передавайте ключ посторонним лицам
                    </div>
                    <Button
                        color="secondary"
                        variant="outlined"
                        iconStart={<KeyIcon />}
                        onClick={() => setStep("alert")
                    }>
                    Сгенерировать новый API ключ
                    </Button>
                </>)

            }
            {step === "alert" && (
                <>
                    <ModalTitle withCloseBtn btnBack={() => setStep("start")}>Новый API ключ</ModalTitle>
                    <p className={cn(styles.Description)}>
                        Вы уверены что хотите сгенерировать
                        новый ключ? Старый ключ будет удален
                        и недействителен
                    </p>
                    <div className={cn(styles.Warning)}>
                        <WarningTriangleIcon className={cn(styles.WarningIcon)}/>
                        При генерации нового ключа, старый ключ будет удален и недействителен
                    </div>
                    <Button classes={{root: cn(styles.ButtonMargin)}} color="gray" onClick={() => setStep("start")}>Оставить старый ключ</Button>
                    <Button color="primary" onClick={() => handleGenerate()}>Сгенерировать новый ключ</Button>
                </>
            )}
            {step === "loading" && (
                <>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Preloader size={60}/>
                    </div>
                    <ModalTitle className={cn(styles.HeaderMargin)}>Генерируем новый API ключ</ModalTitle>
                </>
            )}
            {step === "finish" && (
                <>
                    <ModalTitle withCloseBtn btnBack={() => setStep("start")}>Новый API ключ</ModalTitle>
                    <p className={cn(styles.Description)}>
                        API ключ
                    </p>
                    <Param value={token} withCopy className={cn(styles.Param)}/>
                    <div className={cn(styles.Warning)}>
                        <WarningTriangleIcon className={cn(styles.WarningIcon)}/>
                        Не передавайте ключ посторонним лицам
                    </div>
                    <Button color="primary" onClick={() => close()}>Готово</Button>
                </>
            )}
        </>
    )

}

function ApiKeyModal({apiToken, ...props}: ModalProps & Props){
    return (
        <Modal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <Main/>
        </Modal>
    );
};

export default ApiKeyModal;
