import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// import { AuthStep, useSignupMutation } from 'apollo/generated';
import { formatPhone, getErrorData, useFormField, useGlobalError, useToast } from 'helpers';
import validator from 'helpers/validator';
import { signIn } from 'helpers/sign';
import { DRAG_ANIMATION_DURATION } from 'constants/';
import Button from 'ui/Button/Button';
import Link from 'ui/Link/Link';
import PasswordField, { validatePassword } from 'ui/PasswordField/PasswordField';
import AuthCode from '../AuthCode/AuthCode';
// import LanguageSwitcher from '../AuthLanguageSwitcher/AuthLanguageSwitcher';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import LoginField, { useLoginType } from '../AuthLoginTabs/AuthLoginTabs';
import { AuthSignupProps, Step } from './AuthSignup.d';
import styles from './AuthSignup.module.scss';

const cx = classNames.bind(styles);

function AuthSignup({ isMobile, onOpenLogin, onOpenApp, onOpenQr }: AuthSignupProps) {
    const [t] = useTranslation();
    const [loginType, setLoginType] = useLoginType();
    // const [signupMutation, { loading: loadingSignup }] = useSignupMutation();
    const login = useFormField('');
    const password = useFormField('');
    const repeatPassword = useFormField('');
    const onGlobalError = useGlobalError();
    const toast = useToast();

    const [verificationToken, setVerificationToken] = React.useState<null | string>();
    const [step, setStep] = React.useState<Step>('signup');

    const formRef = React.useRef<HTMLFormElement>(null);

    /** Await finishing of drag animation */
    const onErrorForm = async () => {
        const className = cx('DragAnimation');

        formRef.current?.classList.remove(className);
        formRef.current?.classList.add(className);

        await new Promise((resolve) => {
            setTimeout(() => resolve(formRef.current?.classList.remove(className)), DRAG_ANIMATION_DURATION);
        });
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        password.changeError('');
        repeatPassword.changeError('');

        if (!login.value || !password.value) {
            await onErrorForm();

            return toast.error(t('pageSign.alert.signup.emptyFields', { context: loginType }));
        }

        if (password.value !== repeatPassword.value) {
            await onErrorForm();

            return repeatPassword.changeError(t('pageSign.alert.signup.passwordsDifferent'));
        }

        // signupMutation({
        //     variables: {
        //         input: {
        //             password: password.value,
        //             email: loginType === 'email' ? login.value : undefined,
        //             phone: loginType === 'phone' ? formatPhone(login.value) : undefined
        //         }
        //     }
        // })
        //     .then(async ({ data }) => {
        //         const signup = data?.signup!;
        //
        //         if (signup.__typename === 'SignupSuccess') {
        //             if (signup.nextStep === AuthStep.Finish) {
        //                 // TODO: Why empty?
        //             } else {
        //                 setVerificationToken(signup.token);
        //                 setStep('code');
        //             }
        //         } else {
        //             const { __typename, errorMessage = '' } = signup ?? {};
        //
        //             await onErrorForm();
        //
        //             if (__typename === 'InvalidEmailError') {
        //                 login.changeError(t(['pageSign.alert.signup.invalidEmail', errorMessage]));
        //             } else if (__typename === 'InvalidPasswordError') {
        //                 password.changeError(t(['pageSign.alert.signup.invalidPassword', errorMessage]));
        //             } else if (__typename === 'InvalidPhoneError') {
        //                 login.changeError(t(['pageSign.alert.signup.invalidPhone', errorMessage]));
        //             } else if (__typename === 'LoginIsEmptyError') {
        //                 login.changeError(errorMessage);
        //             } else if (__typename === 'UserAlreadyExistsError') {
        //                 login.changeError(
        //                     t(['pageSign.alert.signup.userExists', errorMessage], {
        //                         context: loginType
        //                     })
        //                 );
        //             } else {
        //                 onGlobalError(errorMessage, toast);
        //                 toast.error(t('errors.globalError'));
        //             }
        //         }
        //     })
        //     .catch(async (err) => {
        //         await onErrorForm();
        //         onGlobalError(getErrorData(err).message, toast);
        //     });

        return undefined;
    };

    const onBlockFormCode = () => toast.error(t('pageSign.alert.formCode.hasNotAttempts'));

    return (
        <AuthWrapper
            title={t('pageSign.title', { context: 'signup' })}
            isBack={isMobile}
            onBackClick={onOpenLogin}
            sub={
                <>
                    <div className={cx('BtnWrapper')}>
                        <Trans i18nKey="pageSign.btnAlternative_signup">
                            <Link as="button" data-testid="btn-alternative" onClick={onOpenLogin} size="small">
                                .
                            </Link>
                        </Trans>
                    </div>
                    {/* TODO: Add translate */}
                    {isMobile ? (
                        <div className={cx('BtnWrapper')}>
                            <Link as="button" data-testid="btn-app" onClick={onOpenApp} size="small">
                                Войти при помощи приложения
                            </Link>
                        </div>
                    ) : (
                        <div className={cx('BtnWrapper')}>
                            <Link as="button" data-testid="btn-qr" onClick={onOpenQr} size="small">
                                Войти при помощи QR
                            </Link>
                        </div>
                    )}
                </>
            }
        >
            <>
                {step === 'code' && (
                    <AuthCode
                        verificationToken={verificationToken}
                        onSuccess={signIn}
                        className={cx('Form')}
                        onBlock={onBlockFormCode}
                        description={
                            <Trans i18nKey="pageSign.code_description_signup" values={{ address: login.value }}>
                                <b>.</b>
                            </Trans>
                        }
                        btnSubmitText={t('pageSign.btnSubmit', { context: 'signup' })}
                    />
                )}

                {step === 'signup' && (
                    <form
                        ref={formRef}
                        onSubmit={onSubmit}
                        style={{ animationDuration: `${DRAG_ANIMATION_DURATION}ms` }}
                    >
                        <LoginField loginType={loginType} setLoginType={setLoginType} login={login} />

                        <PasswordField
                            label={t('pageSign.password')}
                            placeholder={t('pageSign.password_placeholder', {
                                context: 'signup'
                            })}
                            showPasswordBtn={false}
                            validatePassword={validatePassword(password.value)}
                            maxLength={255}
                            data-testid="field-password"
                            value={password.value}
                            error={password.error}
                            onChange={(e) => password.change(validator(e, password.value))}
                        />

                        <PasswordField
                            showPasswordBtn={false}
                            data-testid="field-repeatPassword"
                            label={t('pageSign.repeatPassword')}
                            placeholder={t('pageSign.repeatPassword_placeholder')}
                            value={repeatPassword.value}
                            error={repeatPassword.error}
                            maxLength={255}
                            onChange={(e) => repeatPassword.change(validator(e, repeatPassword.value))}
                        />

                        <Button
                            data-testid="btn-submit"
                            // loading={loadingSignup}
                            disabled={!login.value || !password.value || !repeatPassword.value}
                            size="large"
                            type="submit"
                            fullWidth
                        >
                            {t('pageSign.btnSubmit', { context: 'signup' })}
                        </Button>
                    </form>
                )}
            </>
        </AuthWrapper>
    );
}

export default AuthSignup;
