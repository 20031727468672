import React from 'react';
// import { NavLink } from 'react-router-dom';
import cn from 'classnames';
// import { URLS_MAP } from 'constants/';
// import { CrownIcon, DashboardIcon, DocIcon, ProfileIcon } from 'ui/Icons/Icons';
// import Button from 'ui/Button/Button';
import HeaderUser from '../HeaderUser/HeaderUser';
import Logo from '../Logo/Logo';
// import useModal from '../../hooks/useModal/useModal';
// import ModalProfile from '../ProfileModal/ProfileModal';
import styles from './AdminHeader.module.scss';

export default function AdminHeader({isUser}: {isUser: boolean}) {

    return (
            <header className={cn(styles.Root)}>
                <Logo isDark />
                <HeaderUser className={cn(styles.User)} isUser={isUser}/>
            </header>
    );
}
