import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useCopy } from 'helpers';
import { CopyIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import styles from './Param.module.scss';

const cx = classNames.bind(styles);

export default function Param({
    label,
    value,
    className,
    copyText,
    onCopyText,
    withCopy
}: {
    className?: string;
    label?: React.ReactNode;
    value?: React.ReactNode;
    copyText?: number | string;
    onCopyText?: string;
    withCopy?: boolean;
}) {
    const [t] = useTranslation();
    const copy = useCopy();

    const onClickCopy = () =>
        copy((copyText ?? value)!.toString(), t(onCopyText ?? 'global.copied'), t('errors.globalError'));

    return (
        <p className={cx('Param', className)}>
            <span className={cx('ParamLabel')}>{label}</span>

            <span className={cx('ParamValue')}>
                <b>{value}</b>

                {withCopy && (
                    <Button
                        variant="text"
                        color="gray"
                        isRound
                        iconStart={<CopyIcon />}
                        onClick={onClickCopy}
                        classes={{ root: cx('ParamBtnCopy') }}
                    />
                )}
            </span>
        </p>
    );
}
