import React from 'react';
import classNames from 'classnames/bind';
import { useWidthCondition } from 'helpers';
import styles from './TabSwitcher.module.scss';

const cx = classNames.bind(styles);

type Props<T> = {
    className?: string;
    activeTab: T;
    setActiveTab(t: T): void;
    tabs: { label: React.ReactNode; type: T }[];
};

function TabSwitcher<T extends string>({ tabs, className = '', activeTab, setActiveTab }: Props<T>) {
    const activeRef = React.useRef<HTMLButtonElement>(null);
    const activeBgRef = React.useRef<HTMLSpanElement>(null);

    const updateBg = () => {
        if (!activeBgRef.current || !activeRef.current) return true;

        activeBgRef.current.style.width = `${activeRef.current.offsetWidth}px`;
        activeBgRef.current.style.left = `${activeRef.current.offsetLeft}px`;
        return true;
    };

    useWidthCondition(updateBg);

    React.useEffect(() => {
        setTimeout(updateBg, 0);
    }, [activeTab]);

    return (
        <div data-testid="tab-switcher" className={cx('Component', className)}>
            <span ref={activeBgRef} className={cx('ActiveBg')} />
            {tabs.map((tab) => {
                const active = tab.type === activeTab;
                return (
                    <button
                        key={tab.type}
                        ref={active ? activeRef : null}
                        onClick={() => setActiveTab(tab.type)}
                        className={cx('Btn', active && 'active')}
                        type="button"
                    >
                        {tab.label}
                    </button>
                );
            })}
        </div>
    );
}

export default TabSwitcher;
