export default {
    title: 'Переключить режим',
    description_test:
        'Вы уверены что хотите переключить режим с рабочего на тестовый? В тестовом режиме не будет работать оплата заказов',
    description_prod: 'Вы уверены что хотите переключить режим с тестового на рабочий?',

    successMsg: 'Режим работы магазина изменен',

    btnSubmit: 'Да, переключить',
    btnCancel: 'Нет, не переключать'
};
