import React from 'react';
import cn from 'classnames'
import Button from "ui/Button/Button";
import formatDate from "../../helpers/formatDate";
import styles from './Request.module.scss'
import {OrderTypeEnum, useOrderApprovalMutation} from "../../apollo/generated";
import {validateIntToFloat} from "../../helpers/formatMoney";

interface RequestProps {
    date: string,
    comment?: string | null,
    amount: number,
    orderId?: string | null,
    orderType: OrderTypeEnum
}
function Request({date, comment, amount, orderId, orderType}: RequestProps) {
    const [orderApprovalMutation, {data, loading}] = useOrderApprovalMutation()

    const handleClick = (isApproved: boolean) => {
        orderApprovalMutation({
            variables: {
                isApproved,
                orderId,
                orderType
            }
        })
    }

    return (
        <div className={cn(styles.Root)}>
            <span className={cn(styles.Label)}>дата и время</span>
            <span className={cn(styles.Text)}>{formatDate(date)}</span>
            <span className={cn(styles.Label)}>КОММЕНТАРИЙ</span>
            <span className={cn(styles.Text)}>{comment || "-"}</span>
            <span className={cn(styles.Label)}>СУММА на вывод</span>
            <span className={cn(styles.Sum)}>{validateIntToFloat(amount)} ₽</span>
            <div className={cn(styles.ButtonsWrapper)}>
                <Button color='primary' onClick={() => handleClick(true)}>Подтвердить</Button>
                <Button color='secondary' onClick={() => handleClick(false)}>Отклонить</Button>
            </div>

        </div>
    )
}

export default Request
