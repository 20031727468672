import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import Button from '../Button/Button';
import Tooltip, { useTooltip } from '../Tooltip/Tooltip';
import TextField, { Props as TextFieldProps } from '../TextField/TextField';
import { CheckIcon, CrossIcon, ShowOffIcon, ShowOnIcon } from '../Icons/Icons';
import styles from './PasswordField.module.scss';

const cx = classNames.bind(styles);

type Props = TextFieldProps & {
    validatePassword?: ReturnType<typeof validatePassword>;
    showPasswordBtn?: boolean;
};

/**
 * New password validator
 * Helps on registration page and change password page
 *
 * Main rules:
 * - min length is 8 symbols
 * - min one letter
 * - min one digit
 *
 * @param {string} password
 */
export const validatePassword = (password: string = '') => {
    const length = !!password && password.toString().length >= 8;
    const uppercase = !!password && password.toLowerCase() !== password.toUpperCase();
    const number = !!password && /\d/.test(password.toString());
    // eslint-disable-next-line
    const special = !!password && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password.toString());

    return {
        length,
        uppercase,
        number,
        special,
        isValid: length && uppercase && number && special
    };
};

function PasswordField({
    showPasswordBtn = true,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    validatePassword,
    ...props
}: Props) {
    const [t] = useTranslation();
    const tooltip = useTooltip();

    const [showPassword, setShowPassword] = React.useState(false);

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        props.onFocus?.(e);

        if (validatePassword) tooltip.setDisplayTooltip(true);
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        props.onBlur?.(e);

        if (validatePassword) tooltip.setDisplayTooltip(false);
    };

    const toggleShowPassword = () => setShowPassword(!showPassword);

    return (
        <div ref={tooltip.popperWrapperRef}>
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                onFocus={onFocus}
                onBlur={onBlur}
                type={showPassword ? 'text' : 'password'}
                inputRightContent={
                    showPasswordBtn && (
                        <Button
                            onClick={toggleShowPassword}
                            tabIndex={0}
                            aria-label="toggle password visibility"
                            size="small"
                            isRound
                            type="button"
                            variant="text"
                            color="gray"
                            iconStart={showPassword ? <ShowOffIcon fontSize="1rem" /> : <ShowOnIcon fontSize="1rem" />}
                        />
                    )
                }
            />

            {validatePassword && (
                <Tooltip popper={tooltip}>
                    <p className={cx('TooltipText')}>
                        {validatePassword.length ? (
                            <CheckIcon className={cx('IconCheck')} />
                        ) : (
                            <CrossIcon className={cx('IconClose')} />
                        )}{' '}
                        {t('passwordRules.length')}
                    </p>
                    <p className={cx('TooltipText')}>
                        {validatePassword.uppercase ? (
                            <CheckIcon className={cx('IconCheck')} />
                        ) : (
                            <CrossIcon className={cx('IconClose')} />
                        )}{' '}
                        {t('passwordRules.uppercase')}
                    </p>
                    <p className={cx('TooltipText')}>
                        {validatePassword.number ? (
                            <CheckIcon className={cx('IconCheck')} />
                        ) : (
                            <CrossIcon className={cx('IconClose')} />
                        )}{' '}
                        {t('passwordRules.number')}
                    </p>
                    <p className={cx('TooltipText')}>
                        {validatePassword.special ? (
                            <CheckIcon className={cx('IconCheck')} />
                        ) : (
                            <CrossIcon className={cx('IconClose')} />
                        )}{' '}
                        {t('passwordRules.special')}
                    </p>
                </Tooltip>
            )}
        </div>
    );
}

export default PasswordField;
