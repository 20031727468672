import { createAction } from 'store/createAction';
import ActionTypes from './actionTypes';
// eslint-disable-next-line import/no-cycle
import { ErrorStatus } from './reducer';

export const setErrorStatus = (payload: ErrorStatus = null) => createAction(ActionTypes.SET_ERROR_STATUS, payload);

export const setAuth = (payload: boolean) => createAction(ActionTypes.SET_AUTH, payload);
export const setIsAdmin = (payload: boolean) => createAction(ActionTypes.SET_IS_ADMIN, payload);

export const setShowModalSession = (payload: boolean) => createAction(ActionTypes.SET_SHOW_MODAL_SESSION, payload);

export type Actions =
    | ReturnType<typeof setErrorStatus>
    | ReturnType<typeof setShowModalSession>
    | ReturnType<typeof setIsAdmin>
    | ReturnType<typeof setAuth>;
