import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import {useParams, useHistory} from "react-router-dom";
import { getErrorData, useFormField, useGlobalError, useToast } from 'helpers';
import validator from 'helpers/validator';
import { signIn } from 'helpers/sign';
import { DRAG_ANIMATION_DURATION } from 'constants/';
import PasswordField, { validatePassword } from 'ui/PasswordField/PasswordField';
import Button from 'ui/Button/Button';
import Preloader from "../../ui/Preloader/Preloader";
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import {useChangePasswordMutation} from "../../apollo/generated";
import { useLoginType } from '../AuthLoginTabs/AuthLoginTabs';
import { AuthForgotPasswordProps, Step } from './AuthForgotPassword.d';
import styles from './AuthForgotPassword.module.scss';

interface Params {
    uid:string,
    ts: string,
    token: string
}

const cx = classNames.bind(styles);

function AuthForgotPassword({ onOpenLogin }: AuthForgotPasswordProps) {
    const {uid, ts, token: paramsToken} = useParams<Params>()
    const [step, setStep] = React.useState<Step>('loading');
    const history = useHistory()
    const [changePasswordMutation, { data, loading }] = useChangePasswordMutation({
        variables: {
            uid,
            ts,
            token: paramsToken
        }
    });

    React.useEffect(() => {
        changePasswordMutation()
            .then(({data: passwordData}) => {
                if (passwordData?.changePassword.__typename === "Token") {
                    setStep("password")
                }
                if (passwordData?.changePassword.__typename === "Error") {
                    setStep("error")
                }
            })
    }, [])

    const [t] = useTranslation();
    const login = useFormField('');
    const password = useFormField('');
    const repeatPassword = useFormField('');
    const [loginType, setLoginType] = useLoginType();
    const onGlobalError = useGlobalError();
    const toast = useToast();

    const [token, setToken] = React.useState('');

    const formRef = React.useRef<HTMLFormElement>(null);

    /** Await finishing of drag animation */
    const onErrorForm = () => {
        const className = cx('DragAnimation');

        formRef.current?.classList.remove(className);
        formRef.current?.classList.add(className);
        setTimeout(() => formRef.current?.classList.remove(className), DRAG_ANIMATION_DURATION);
    };

    const onSubmitLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        login.changeError('');

        if (!login.value) {
            onErrorForm();

            return toast.error(t('pageSign.alert.forgotPassword.emptyFields', { context: loginType }));
        }

        // resetPasswordMutation({
        //     variables: {
        //         input: {
        //             email: loginType === 'email' ? login.value : undefined,
        //             phone: loginType === 'phone' ? formatPhone(login.value) : undefined
        //         }
        //     }
        // })
        //     .then(({ data }) => {
        //         const resetPassword = data?.resetPassword!;
        //
        //         if (resetPassword.__typename === 'ResetPasswordSuccess') {
        //             setToken(resetPassword.token);
        //             setStep('code');
        //         } else {
        //             const { __typename, errorMessage = '' } = resetPassword ?? {};
        //
        //             onErrorForm();
        //
        //             if (__typename === 'UserNotFoundError') {
        //                 login.changeError(t(['pageSign.alert.forgotPassword.userNotFound', errorMessage]));
        //             } else if (__typename === 'NoResendAttemptsError') {
        //                 toast.error(t(['pageSign.alert.forgotPassword.noResendAttempts', errorMessage]));
        //             } else {
        //                 onGlobalError(errorMessage, toast);
        //             }
        //         }
        //     })
        //     .catch((err) => {
        //         onGlobalError(getErrorData(err).message);
        //     });

        return undefined;
    };

    const onSubmitPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        password.changeError('');

        if (!repeatPassword.value || !password.value) {
            onErrorForm();

            return toast.error(t('pageSign.alert.forgotPassword.emptyFields', { context: 'password' }));
        }

        if (password.value !== repeatPassword.value) {
            onErrorForm();

            return toast.error(t('pageSign.alert.forgotPassword.passwordsDifferent'));
        }

        changePasswordMutation({
            variables: {
                uid,
                ts,
                token: paramsToken,
                password: password.value
            }
        }).then(({data: confitmChangeData}) => {
            if (confitmChangeData?.changePassword.__typename === "Token") {
                history.push('/')
            }
        }).catch((error) => {
            onGlobalError(getErrorData(error).message)
        })

        // confirmPasswordResetMutation({
        //     variables: {
        //         input: {
        //             password: password.value
        //         }
        //     },
        //
        //     context: { headers: constructAuthHeader(token) }
        // })
        //     .then(({ data }) => {
        //         const confirmPasswordReset = data?.confirmPasswordReset! ?? {};
        //
        //         if (confirmPasswordReset.__typename === 'ConfirmPasswordResetSuccess') {
        //             signIn(confirmPasswordReset.token);
        //         } else {
        //             onErrorForm();
        //             onGlobalError(confirmPasswordReset.errorMessage);
        //         }
        //     })
        //     .catch((err) => {
        //         onGlobalError(getErrorData(err).message);
        //     });

        return undefined;
    };

    return (
        <AuthWrapper title={t('pageSign.title', { context: 'forgotPassword' })} isBack onBackClick={onOpenLogin}>
            <>
                {step === 'loading' && (
                    <>
                        <p className={cx('Description')}>Проверка ссылки</p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Preloader size={40}/>
                        </div>
                        {/*<form*/}
                        {/*    ref={formRef}*/}
                        {/*    onSubmit={onSubmitLogin}*/}
                        {/*    className={cx('Form')}*/}
                        {/*    style={{ animationDuration: `${DRAG_ANIMATION_DURATION}` }}*/}
                        {/*>*/}
                        {/*    <AuthLoginTabs login={login} loginType={loginType} setLoginType={setLoginType} />*/}
                        {/*    <Button*/}
                        {/*        data-testid="btn-submit"*/}
                        {/*        // loading={loadingResetPassword}*/}
                        {/*        disabled={!login.value}*/}
                        {/*        size="large"*/}
                        {/*        type="submit"*/}
                        {/*        fullWidth*/}
                        {/*    >*/}
                        {/*        {t('pageSign.btnSubmit', { context: 'forgotPassword' })}*/}
                        {/*    </Button>*/}
                        {/*</form>*/}
                    </>
                )}

                {step === "error" && (
                    <>
                        <h1>Ошибка</h1>
                        <p>Обратитесь к админу</p>
                    </>
                )}

                {step === 'password' && (
                    <form
                        ref={formRef}
                        onSubmit={onSubmitPassword}
                        className={cx('Form')}
                        style={{ animationDuration: `${DRAG_ANIMATION_DURATION}` }}
                    >
                        <p>Введите новый пароль</p>
                        <PasswordField
                            label={t('pageSign.password')}
                            placeholder={t('pageSign.password_placeholder', {
                                context: 'forgotPassword'
                            })}
                            showPasswordBtn={false}
                            validatePassword={validatePassword(password.value)}
                            maxLength={255}
                            value={password.value}
                            data-testid="field-password"
                            error={password.error}
                            onChange={(e) => password.change(validator(e, password.value))}
                        />
                        <PasswordField
                            showPasswordBtn={false}
                            label={t('pageSign.repeatPassword')}
                            placeholder={t('pageSign.repeatPassword_placeholder')}
                            value={repeatPassword.value}
                            error={repeatPassword.error}
                            maxLength={255}
                            data-testid="field-repeatPassword"
                            onChange={(e) => repeatPassword.change(validator(e, repeatPassword.value))}
                        />

                        <Button
                            // loading={loadingConfirmPasswordReset}
                            disabled={!password.value || !repeatPassword.value}
                            size="large"
                            data-testid="btn-submit"
                            type="submit"
                            fullWidth
                        >
                            {t('pageSign.btnSubmit', { context: 'forgotPassword' })}
                        </Button>
                    </form>
                )}
            </>
        </AuthWrapper>
    );
}

export default AuthForgotPassword;
