import React from 'react';
import {useParams} from "react-router-dom";
import OrderRedirect from "../components/OrderRedirect/OrderRedirect";

interface Params {
    id: string
}
function OrderRedirectPage(){
    const params:Params = useParams()

    return (
        <div>
            <OrderRedirect id={params.id} />
        </div>
    );
};

export default OrderRedirectPage;
