export default {
    title_intro: 'Возврат средств',
    title_refundIntroAttention: 'Возврат средств',
    title_agreement: 'Возврат',
    title_hasNoBalance: 'Недостаточно средств',

    description_intro: 'Вы хотите совершить возврат средств по заказу <0>№{{orderNumber}}</0>',
    description_refundIntroAttention:
        'По заказу <0>№{{orderNumber}}</0> вы уже вернули средства в размере <0>{{amount}}</0>',

    description_agreement: 'Вы уверены что хотите вернуть средства за данный заказ, отмена операции будет невозможна',

    description_hasNoBalance: 'Для возврата срдеств на вашем балансе недостаточно средств.',

    missingBalance: 'Недостающая сумма',

    successMsg: 'Возврат средств успешно произведен',

    receiver: 'Аккаунт получателя',
    fee: 'Комиссия',
    received: 'Вы получили',
    paid: 'Было оплачено',
    youWillPay: 'С вас будет списано',
    clientReceive: 'Клиент получит',
    clientReceived: 'Клиент получил',
    btnSubmit_intro: 'Продолжить',
    btnSubmit_agreement: 'Сделать возврат средств',
    btnSubmit_hasNoBalance: 'Пополнить баланс',
    btnCancel: 'Отмена',
    btnChangeAmount: 'Изменить сумму возврата',
    btnReturnAllSum: 'Вернуть полную сумму',

    error: {
        amountTooLow: 'Сумма списания слишком мала'
    }
};
