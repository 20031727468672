import React from 'react';
import classNames from 'classnames/bind';
import { ErrorStatus } from 'store/Base/reducer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorStatus } from 'store/Base/actions';
import { useStore, useWidthCondition } from 'helpers';
import Button from 'ui/Button/Button';
import { BREAKPOINT_PHONE } from 'constants/';
import { useHistory } from 'react-router-dom';
import BigImage500 from 'assets/images/error-500.svg';
import BigImage404 from 'assets/images/error-404.svg';
import SmallImage500 from 'assets/images/status-error.svg';
import SmallImage404 from 'assets/images/status-question.svg';
import styles from './Error.module.scss';

const cx = classNames.bind(styles);

function Error(props: { code?: ErrorStatus; onClickBtn?: () => void }) {
    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isPhone] = useWidthCondition((w) => w < BREAKPOINT_PHONE);
    const storeErrorCode = useStore((s) => s.Base.error);
    // eslint-disable-next-line react/destructuring-assignment
    const errorCode = props.code ?? storeErrorCode ?? 404;

    // eslint-disable-next-line consistent-return
    const Image = React.useMemo(() => {
        // eslint-disable-next-line default-case
        switch (errorCode) {
            case 404:
                return isPhone ? SmallImage404 : BigImage404;
            case 500:
                return isPhone ? SmallImage500 : BigImage500;
        }
    }, [errorCode, isPhone]);

    const resetError = React.useCallback(() => dispatch(setErrorStatus()), [dispatch]);

    const onClick = () => {
        resetError();
        // eslint-disable-next-line react/destructuring-assignment
        props.onClickBtn?.();
        history.push('/');
    };

    return (
        <main className={cx('Component')}>
            <div className={cx('ImageWrapper')}>
                <img src={Image} alt="" />
            </div>
            <div className={cx('ContentWrapper')}>
                <code className={cx('Code')}>{t('pageError.code', { code: errorCode })}</code>
                <h1 className={cx('Title')}>{t('pageError.title', { context: errorCode.toString() })}</h1>
                <p>{t(`pageError.description`, { context: errorCode.toString() })}</p>
                <Button size={isPhone ? 'large' : 'medium'} data-testid="btn" onClick={onClick}>
                    {t(`pageError.btn`)}
                </Button>
            </div>
        </main>
    );
}

export default React.memo(Error);
