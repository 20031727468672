import React from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import { useOverflowController, useOverlayClickHandler } from 'helpers';
import { ReactComponent as IconInfoStatus } from 'assets/images/status-info.svg';
import { ReactComponent as IconDoneStatus } from 'assets/images/status-success.svg';
import { ReactComponent as IconWaitStatus } from 'assets/images/status-pending.svg';
import { ReactComponent as IconErrorStatus } from 'assets/images/status-error.svg';
import { ReactComponent as IconQuestionStatus } from 'assets/images/status-question.svg';
import Button from '../Button/Button';
import { ChevronLeftIcon, CrossIcon } from '../Icons/Icons';
import { ModalTitleProps, ModalDescriptionProps, ModalStatusProps, ModalProps } from './Modal.d';
import styles from './Modal.module.scss';

const ANIM_DURATION_BG = 250;
export const ANIM_DURATION_MODAL = 600;

/**
 * Modal context, that allow correctly close modal with initiate close animation firstly
 */
export const ModalContext = React.createContext({
    close: () => {}
});
export const useModalContext = () => React.useContext(ModalContext);

export function ModalTitle({ children, className = '', withCloseBtn, btnBack }: ModalTitleProps) {
    const { close } = useModalContext();

    return (
        <h3 className={cn(styles.Title, className)}>
            {btnBack && (
                <Button
                    classes={{ root: cn(styles.BtnBack) }}
                    variant="text"
                    color="secondary"
                    isRound
                    iconStart={<ChevronLeftIcon fontSize="1.25rem" />}
                    onClick={btnBack}
                />
            )}
            {children}
            {withCloseBtn && (
                <Button
                    classes={{ root: cn(styles.BtnClose) }}
                    variant="text"
                    color="secondary"
                    isRound
                    iconStart={<CrossIcon />}
                    onClick={close}
                />
            )}
        </h3>
    );
}

export function ModalDescription({ children, className = '' }: ModalDescriptionProps) {
    return <p className={cn(styles.Description, className)}>{children}</p>;
}

export function ModalStatus({ className = '', type, bgColor }: ModalStatusProps) {
    const Icon =
        // eslint-disable-next-line no-nested-ternary
        type === 'info'
            ? IconInfoStatus
            : // eslint-disable-next-line no-nested-ternary
            type === 'done'
            ? IconDoneStatus
            : // eslint-disable-next-line no-nested-ternary
            type === 'error'
            ? IconErrorStatus
            : type === 'wait'
            ? IconWaitStatus
            : IconQuestionStatus;

    return <Icon className={cn(styles.StatusIcon, bgColor, className)} />;
}

/**
 * Fully controllable Modal component.
 * It is divided into parts to make it easier to create modal components with
 * different markups.
 * Component has some internal logic of displaying:
 * 1) after change `isOpen` to `true` modal adds to the DOM by React portal, animation starts
 * 2) on the end of animation local flag `modalActive` changes to `true`. Component ready to use
 * 3) on click close button or call `close` func from context - close animation fires by changing
 * `modalActive` flag to `true`
 * 4) on the end of animation `props.close` calls, `props.onClose` calls if it passed,
 * modal removes from the DOM
 *
 * @important
 * For better animation rendering on close use `close` function from **context**
 * ~~or pass `internalCloseRef` and call it if you need close modal outside/from upper level in the VDOM~~
 *
 * Additional features:
 * - on click **overlay** start closing modal
 * - on press `ESC` start closing modal
 * - uses focus-lock to disable leaving focus from modal
 */
function Modal({
    isOpen,
    close,
    onClose,
    closable = true,
    callClosePropOnly = false,
    children,
    showCloseBtn,
    className = '',
    ...props
}: ModalProps) {
    const [modalActive, setModalActive] = React.useState(false);
    const isFirstRenderRef = React.useRef(true);
    const closeTimeoutId = React.useRef<any>();
    const overflowController = useOverflowController();

    const closeRef = React.useRef(close);

    React.useEffect(() => {
        closeRef.current = close;
    }, [close]);

    const onExternalCloseRef = React.useRef<() => void>();
    const modalPortal = React.useRef(document.createElement('div')).current;
    const modalWrapperRef = React.useRef<HTMLElement>(null);
    const isClosing = (modalActive || isOpen) && !(modalActive && isOpen);
    const showModal = modalActive && isOpen;

    const unmountEnvironment = (
        onBeforeAnimation = () => setModalActive(false),
        onEndAnimation = closeRef.current,
        forceClose = false
    ) => {
        if (callClosePropOnly) return onEndAnimation?.();

        if (!closable && !forceClose) return undefined;

        /** enable body's overflow */
        overflowController.unblockScroll();
        /** remove keyboard event listener */
        window.onkeydown = null;
        onBeforeAnimation();

        closeTimeoutId.current = setTimeout(() => {
            onExternalCloseRef.current = undefined;
            onClose?.();
            onEndAnimation?.();
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            modalPortal && modalPortal.remove();
        }, ANIM_DURATION_MODAL);

        return undefined;
    };
    const onPressEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') unmountEnvironment();
    };

    /** create portal container, mount it and set "ready" flag */
    const prepareEnvironment = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        modalPortal && document.body.appendChild(modalPortal);

        return setTimeout(() => {
            setModalActive(true);
            overflowController.blockScroll(modalPortal);
            modalWrapperRef.current?.scrollTo(0, 0);
            window.onkeydown = onPressEsc;
        });
    };

    const overlayClickHandler = useOverlayClickHandler(unmountEnvironment);

    React.useEffect(
        () => () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            modalPortal && modalPortal.remove();
            overflowController.unblockScroll();
            clearInterval(closeTimeoutId.current);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * on change `isOpen`
     * if `isOpen` is true - create portal container, render it and set "ready" flag
     * otherwise remove all event listeners and close modal
     */
    React.useEffect(() => {
        if (isOpen) {
            const tid = prepareEnvironment();

            onExternalCloseRef.current = () => {
                unmountEnvironment(
                    closeRef.current,
                    () => {
                        setModalActive(false);
                    },
                    true
                );
                onExternalCloseRef.current = undefined;
            };

            return () => clearTimeout(tid);
        }

        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false;

            return undefined;
        }

        onExternalCloseRef.current?.();

        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    if (!isOpen && !modalActive) return null;

    return createPortal(
        <FocusLock autoFocus={false}>
            {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
            <ModalContext.Provider value={{ close: () => unmountEnvironment() }}>
                <aside
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...overlayClickHandler.overlayProps}
                    ref={modalWrapperRef}
                    data-testid="modal-wrapper"
                    style={{
                        transitionDuration: `${ANIM_DURATION_BG / 1000}s`,
                        transitionDelay: isClosing ? `${(ANIM_DURATION_BG / 1000) * 0.8}s` : undefined
                    }}
                    className={cn(styles.Component, modalActive && isOpen && styles.Opened)}
                >
                    <div
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...overlayClickHandler.componentProps}
                        style={{
                            transitionDuration: `${ANIM_DURATION_MODAL / 1000}s`,
                            transitionDelay: showModal ? `${(ANIM_DURATION_BG / 1000) * 0.2}s` : undefined
                        }}
                        className={cn(styles.Modal, className)}
                        data-testid={props['data-testid'] || 'modal'}
                    >
                        {children}
                    </div>
                </aside>
            </ModalContext.Provider>
        </FocusLock>,
        modalPortal
    );
}

export default React.memo(Modal);
