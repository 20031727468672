export default {
    title: 'Пополнения и выводы',
    subtitle_0: '{{count}} запись',
    subtitle_1: '{{count}} записи',
    subtitle_2: '{{count}} записей',

    searchCount_0: 'Найдена {{count}} запись',
    searchCount_1: 'Найдено {{count}} записи',
    searchCount_2: 'Найдено {{count}} записей',

    search_placeholder: 'Поиск',

    type: 'Тип',
    type_DEPOSIT: 'Пополнение баланса',
    type_WITHDRAW: 'Вывод средств',
    createdDate: 'Дата и время',

    amount: 'Сумма',
    fee: 'Комиссия',
    total: 'Итого',
    trxid: 'ID транзакции',

    account: 'Аккаунт',

    actions: {
        details: 'Подробнее'
    },

    empty: {
        title: 'Данные отсутвуют',
        description: 'На данный момент в магазине\nне было никаких действий',
        title_search: 'Ничего не найдено',
        description_search:
            'Мы не смогли ничего найти по вашему запросу.\nПроверьте правильность\nнаписания и попробуйте еще раз'
    },

    modalDetails: {
        title: 'Подробности'
    },

    filters: {
        title: 'Фильтры',
        btnSubmit: 'Сохранить',

        createdDate: {
            label: 'Дата и время',
            from_empty: 'С самого начала',
            to_empty: 'Сегодня'
        },

        amount: 'Сумма',
        amount_from: 'От',
        amount_to: 'До',

        token: 'Токен',
        token_placeholder: 'Все токены',
        type: 'Тип',
        type_placeholder: 'Все типы'
    }
};
