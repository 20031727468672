import React from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowUpAndDown } from 'assets/icons/arrows-up-and-down.svg';
import { ReactComponent as ArrowBottomCorner } from 'assets/icons/arrow-bottom-corner.svg';
import { ReactComponent as ArrowTopCorner } from 'assets/icons/arrow-top-corner.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Cards } from 'assets/icons/cards.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Clip } from 'assets/icons/clip.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Crown } from 'assets/icons/crown.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as Doc } from 'assets/icons/doc.svg';
import { ReactComponent as DocOutlined } from 'assets/icons/doc-outlined.svg';
import { ReactComponent as Dots } from 'assets/icons/dots.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Faq } from 'assets/icons/faq.svg';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as Garbage } from 'assets/icons/garbage.svg';
import { ReactComponent as Hexagon } from 'assets/icons/hexagon.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as InfoCircled } from 'assets/icons/info-circled.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Key } from 'assets/icons/key.svg';
import { ReactComponent as Exit } from 'assets/icons/exit.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { ReactComponent as Message } from 'assets/icons/message.svg';
import { ReactComponent as MessageCircled } from 'assets/icons/message-circled.svg';
import { ReactComponent as MoneyOrder } from 'assets/icons/money-order.svg';
import { ReactComponent as MoneyRefund } from 'assets/icons/money-refund.svg';
import { ReactComponent as MoneySend } from 'assets/icons/money-send.svg';
import { ReactComponent as Notification } from 'assets/icons/notification.svg';
import { ReactComponent as People } from 'assets/icons/people.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Point } from 'assets/icons/point.svg';
import { ReactComponent as Print } from 'assets/icons/print.svg';
import { ReactComponent as Profile } from 'assets/icons/profile.svg';
import { ReactComponent as Qr } from 'assets/icons/qr.svg';
import { ReactComponent as QuestionCircled } from 'assets/icons/question-circled.svg';
import { ReactComponent as Rating } from 'assets/icons/rating.svg';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as Shop } from 'assets/icons/shop.svg';
import { ReactComponent as ShowOff } from 'assets/icons/show-off.svg';
import { ReactComponent as ShowOn } from 'assets/icons/show-on.svg';
import { ReactComponent as Sort } from 'assets/icons/sort.svg';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import { ReactComponent as Table } from 'assets/icons/table.svg';
import { ReactComponent as TagClose } from 'assets/icons/tag-close.svg';
import { ReactComponent as WarningShield } from 'assets/icons/warning-shield.svg';
import { ReactComponent as WarningTriangle} from 'assets/icons/warning-triangle.svg';
import styles from './Icons.module.scss';

type SvgIconProps = React.SVGProps<SVGSVGElement> & { className?: string };

const defaultProps = {
    'aria-hidden': true,
    focusable: false
};

export function ArrowDownIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ArrowDown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowLeftIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ArrowLeft {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowRightIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ArrowRight {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowUpIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ArrowUp {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowUpAndDownIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ArrowUpAndDown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowBottomCornerIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ArrowBottomCorner {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ArrowTopCornerIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ArrowTopCorner {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CalendarIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Calendar {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CardsIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Cards {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CheckIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Check {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronUpIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ChevronUp {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronDownIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ChevronDown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronLeftIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ChevronLeft {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ChevronRightIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ChevronRight {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ClipIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Clip {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ClockIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Clock {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CopyIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Copy {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CrossIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Cross {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function CrownIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Crown {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DashboardIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Dashboard {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DocIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Doc {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DocOutlinedIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DocOutlined {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function DotsIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Dots {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function EditIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Edit {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function FaqIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Faq {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function FileIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <File {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function FilterIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Filter {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function GarbageIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Garbage {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function HexagonIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Hexagon {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function HomeIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Home {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function InfoCircledIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <InfoCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function LockIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Lock {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function KeyIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Key {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ExitIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Exit {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MailIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Mail {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MenuIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Menu {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MessageIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Message {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MessageCircledIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MessageCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoneyOrderIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MoneyOrder {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoneyRefundIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MoneyRefund {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function MoneySendIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MoneySend {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function NotificationIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Notification {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PeopleIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <People {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PhoneIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Phone {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PlusIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Plus {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PointIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Point {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function PrintIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Print {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ProfileIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Profile {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function QrIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Qr {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function QuestionCircledIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <QuestionCircled {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function RatingIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Rating {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ReloadIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Reload {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SearchIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Search {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SettingsIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Settings {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ShopIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Shop {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ShowOffIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ShowOff {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function ShowOnIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ShowOn {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function SortIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Sort {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function StarIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Star {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TableIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Table {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function TagCloseIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TagClose {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function WarningShieldIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WarningShield {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}

export function WarningTriangleIcon({ className, ...props }: SvgIconProps) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WarningTriangle {...defaultProps} className={cn(styles.Root, className)} {...props} />;
}
