import React from 'react';
import cn from 'classnames';
import { PreloaderProps } from './Preloader.d';
import styles from './Preloader.module.scss';

const SIZE = 44;

const Preloader = React.forwardRef<HTMLSpanElement, PreloaderProps>(
    (
        {
            color = 'primary',
            disableShrink,
            className,
            thickness = 3.6,
            size = 28,
            value = 0,
            variant = 'indeterminate',
            ...props
        }: PreloaderProps,
        ref
    ) => {
        const classes = cn(
            styles.Root,
            styles[`Color-${color}`],
            styles[`Variant-${variant}`],
            disableShrink && styles.ShrinkDisable,
            className
        );

        const circumference = 2 * Math.PI * ((SIZE - thickness) / 2);

        return (
            <span
                className={classes}
                role="progressbar"
                style={{ fontSize: size }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                aria-valuenow={variant === 'determinate' ? Math.round(value) : undefined}
                ref={ref}
            >
                <svg className={cn(styles.Svg)} viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
                    <circle
                        className={cn(styles.Circle)}
                        cx={SIZE}
                        cy={SIZE}
                        r={(SIZE - thickness) / 2}
                        fill="none"
                        strokeWidth={thickness}
                        strokeDasharray={variant === 'determinate' ? circumference.toFixed(3) : '80px, 200px'}
                        strokeDashoffset={
                            variant === 'determinate' ? `${(((100 - value) / 100) * circumference).toFixed(3)}px` : 0
                        }
                    />
                </svg>
            </span>
        );
    }
);

export default Preloader;
