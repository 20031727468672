import React from 'react';
import cn from 'classnames';
import { ChevronLeftIcon } from 'ui/Icons/Icons';
import Button from 'ui/Button/Button';
import styles from './AuthWrapper.module.scss';
import { AuthWrapperProps } from './AuthWrapper.d';

export default function AuthWrapper({ title, isBack, onBackClick, children, sub }: AuthWrapperProps) {
    return (
        <div className={cn(styles.Root)}>
            <div className={cn(styles.Container)}>
                {(title || isBack) && (
                    <div className={cn(styles.Header, isBack && styles.WithBtn)}>
                        {isBack && (
                            <Button
                                variant="text"
                                color="gray"
                                data-testid="btn-back"
                                onClick={onBackClick}
                                iconStart={<ChevronLeftIcon fontSize="1.5rem" />}
                            />
                        )}
                        <h1 className={cn(styles.Title)}>{title}</h1>
                    </div>
                )}
                <div className={cn(styles.Body)}>{children}</div>
            </div>

            {sub && <div className={cn(styles.Sub)}>{sub}</div>}
        </div>
    );
}
