import React from 'react';
import { useParams } from 'react-router-dom';
import OrderFailure from '../components/OrderFailure/OrderFailure';

interface Params {
    id: string;
}

export default function OrderPage() {
    const params: Params = useParams();

    return (
        <div>
            <OrderFailure id={params.id} />
        </div>
    );
}
