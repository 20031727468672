import React from 'react';
import AdminDashboard from 'components/AdminDashboard/AdminDashboard';
import AdminHeader from "../components/AdminHeader/AdminHeader";

// interface AdminDashboardPageProps {
//     // showBalancesAndResources?: boolean;
//     allowDeposit?: boolean;
//     allowWithdraw?: boolean;
//     allowShutDown?: boolean;
//     allowEditCommissions?: boolean;
//     allowToggleCurrencies?: boolean;
// }

export default function AdminDashboardPage() {
    return (
        <>
            <AdminHeader isUser/>
            <AdminDashboard/>
        </>

    );
}
