export default {
    title: 'Вывод средств',

    amount: {
        title: 'Введите сумму вывода',

        balance: 'Баланс',

        amount: 'Сумма вывода',
        amount_placeholder: 'Введите сумму для вывода',
        btnAmountMax: 'Вывести максимум',

        account: 'Аккаунт',
        account_placeholder: 'Укажите аккаунт',
        willReceive: 'Вы получите',
        youWillPay: 'С баланса будет списано',

        btnSubmit: 'Продолжить'
    },

    password: {
        title: 'Подтверждение вывода',
        description: 'Для подтверждения вывода средств необходимо ввести пароль от аккаунта',
        password: 'Пароль',
        password_placeholder: 'Введите свой пароль',
        btnSubmit: 'Продолжить'
    },

    success: {
        title: 'Готово',
        description: 'Запрос отправлен в обработку. Вы можете проверить статус вывода в профиле',
        btnSubmit: 'Готово'
    },

    exit: {
        title: 'Отмена',
        description: 'Вы уверены что хотите прекратить процесс вывода средств',
        btnSubmit: 'Да',
        btnCancel: 'Нет'
    },

    error: {
        amountRequired: 'Вы не указали сумму вывода',
        accountRequired: 'Вы не указали аккаунт',
        notEnoughBalance: 'У вас на балансе недостаточно средств',
        accountNotExists: 'Аккаунт не найден',
        invalidPassword: 'Неверный пароль',
        amountTooLow: 'Сумма вывода слишком мала'
    }
};
