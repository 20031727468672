export default {
    code: 'Ошибка {{ code}}',
    title_500: 'Внутренняя\nошибка сервера',
    title_404: 'Страница\nне найдена',
    description_500:
        'На сервере произошла непредвиденная\nошибка. Пожалуйста, подождите: она\nвскоре будет исправлена.',
    description_404: 'Вероятно, страница не существует\nили вы ошиблись, когда вводили\nадрес в строке браузера',

    btn: 'Вернуться на главную'
};
