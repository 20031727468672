import { ApolloClient, createHttpLink, from, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { constructAuthHeader, getToken } from 'helpers';
import { signOut } from 'helpers/sign';
import store from 'store/';
import { setErrorStatus } from 'store/Base/actions';
import { cache } from './cache';

const errorCodes = onError((error) => {
    const { networkError } = error as any;
    const status = networkError?.statusCode;

    if (status === 401 && networkError.result?.error === 'TOKEN_INVALID') {
        signOut(false, true);
    }

    if (networkError && navigator?.onLine) {
        if ([500, 504].includes(status)) {
            store.dispatch(setErrorStatus(500));
        }
    }
});

const httpLink = createHttpLink({
    credentials: 'omit',
    uri: process.env.REACT_APP_API_ENDPOINT
});

export const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_ENDPOINT!,
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: () => {
            const params = {} as { [key: string]: string };
            const token = getToken();

            if (token) {
                params.AUTHORIZATION = token;
            }
            return params;
        }
    }
});

/**
 * We have to trigger reconnect for all WS sockets,
 * because we need to update JWT token for the connection
 */
export const reconnectWS = () => {
    const client = (wsLink as any).subscriptionClient;
    const operations = {
        ...client.operations
    };
    client.close(true);
    client.connect();

    Object.keys(operations).forEach((id) => {
        client.sendMessage(id, 'start', operations[id].options);
    });
    client.operations = operations;
};

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const authLink = setContext((_, context) => {
    const token = getToken();

    context.headers = {
        ...(token ? constructAuthHeader(token) : null),
        ...context.headers
    };
    return context;
});

const client = new ApolloClient({
    link: from([errorCodes, authLink, splitLink]),
    cache
});

export default client;
