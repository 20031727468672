export default {
    title: 'Для разработчиков',

    documentation: {
        title: 'Документация',

        btnBack: 'Перейти к предыдущему разделу',
        btnNext: 'Перейти к следующему разделу',

        begin: {
            title: 'Начало работы',

            content: [
                'Для начала работы с API PayCash for store Вам необходимо будет использовать секретный ключ магазина, который был получен при создании магазина. Если вы не сохранили ключ, Вы можете его перегенерировать его в разделе <1>Для Разработчиков > API ключ</1>. Полученный секретный ключ необходимо сохранить в надежном месте - он будет использоваться для подтверждения запросов.',
                'Все запросы отправляются на url <0>https://api.pc4.store/</0>'
            ]
        },

        auth: {
            title: 'Авторизация',

            content: [
                'Все запросы должны включать авторизационный хэдер вида <0>BasicAuth (store_id, store_secret_key)</0>',
                'Пример <1>curl https://api.pc4.store/v1/create  -u store_id:store_secret_key</1>',
                'В случае отсутствия хэдера авторизации Вы получите <0>JSON ответ со статусом 401</0>',
                'Если магазин с таким id не найден или ключ неправильный <0>JSON ответ со статусом 403</0>'
            ]
        },

        createOrder: {
            title: 'Создание заказа',
            description: 'Для создания заказа клиенту необходимо выполнить post запрос на url <0>/v1/create</0>',

            table: {
                headers: ['Параметр', 'описание', 'пример'],

                description: {
                    amount_to_pay: 'Cума заказа в cash токенах (строкой)',
                    currency_name: 'Cash токен',
                    currency_smart_contract:
                        'Смарт контракт в котором работает токен (все поддерживаемые валюты наведены в соответствующем разделе)',
                    response_url: 'Url на который прийдет ответ о успешной или неуспешной оплате',
                    merchant_order_id: 'Уникальный идентификатор заказа в магазине',
                    description: 'Описание заказа будет отображатся на станице оплаты',
                    expiration_timeout: 'Время после которого заказ станет невалидным в минутах. По умолчанию 15 минут',
                    success_payment_redirect_url:
                        'Url на который пользователя перебросит с платежной страницы в случае успешной оплаты заказа',
                    failed_payment_redirect_url:
                        'Url на который пользователя перебросит с платежной страницы в случае отмены аказа или истечении срока оплаты'
                }
            },

            response: {
                title: 'Клиент сразу получает ответ в зависимости от результата',
                description_success: 'В случае успешного создания заказа:',
                description_failure: 'В случае не успешного создания заказа:'
            },

            statuses: {
                title: 'Возможные статусы ответа ',
                OK: 'Заказ успешно создан',
                ERROR: 'Некорректный запрос',
                description:
                    'В случае передачи некоректного токена авторизации в запрос, придет <0>JSON ответ с http статусом 401 или 403</0>'
            },

            currencies: {
                title: 'Список поддерживаемых валют',
                smartContract: 'Смарт контракт'
            }
        },

        orderDetails: {
            title: 'Информация о заказе',
            content: [
                // eslint-disable-next-line no-useless-escape
                'Чтобы получить информацию о заказе необходимо выполнить GET запрос на адрес <0>/v1/order_info/\u2039order_id\u203a</0>',
                '<0>order_id </0> Уникальный идентификатор заказа в платежной системе',
                'Формат ответа:',
                'Если заказ не найден прийдет ответ вида:',
                'В случае передачи некоректного токена авторизации в запрос, придет <0>JSON ответ с http статусом 401 или 403</0>'
            ],

            statuses: {
                title: 'Возможные статусы заказа',
                content: [
                    '<0>CREATED</0> Заказ создан',
                    '<0>MONEY_RECEIVED</0> Оплата получена (но транзакция не irreversible)',
                    '<0>PAID</0> Оплата получена, транакция необратима (транзакция становится необратимой через 3 минуты после проведения оплаты)',
                    '<0>CANCELLED</0> Заказ был отменен',
                    '<0>EXPIRED </0> Оплата не была получена в установленое время'
                ]
            }
        },

        createTransfer: {
            title: 'Перевод средств',
            description: 'Для создания перевода клиенту необходимо выполнить post запрос на url <0>/v1/transfer</0>',

            table: {
                headers: ['Параметр', 'описание', 'пример'],

                description: {
                    amount: 'Cума заказа в cash токенах (строкой)',
                    currency_name: 'Cash токен',
                    currency_smart_contract:
                        'Смарт контракт в котором работает токен (все поддерживаемые валюты наведены в соответствующем разделе)',
                    eos_account: 'EOS аккаунт на который будут отправлены средства',
                    response_url: 'Url на который прийдет ответ о успешной или неуспешной оплате'
                }
            },

            response: {
                title: 'Клиент сразу получает ответ в зависимости от результата',
                description_success: 'В случае успешного создания перевода:',
                description_failure: 'В случае ошибки:'
            },

            statuses: {
                title: 'Возможные статусы ответа ',
                content: [
                    // eslint-disable-next-line no-useless-escape
                    'Смотри раздел “Создание заказа“',
                    'После того как транзакция станет необратимой или отменится клиент получает post запрос на response_url с данными по переводу (формат в разделе “Информация о заказе“ ). Смотрите детали по обработке запроса в разделе “Callback“',
                    'Важно: статус перевода ACCEPTED означает что сервис обработал запрос и совершил перевод, но транзакция все еще может быть отвергнута. Чтобы убедится что транзакция необратима дождитесь пока поле transfer.action.is_irreversilble станет True (обычно на это уходит 3 минуты). По завершению этого периода Вы получите callback на указаный response_url. Также Вы можете отслеживать состояние перевода сами (смотри “Информация о переводе“)'
                ]
            }
        },

        transferDetails: {
            title: 'Информация о переводе',
            content: [
                // eslint-disable-next-line no-useless-escape
                'Чтобы получить информацию о заказе необходимо выполнить GET запрос на адрес <0>/v1/transfer_info/\u2039transfer_id\u203a</0>',
                '<0>transfer_id</0> Уникальный идентификатор заказа в платежной системе',
                'Формат ответа:',
                'Если трансфер не найден прийдет ответ вида:',
                'В случае передачи некоректного токена авторизации в запрос, придет <0>JSON ответ с http статусом 401 или 403</0>'
            ]
        },

        callback: {
            title: 'Callback',
            content: [
                'После принятия оплаты от пользователя <0>MONEY_RECEIVED</0> когда транзакция станет необратимой <0>PAID</0>',
                'по истечению expiration_date <0>EXPIRED</0> или при отмене заказа из кабинета <0>CANCELLED</0> клиент получает post запрос',
                'на <0>response_url</0> с данными по заказу формат в разделе “Информация о заказе“',
                'Важно: статус заказа MONEY_RECEIVED означает что магазин получил деньги, но транзакция все еще может быть отвергнута. Статус PAID означает что транзакция уже необратима.',
                'Запрос также содержит хэдер с подписью вида',
                'Чтобы убедиться в подлинности запроса неоходимо проверить его подпись.',
                'Используемый алгоритм <0>Ed25519</0>',
                'Публичный ключ <0>69f72437e2e359a3e5c29fe9a7e0d509345cc57b7bfca0b470598d679a349806</0>',
                'Чтобы проверить подпись нужно:',
                'Учитывая что',
                '<0>Тело сообщения</0> строка полученая в пункте 2',
                '<0>Подпись </0> значение из хэдера SIGNATURE ',
                'HEX значение публичного ключа <0>69f72437e2e359a3e5c29fe9a7e0d509345cc57b7bfca0b470598d679a349806</0> '
            ],

            steps: [
                ['Привести <0>тело запроса</0> к JSON', 'ВАЖНО: не должно быть пробелов между ключем и значением'],
                ['Взять   <0>хэш sha256</0> от JSON строки из пункта 1. На выходе должна получится hex строка'],
                ['Проверить <0>подпись</0> по алгоритму <0>Ed25519</0>']
            ]
        },

        lifecycles: {
            title: 'Циклы жизни заказа',
            content: ['Успешный заказ', 'Заказ был отменен', 'Истек срок ожидания оплаты', 'Транзакция была отвергнута']
        },

        sdk: {
            title: 'SDK',
            content: [
                'Для облегчения интеграции с нашей платежной системой, реализованы библиотеки для следующих языков програмирования:'
            ]
        },

        testing: {
            title: 'Тестирование',
            content: [
                'Для тестирования синхронизации с платежной системой, войдите в личный кабинет на сайте, выберете нужный Вам магазин и переведите его в тестовый режим (включен по умолчанию).',
                'Все заказы, созданые пока магазин находится в тестовом режиме, автоматически становятся тестовыми. На странице оплаты появятся кнопки для управления циклом жизни заказа.'
            ]
        }
    },
    apikey: {
        title: 'API ключ'
    },
    logs: {
        title: 'Логи'
    },
    webhooks: {
        title: 'Вебхуки'
    },
    integrations: {
        title: 'Интеграция'
    }
};
