export default {
    title: 'Мои магазины',
    subtitle_0: '{{count}} Магазин',
    subtitle_1: '{{count}} Магазина',
    subtitle_2: '{{count}} Магазинов',
    searchCount_0: 'Найден {{count}} магазин',
    searchCount_1: 'Найдено {{count}} магазина',
    searchCount_2: 'Найдено {{count}} магазинов',

    sortType: {
        CREATED: 'Дата создания',
        CREATED_DESC: 'Дата создания',
        NAME: 'Название',
        NAME_DESC: 'Название',
        MODE: 'Режим',
        MODE_DESC: 'Режим'
    },

    btnAdd: 'Создать',

    search_placeholder: 'Поиск',
    sort: 'Сортировка',

    params: {
        id: 'ID',
        created: 'Дата создания',
        orderCount: 'заказов',
        totalIncome: 'Поступления за все время'
    },

    actions: {
        key: 'API ключ',
        edit: 'Редактировать',
        remove: 'Удалить'
    },

    empty: {
        title: 'Ничего не найдено',
        description:
            'Мы не смогли ничего найти по вашему\nзапросу. Проверьте правильность\nнаписания и попробуйте еще раз'
    }
};
