import React from 'react';
import classNames from 'classnames/bind';
// import { useTranslation } from 'react-i18next';
// import { useDepositParamsQuery } from 'apollo/generated';
import {useIsTouchableDevice} from 'helpers';
import Modal, {ModalDescription, ModalTitle} from 'ui/Modal/Modal';
import {ModalProps} from 'ui/Modal/Modal.d';
import Preloader from 'ui/Preloader/Preloader';
import {OrderStatusEnum, OrderTypeEnum, useOrdersQuery} from "../../apollo/generated";
import Request from "../Request/Request";
// import Alert from 'ui/Alert/Alert';
// import TextField from "ui/TextField/TextField";
// import Param from '../Param/Param';
// import QR from '../QR/QR';
import styles from './RequestModal.module.scss';

const cx = classNames.bind(styles);

type Props = {
    currencyId: string;
    merchantId: any
};

function Main({ currencyId, merchantId }: Props) {
    const isTouchableDevice = useIsTouchableDevice();
    // const [t] = useTranslation();
    // const depositParamsQuery = useDepositParamsQuery({
    //     variables: { currencyId }
    // });
        // const [value, setValue] = React.useState('')

    // const depositParams = depositParamsQuery.data?.depositParams! ?? {};

    const {data, loading, error} = useOrdersQuery({
        variables: {
            merchantUids: [merchantId],
            limit: 40,
            skip: 0
        }
    })

    return (
        <>
            <div className={cx('Main')}>
                <ModalTitle withCloseBtn>
                    {data?.orders.__typename === "Orders" &&
                        data.orders.orders.filter((el) => (el.orderType === OrderTypeEnum.UserDeposit || el.orderType === OrderTypeEnum.UserWithdraw) && el.status === OrderStatusEnum.Pending).length
                        ? "Все заявки"
                        : "Нет заявок"}
                </ModalTitle>
                <ModalDescription />
                {loading &&
                    <div className={cx('Preloader')}>
                        <Preloader />
                    </div>}
                <div style={{overflow: "auto"}}>
                    {data?.orders.__typename === "Orders" ? data.orders.orders.map((el) =>
                        ((el.orderType === OrderTypeEnum.UserDeposit || el.orderType === OrderTypeEnum.UserWithdraw) &&
                            <Request
                                key={el.uid}
                                amount={el.amount}
                                comment={el.description}
                                date={el.created}
                                //@ts-ignore
                                orderType={el.orderType}
                                orderId={el.uid}
                            />
                        )
                    ) : ""}
                </div>
                {/*<QR data={depositParams.metaInfo ?? ''} className={cx('QR')} />*/}

                {/* {isTouchableDevice && (
					<ButtonLink type='button' className={cx('BtnLink', 'BtnDownload')}>
						{t('modalDeposit.btnDownload')}
					</ButtonLink>
				)} */}

                {/*{!isTouchableDevice && (*/}
                {/*    <div className={cx('OrDivider')}>*/}
                {/*        <span>{t('modalDeposit.orDivider')}</span>*/}
                {/*    </div>*/}
                {/*)}*/}
                {/*<p>{value}</p>*/}
                {/*<TextField placeholder='Введите сумму' label='Сумма' value={value} onChange={(event) => setValue(event.target.value)}/>*/}

                {/*<TextField placeholder='Введите комментарий' label='Комментарий (не обязательно)'/>*/}

                {/*<Param*/}
                {/*    className={cx('Param')}*/}
                {/*    label={t('modalDeposit.wallet')}*/}
                {/*    value={depositParams.receiver}*/}
                {/*    withCopy*/}
                {/*/>*/}
                {/*<Param className={cx('Param')} label={t('modalDeposit.memo')} value={depositParams.memo} withCopy />*/}

                {/*<Alert>{t('modalDeposit.alert')}</Alert>*/}

                {/*<p className={cx('Hint')}>{t('modalDeposit.hint')}</p>*/}
            </div>

            {/*{isTouchableDevice && (*/}
            {/*    <div className={cx('Footer')}>*/}
            {/*        <Button as="a" size="large" fullWidth>*/}
            {/*            /!*<Button as="a" href={depositParams.deeplink} size="large" fullWidth>*!/*/}
            {/*            Оставить заявку*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*)}*/}
        </>
    );
}

export default function ModalRequest({ currencyId, merchantId, ...props }: ModalProps & Props) {
    // const isTouchableDevice = useIsTouchableDevice();
    return (
        <Modal
            // className={cx('Component', isTouchableDevice && 'isTouchable')}
            className={cx('Component', 'isTouchable')}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <Main currencyId={currencyId} merchantId={merchantId} />
        </Modal>
    );
}
