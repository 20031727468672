export default {
    title: 'Перевод средств',
    subtitle_0: '{{count}} перевод',
    subtitle_1: '{{count}} перевода',
    subtitle_2: '{{count}} переводов',

    btnAdd: 'Новый перевод',
    searchCount_0: 'Найден {{count}} перeвод',
    searchCount_1: 'Найдено {{count}} перeвода',
    searchCount_2: 'Найдено {{count}} перeводов',

    search_placeholder: 'Поиск',

    status: 'Статус',
    status_placeholder: 'Все статусы',
    status_PENDING: 'Подтверждается',
    status_FINISHED: 'Завершен',
    status_CANCELLED: 'Отменен',

    number: 'Перевод',
    createdDate: 'Время перевода',
    token: 'Токен',
    amount: 'Сумма перевода',
    received: 'Клиент получил',
    receiver: 'Клиент',

    actions: {
        details: 'Посмотреть на bloks.io'
    },

    empty: {
        title: 'Переводы отсутвуют',
        description: 'У вас пока что нет переводов\nв данном магазине',
        title_search: 'Ничего не найдено',
        description_search:
            'Мы не смогли ничего найти по вашему запросу.\nПроверьте правильность\nнаписания и попробуйте еще раз'
    },

    modalDetails: {
        title: 'Подробности'
    },

    filters: {
        title: 'Фильтры',
        btnSubmit: 'Сохранить',

        createdDate: {
            label: 'Дата и время',
            from_empty: 'С самого начала',
            to_empty: 'Сегодня'
        },

        amount: 'Сумма',
        amount_from: 'От',
        amount_to: 'До',

        token: 'Токен',
        token_placeholder: 'Все токены',
        status: 'Статус',
        status_placeholder: 'Все статусы'
    }
};
