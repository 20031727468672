import React from 'react';
import { ErrorEnum, useOrderQuery } from 'apollo/generated';
import { getErrorData } from 'helpers';
import Preloader from 'ui/Preloader/Preloader';
import styles from './OrderSuccess.module.scss';

interface OrderSuccessProps {
    id: string;
}

const getErrorMessage = (type: ErrorEnum) => {
    switch (type) {
        case ErrorEnum.BadPassword:
            return 'Не верный пароль';
        case ErrorEnum.BadRequest:
            return 'Неверный запрос';
        case ErrorEnum.Duplicate:
            return 'Дубликат';
        case ErrorEnum.Forbidden:
            return 'Запрещено';
        case ErrorEnum.InsecureLink:
            return 'Небезопасная ссылка';
        case ErrorEnum.InvalidCredentials:
            return 'Неверные данные';
        case ErrorEnum.NotFound:
            return 'Не найдено';
        case ErrorEnum.Unavailable:
            return 'Недоступно';
        default:
            return 'Не предвиденная ошибка';
    }
};

export default function OrderSuccess({ id }: OrderSuccessProps) {
    const { data, error } = useOrderQuery({
        variables: {
            uid: id
        }
    });

    if (data?.order.__typename === 'Order') {
        const { successRedirectUrl } = data.order ?? {};

        window.location.href = successRedirectUrl ?? '';
    }

    if (data?.order.__typename === 'Error') {
        const { errorType, errorMessage } = data?.order ?? {};

        return (
            <h1>
                <>
                    {errorType && <>{getErrorMessage(errorType)}</>}
                    {errorMessage && getErrorData(errorMessage)}
                </>
            </h1>
        );
    }

    return <div className={styles.Root}>{error ? <h1>Error</h1> : <Preloader size={40} />}</div>;
}
