import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useWidthCondition } from '../../helpers';
import { BREAKPOINT_TABLET } from '../../constants';
import styles from './TestMarker.module.scss';

const cx = classNames.bind(styles);

export default function TestMark({ textContent }: { textContent?: string }) {
    const [t] = useTranslation();

    const [isTabletOrLess] = useWidthCondition((w) => w < BREAKPOINT_TABLET);

    return (
        <div className={cx('TestMark')}>
            <span>{textContent || t(`orderStatus.isTest`, { context: isTabletOrLess ? '' : 'short' })}</span>
        </div>
    );
}
