import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  UUID: any;
};

export type CreateOrder = {
  amount: Scalars['Int'];
  callbackUrl?: InputMaybe<Scalars['String']>;
  card?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  failureRedirectUrl?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderType: OrderTypeEnum;
  personalData?: InputMaybe<PersonalData>;
  successRedirectUrl?: InputMaybe<Scalars['String']>;
};

export type CustomerBalance = {
  __typename: 'CustomerBalance';
  balance: Scalars['Int'];
};

export type CustomerBalanceResponse = CustomerBalance | Error;

export type Error = {
  __typename: 'Error';
  errorMessage?: Maybe<Scalars['String']>;
  errorType: ErrorEnum;
};

export enum ErrorEnum {
  BadPassword = 'BAD_PASSWORD',
  BadRequest = 'BAD_REQUEST',
  Duplicate = 'DUPLICATE',
  Forbidden = 'FORBIDDEN',
  InsecureLink = 'INSECURE_LINK',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  NotFound = 'NOT_FOUND',
  Unavailable = 'UNAVAILABLE',
  UnexpectedError = 'UNEXPECTED_ERROR'
}

export type Mutation = {
  __typename: 'Mutation';
  changePassword: SignInResponse;
  createOrder: OrderResponse;
  orderApproval: OrderApprovalResponse;
  regenerateApiToken: UserInfoResponse;
  signIn: SignInResponse;
};


export type MutationChangePasswordArgs = {
  password?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  ts: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationCreateOrderArgs = {
  order: CreateOrder;
};


export type MutationOrderApprovalArgs = {
  isApproved: Scalars['Boolean'];
  orderId: Scalars['UUID'];
  orderType: OrderTypeEnum;
};


export type MutationSignInArgs = {
  email?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename: 'Order';
  amount: Scalars['Int'];
  created: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  depositAmount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  failureRedirectUrl?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  merchantUid: Scalars['UUID'];
  orderId?: Maybe<Scalars['String']>;
  orderType?: Maybe<OrderTypeEnum>;
  paymentProvider?: Maybe<PaymentProviderEnum>;
  paymentProviderId?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  status: OrderStatusEnum;
  successRedirectUrl?: Maybe<Scalars['String']>;
  uid: Scalars['UUID'];
  updated: Scalars['DateTime'];
  url: Scalars['String'];
};

export type OrderApproval = {
  __typename: 'OrderApproval';
  decision: Scalars['Boolean'];
};

export type OrderApprovalResponse = Error | OrderApproval;

export type OrderResponse = Error | Order;

export enum OrderSortTypeEnum {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  DepositAmountAsc = 'DEPOSIT_AMOUNT_ASC',
  DepositAmountDesc = 'DEPOSIT_AMOUNT_DESC',
  FeeAsc = 'FEE_ASC',
  FeeDesc = 'FEE_DESC',
  OrderIdAsc = 'ORDER_ID_ASC',
  OrderIdDesc = 'ORDER_ID_DESC'
}

export enum OrderStatusEnum {
  AwaitsManualApproval = 'AWAITS_MANUAL_APPROVAL',
  Declined = 'DECLINED',
  Error = 'ERROR',
  Expired = 'EXPIRED',
  New = 'NEW',
  Paid = 'PAID',
  Pending = 'PENDING',
  Unpaid = 'UNPAID'
}

export enum OrderTypeEnum {
  Deposit = 'DEPOSIT',
  UserDeposit = 'USER_DEPOSIT',
  UserWithdraw = 'USER_WITHDRAW',
  Withdraw = 'WITHDRAW'
}

export type Orders = {
  __typename: 'Orders';
  orders: Array<Order>;
};

export type OrdersResponse = Error | Orders;

export type PasswordReset = {
  __typename: 'PasswordReset';
  url: Scalars['String'];
};

export type PasswordResetUrl = Error | PasswordReset;

export enum PaymentProviderEnum {
  Best2Pay = 'BEST2PAY',
  Pegas = 'PEGAS'
}

export type PersonalData = {
  birthDate: Scalars['Date'];
  birthPlace: Scalars['String'];
  docDepartmentCode?: InputMaybe<Scalars['String']>;
  docIssuedBy?: InputMaybe<Scalars['String']>;
  docIssuedDate: Scalars['Date'];
  docNumber: Scalars['String'];
  docSeries: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  factAddress?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  inn?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  middleName?: InputMaybe<Scalars['String']>;
  mobilePhone: Scalars['String'];
  registrationAddress?: InputMaybe<Scalars['String']>;
  signDateUtc: Scalars['DateTime'];
  signature: Scalars['String'];
};

export type Query = {
  __typename: 'Query';
  customerBalance: CustomerBalanceResponse;
  getResetPasswordUrl: PasswordResetUrl;
  merchants: UsersInfoResponse;
  order: OrderResponse;
  orders: OrdersResponse;
  userInfo: UserInfoResponse;
};


export type QueryCustomerBalanceArgs = {
  customerId: Scalars['String'];
};


export type QueryGetResetPasswordUrlArgs = {
  userId: Scalars['UUID'];
};


export type QueryOrderArgs = {
  uid: Scalars['UUID'];
};


export type QueryOrdersArgs = {
  allMerchants?: InputMaybe<Scalars['Boolean']>;
  limit?: Scalars['Int'];
  merchantUids?: InputMaybe<Array<Scalars['UUID']>>;
  orderTypes?: InputMaybe<Array<OrderTypeEnum>>;
  q?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  sortType?: InputMaybe<OrderSortTypeEnum>;
};

export type SignInResponse = Error | Token;

export type Token = {
  __typename: 'Token';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type User = {
  __typename: 'User';
  apiToken?: Maybe<Scalars['String']>;
  balance: Scalars['Int'];
  balanceIncoming: Scalars['Int'];
  balanceOutgoing: Scalars['Int'];
  email: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isPaymentEnabled: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  isWithdrawEnabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  paymentAutoLimit: Scalars['Int'];
  paymentFee: Scalars['Int'];
  paymentMaxFee: Scalars['Int'];
  paymentMinFee: Scalars['Int'];
  paymentMonthlyLimit: Scalars['Int'];
  paymentProviders: Array<PaymentProviderEnum>;
  uid: Scalars['String'];
  userWithdrawOrdersCount: Scalars['Int'];
  username: Scalars['String'];
  whiteListIps?: Maybe<Array<Scalars['String']>>;
  withdrawAutoLimit: Scalars['Int'];
  withdrawFee: Scalars['Int'];
  withdrawMaxFee: Scalars['Int'];
  withdrawMinFee: Scalars['Int'];
  withdrawMonthlyLimit: Scalars['Int'];
};

export type UserInfoResponse = Error | User;

export type Users = {
  __typename: 'Users';
  users: Array<User>;
};

export type UsersInfoResponse = Error | Users;

export type ChangePasswordMutationVariables = Exact<{
  uid: Scalars['String'];
  ts: Scalars['String'];
  token: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}>;


export type ChangePasswordMutation = { __typename: 'Mutation', changePassword: { __typename: 'Error', errorType: ErrorEnum, errorMessage?: string | null } | { __typename: 'Token', token?: string | null } };

export type CreateOrderMutationVariables = Exact<{
  order: CreateOrder;
}>;


export type CreateOrderMutation = { __typename: 'Mutation', createOrder: { __typename: 'Error', errorType: ErrorEnum, errorMessage?: string | null } | { __typename: 'Order', url: string, uid: any, amount: number, description?: string | null, status: OrderStatusEnum, orderId?: string | null, failureRedirectUrl?: string | null, successRedirectUrl?: string | null, redirectUrl?: string | null } };

export type OrderApprovalMutationVariables = Exact<{
  orderType: OrderTypeEnum;
  orderId: Scalars['UUID'];
  isApproved: Scalars['Boolean'];
}>;


export type OrderApprovalMutation = { __typename: 'Mutation', orderApproval: { __typename: 'Error', errorMessage?: string | null, errorType: ErrorEnum } | { __typename: 'OrderApproval', decision: boolean } };

export type RegenerateApiTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RegenerateApiTokenMutation = { __typename: 'Mutation', regenerateApiToken: { __typename: 'Error', errorMessage?: string | null, errorType: ErrorEnum } | { __typename: 'User', apiToken?: string | null } };

export type SigninMutationVariables = Exact<{
  userName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
}>;


export type SigninMutation = { __typename: 'Mutation', signIn: { __typename: 'Error', errorType: ErrorEnum, errorMessage?: string | null } | { __typename: 'Token', token?: string | null, user?: { __typename: 'User', isSuperuser: boolean } | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename: 'Query', userInfo: { __typename: 'Error', errorMessage?: string | null, errorType: ErrorEnum } | { __typename: 'User', email: string, uid: string, balanceOutgoing: number, balanceIncoming: number, balance: number, isActive: boolean, isSuperuser: boolean, username: string, apiToken?: string | null, name?: string | null, imageUrl?: string | null } };

export type MerchantsQueryVariables = Exact<{ [key: string]: never; }>;


export type MerchantsQuery = { __typename: 'Query', merchants: { __typename: 'Error' } | { __typename: 'Users', users: Array<{ __typename: 'User', uid: string, userWithdrawOrdersCount: number, balance: number, balanceOutgoing: number, balanceIncoming: number, email: string, isActive: boolean, name?: string | null, imageUrl?: string | null }> } };

export type OrderQueryVariables = Exact<{
  uid: Scalars['UUID'];
}>;


export type OrderQuery = { __typename: 'Query', order: { __typename: 'Error', errorType: ErrorEnum, errorMessage?: string | null } | { __typename: 'Order', uid: any, status: OrderStatusEnum, expiresAt?: any | null, created: any, description?: string | null, merchantUid: any, amount: number, successRedirectUrl?: string | null, failureRedirectUrl?: string | null, orderId?: string | null, updated: any, url: string, redirectUrl?: string | null } };

export type OrdersQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']>;
  merchantUids?: InputMaybe<Array<Scalars['UUID']> | Scalars['UUID']>;
  allMerchants?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  sortType?: InputMaybe<OrderSortTypeEnum>;
}>;


export type OrdersQuery = { __typename: 'Query', orders: { __typename: 'Error', errorMessage?: string | null, errorType: ErrorEnum } | { __typename: 'Orders', orders: Array<{ __typename: 'Order', amount: number, created: any, description?: string | null, expiresAt?: any | null, failureRedirectUrl?: string | null, merchantUid: any, status: OrderStatusEnum, successRedirectUrl?: string | null, uid: any, url: string, orderId?: string | null, updated: any, orderType?: OrderTypeEnum | null, redirectUrl?: string | null, fee: number, paymentProvider?: PaymentProviderEnum | null, depositAmount: number, paymentProviderId?: string | null }> } };


export const ChangePasswordDocument = gql`
    mutation ChangePassword($uid: String!, $ts: String!, $token: String!, $password: String = null) {
  changePassword(uid: $uid, token: $token, ts: $ts, password: $password) {
    ... on Error {
      errorType
      errorMessage
    }
    ... on Token {
      token
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      ts: // value for 'ts'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($order: CreateOrder!) {
  createOrder(order: $order) {
    ... on Order {
      url
      uid
      amount
      description
      status
      orderId
      failureRedirectUrl
      successRedirectUrl
      redirectUrl
    }
    ... on Error {
      errorType
      errorMessage
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const OrderApprovalDocument = gql`
    mutation OrderApproval($orderType: OrderTypeEnum!, $orderId: UUID!, $isApproved: Boolean!) {
  orderApproval(orderType: $orderType, orderId: $orderId, isApproved: $isApproved) {
    ... on Error {
      errorMessage
      errorType
    }
    ... on OrderApproval {
      decision
    }
  }
}
    `;
export type OrderApprovalMutationFn = Apollo.MutationFunction<OrderApprovalMutation, OrderApprovalMutationVariables>;

/**
 * __useOrderApprovalMutation__
 *
 * To run a mutation, you first call `useOrderApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderApprovalMutation, { data, loading, error }] = useOrderApprovalMutation({
 *   variables: {
 *      orderType: // value for 'orderType'
 *      orderId: // value for 'orderId'
 *      isApproved: // value for 'isApproved'
 *   },
 * });
 */
export function useOrderApprovalMutation(baseOptions?: Apollo.MutationHookOptions<OrderApprovalMutation, OrderApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderApprovalMutation, OrderApprovalMutationVariables>(OrderApprovalDocument, options);
      }
export type OrderApprovalMutationHookResult = ReturnType<typeof useOrderApprovalMutation>;
export type OrderApprovalMutationResult = Apollo.MutationResult<OrderApprovalMutation>;
export type OrderApprovalMutationOptions = Apollo.BaseMutationOptions<OrderApprovalMutation, OrderApprovalMutationVariables>;
export const RegenerateApiTokenDocument = gql`
    mutation regenerateApiToken {
  regenerateApiToken {
    ... on Error {
      errorMessage
      errorType
    }
    ... on User {
      apiToken
    }
  }
}
    `;
export type RegenerateApiTokenMutationFn = Apollo.MutationFunction<RegenerateApiTokenMutation, RegenerateApiTokenMutationVariables>;

/**
 * __useRegenerateApiTokenMutation__
 *
 * To run a mutation, you first call `useRegenerateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateApiTokenMutation, { data, loading, error }] = useRegenerateApiTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegenerateApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateApiTokenMutation, RegenerateApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateApiTokenMutation, RegenerateApiTokenMutationVariables>(RegenerateApiTokenDocument, options);
      }
export type RegenerateApiTokenMutationHookResult = ReturnType<typeof useRegenerateApiTokenMutation>;
export type RegenerateApiTokenMutationResult = Apollo.MutationResult<RegenerateApiTokenMutation>;
export type RegenerateApiTokenMutationOptions = Apollo.BaseMutationOptions<RegenerateApiTokenMutation, RegenerateApiTokenMutationVariables>;
export const SigninDocument = gql`
    mutation Signin($userName: String, $password: String!, $email: String) {
  signIn(username: $userName, password: $password, email: $email) {
    ... on Token {
      token
      user {
        isSuperuser
      }
    }
    ... on Error {
      errorType
      errorMessage
    }
  }
}
    `;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const MeDocument = gql`
    query Me {
  userInfo {
    ... on User {
      email
      uid
      balanceOutgoing
      balanceIncoming
      balance
      isActive
      isSuperuser
      username
      apiToken
      name
      imageUrl
    }
    ... on Error {
      errorMessage
      errorType
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MerchantsDocument = gql`
    query Merchants {
  merchants {
    ... on Users {
      users {
        uid
        userWithdrawOrdersCount
        balance
        balanceOutgoing
        balanceIncoming
        email
        isActive
        name
        imageUrl
      }
    }
  }
}
    `;

/**
 * __useMerchantsQuery__
 *
 * To run a query within a React component, call `useMerchantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMerchantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMerchantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMerchantsQuery(baseOptions?: Apollo.QueryHookOptions<MerchantsQuery, MerchantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MerchantsQuery, MerchantsQueryVariables>(MerchantsDocument, options);
      }
export function useMerchantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MerchantsQuery, MerchantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MerchantsQuery, MerchantsQueryVariables>(MerchantsDocument, options);
        }
export type MerchantsQueryHookResult = ReturnType<typeof useMerchantsQuery>;
export type MerchantsLazyQueryHookResult = ReturnType<typeof useMerchantsLazyQuery>;
export type MerchantsQueryResult = Apollo.QueryResult<MerchantsQuery, MerchantsQueryVariables>;
export const OrderDocument = gql`
    query Order($uid: UUID!) {
  order(uid: $uid) {
    ... on Error {
      errorType
      errorMessage
    }
    ... on Order {
      uid
      status
      expiresAt
      created
      description
      merchantUid
      amount
      successRedirectUrl
      failureRedirectUrl
      orderId
      updated
      url
      redirectUrl
    }
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrdersDocument = gql`
    query Orders($q: String, $merchantUids: [UUID!], $allMerchants: Boolean, $limit: Int!, $skip: Int!, $sortType: OrderSortTypeEnum) {
  orders(
    q: $q
    merchantUids: $merchantUids
    allMerchants: $allMerchants
    limit: $limit
    skip: $skip
    sortType: $sortType
  ) {
    ... on Error {
      errorMessage
      errorType
    }
    ... on Orders {
      orders {
        amount
        created
        description
        expiresAt
        failureRedirectUrl
        merchantUid
        status
        successRedirectUrl
        uid
        url
        orderId
        updated
        orderType
        redirectUrl
        fee
        paymentProvider
        depositAmount
        paymentProviderId
      }
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      q: // value for 'q'
 *      merchantUids: // value for 'merchantUids'
 *      allMerchants: // value for 'allMerchants'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sortType: // value for 'sortType'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CustomerBalanceResponse": [
      "CustomerBalance",
      "Error"
    ],
    "OrderApprovalResponse": [
      "Error",
      "OrderApproval"
    ],
    "OrderResponse": [
      "Error",
      "Order"
    ],
    "OrdersResponse": [
      "Error",
      "Orders"
    ],
    "PasswordResetUrl": [
      "Error",
      "PasswordReset"
    ],
    "SignInResponse": [
      "Error",
      "Token"
    ],
    "UserInfoResponse": [
      "Error",
      "User"
    ],
    "UsersInfoResponse": [
      "Error",
      "Users"
    ]
  }
};
      export default result;
    