export default {
    title: 'Заказы',
    subtitle_0: '{{count}} заказ',
    subtitle_1: '{{count}} заказа',
    subtitle_2: '{{count}} заказов',

    searchCount_0: 'Найден {{count}} заказ',
    searchCount_1: 'Найдено {{count}} заказа',
    searchCount_2: 'Найдено {{count}} заказов',

    btnAdd: 'Создать',

    status: 'Статус',
    orderNumber: 'Номер заказа',
    createdDate: 'Время создания',
    paymentDate: 'Время оплаты',
    token: 'Токен',
    received: 'Полученная сумма',
    payer: 'Клиент',

    actions: {
        btnCancelOrder: 'Отмена',
        btnRefundOrder: 'Возврат'
    },

    search_placeholder: 'Поиск',
    filters: {
        title: 'Фильтры',
        btnSubmit: 'Сохранить',

        createdDate: {
            label: 'Время создания заказа',
            to_empty: 'Сегодня'
        },

        paymentDate: {
            label: 'Время оплаты заказа',
            to_empty: 'Сегодня'
        },

        amount: 'Сумма',
        amount_from: 'От',
        amount_to: 'До',

        token: 'Токен',
        token_placeholder: 'Все токены',
        status: 'Статус',
        status_placeholder: 'Все статусы'
    },

    empty: {
        title: 'Заказы отсутвуют',
        description: 'У вас пока что нет заказов\n данном магазине'
    },

    card: {
        actions: {
            btnCancelOrder: 'Отменить заказ',
            btnRefund: 'Возврат средств'
        },

        order: 'Заказ',
        createdDate: 'Время создания',
        paidDate: 'время оплаты',
        payer: 'Аккаунт оплатившего',
        paymentInfo: 'Информация по оплате',
        token: 'Токен',
        received: 'Полученная сумма',
        paid: 'Клиент оплатил',
        refund: 'Возврат'
    }
};
