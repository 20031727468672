export default {
    title: 'Перевод средств',

    amount: {
        title: 'Введите сумму вывода',

        token: 'Токен',
        balance: 'Баланс',

        amount: 'С вас будет списано',
        amount_placeholder: 'Введите сумму перевода',

        receive: 'Клиент получит',
        receive_placeholder: 'Введите сумму получения',

        fee: 'Комиссия',

        account: 'Аккаунт',
        account_placeholder: 'Укажите аккаунт',

        btnSubmit: 'Продолжить'
    },

    password: {
        title: 'Подтверждение вывода',
        description: 'Для подтверждения перевода средств необходимо ввести пароль от аккаунта',
        password: 'Пароль',
        password_placeholder: 'Введите свой пароль',
        btnSubmit: 'Продолжить'
    },

    success: {
        title: 'Готово',
        description: 'Запрос отправлен в обработку. Вы можете проверить статус перевода во вкладке переводов.',
        btnSubmit: 'Готово'
    },

    exit: {
        title: 'Отмена',
        description: 'Вы уверены что хотите прекратить процесс перевода средств',
        btnSubmit: 'Да',
        btnCancel: 'Нет'
    },

    error: {
        amountRequired: 'Вы не указали сумму вывода',
        accountRequired: 'Вы не указали аккаунт',
        notEnoughBalance: 'У вас на балансе недостаточно средств',
        accountNotExists: 'Аккаунт не найден',
        invalidPassword: 'Неверный пароль',
        amountTooLow: 'Сумма вывода слишком мала'
    }
};
